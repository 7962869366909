import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Divider } from "@material-ui/core";
import { StoreContext } from "../../store/context";
import { BSA_JOURNEY_STATUS_FORCED } from "../../utils/constants";

import bank from "../../assets/images/encore/guide/Bank.svg";
import file from "../../assets/images/encore/guide/Documents.svg";
import calendar from "../../assets/images/encore/guide/Calendar.svg";
import download from "../../assets/images/encore/guide/File Download.svg";
import contactUs from "../../assets/images/encore/guide/Contact Us.svg";

// import file from "../../assets/images/encore/file.svg";
// import eBanking from "../../assets/images/encore/e-banking.svg";
// import bankStatement from "../../assets/images/encore/bank-statement.svg";
// import bankStatementDate from "../../assets/images/encore/bank-statement-date.svg";
// import downloadStatement from "../../assets/images/encore/download-statement.svg";
import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
// import customerService from "../../assets/images/encore/customer-service.svg";

import "./TwelveStatementGuide.scss";

const TwelveStatementGuide = ({ history }) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData } = actions.generalActions;
  const { previousStep, bankStatementRequired, bsaRequirementStatus, bankStatementCountInMonths } = state.generalStates;
  const [isForcedBsa, setIsForcedBsa] = useState(false);

  useEffect(() => {
    setIsForcedBsa((bsaRequirementStatus == BSA_JOURNEY_STATUS_FORCED) || bankStatementRequired);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaRequirementStatus, bankStatementRequired]);

  const essentialPreparation = [
    {
      icon: bank,
      altIcon: "E Banking",
      description: t("encore.guide.preparation.preparation1")
    },
    {
      icon: file,
      altIcon: "Bank Statement",
      description: t("encore.guide.preparation.preparation2")
    },
    {
      icon: calendar,
      altIcon: "Bank Statement Date",
      description: t("encore.guide.preparation.preparation3", { count: bankStatementCountInMonths })
    },
    {
      icon: download,
      altIcon: "Download Statement",
      description: t("encore.guide.preparation.preparation4", { count: bankStatementCountInMonths })
    },
  ];

  const alternative = [
    {
      icon: contactUs,
      altIcon: "Manager",
      description: t("encore.guide.alternative.preparation1")
    }
  ];

  const essentialPreparationAcp = [
    {
      icon: bank,
      altIcon: "E Banking",
      description: t("encore.guideMandatory.preparation.preparation1")
    },
    {
      icon: file,
      altIcon: "Bank Statement",
      description: t("encore.guideMandatory.preparation.preparation2")
    },
    {
      icon: calendar,
      altIcon: "Bank Statement Date",
      description: t("encore.guideMandatory.preparation.preparation3", { count: bankStatementCountInMonths })
    },
    {
      icon: download,
      altIcon: "Download Statement",
      description: t("encore.guideMandatory.preparation.preparation4", { count: bankStatementCountInMonths })
    },
  ];

  const alternativeAcp = [
    {
      icon: contactUs,
      altIcon: "Manager",
      description: t("encore.guideMandatory.alternative.preparation1")
    }
  ];

  const handleGuide = () => {
    if (isForcedBsa) {
      return (
        <div className="encore__twelve__statement__guide">
          <IconButton
            color="primary"
            onClick={() => {
              setGeneralData({ step: previousStep });
            }}
            className="encore__page__back_holder"
          >
            <img src={circleLeftArrow} alt="Back" />
          </IconButton>
          <div className="encore__page__upper">
            <div className="encore__identifier">{t("encore.guideMandatory.smallTitle", { count: bankStatementCountInMonths })}</div>
            <div className="encore__title">{t("encore.guideMandatory.title")}</div>
            <div className="encore__reminder__title" />
          </div>
          <div className="encore__reminder__container">
            <div className="encore__title">
              {t("encore.guideMandatory.preparation.title")}
            </div>
            <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
            <div className="encore__reminder">
              {essentialPreparationAcp.map((essential, index) => {
                return (
                  <div className="encore__container" key={index}>
                    <div className="encore__icon">
                      <img src={essential.icon} alt={essential.altIcon} />
                    </div>
                    <div
                      className="encore__desc"
                      dangerouslySetInnerHTML={{
                        __html: essential.description,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="encore__reminder__container">
            <div className="encore__title">
              {t("encore.guideMandatory.alternative.title")}
            </div>
            <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
            <div className="encore__reminder">
              {alternativeAcp.map((alternativeItem, index) => {
                return (
                  <div className="encore__container" key={index}>
                    <div className="encore__icon">
                      <img src={alternativeItem.icon} alt={alternativeItem.altIcon} />
                    </div>
                    <div
                      className="encore__desc"
                      dangerouslySetInnerHTML={{
                        __html: alternativeItem.description,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="encore__reminder__container">
            <div className="encore__title primary">
              {t("encore.guideMandatory.notice.title")}
            </div>
            <div className="encore__border primary">
              {t("encore.guideMandatory.notice.description")}
            </div>
          </div>
          <div className="encore__next__button">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => {
                setGeneralData({ step: previousStep });
              }}
            >
              {t("encore.guide.button")}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="encore__twelve__statement__guide">
        <IconButton
          color="primary"
          onClick={() => {
            setGeneralData({ step: previousStep });
          }}
          className="encore__page__back_holder"
        >
          <img src={circleLeftArrow} alt="Back" />
        </IconButton>
        <div className="encore__page__upper">
          <div className="encore__identifier">{t("encore.guide.smallTitle", { count: bankStatementCountInMonths })}</div>
          <div className="encore__title">{t("encore.guide.title")}</div>
          <div className="encore__desc">{t("encore.guide.description")}</div>
          <div className="encore__reminder__title" />
        </div>
        <div className="encore__reminder__container">
          <div className="encore__title">
            {t("encore.guide.preparation.title")}
          </div>
          <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
          <div className="encore__reminder">
            {essentialPreparation.map((essential, index) => {
              return (
                <div className="encore__container" key={index}>
                  <div className="encore__icon">
                    <img src={essential.icon} alt={essential.altIcon} />
                  </div>
                  <div
                    className="encore__desc"
                    dangerouslySetInnerHTML={{
                      __html: essential.description,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="encore__reminder__container">
          <div className="encore__title">
            {t("encore.guide.alternative.title")}
          </div>
          <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
          <div className="encore__reminder">
            {alternative.map((alternativeItem, index) => {
              return (
                <div className="encore__container" key={index}>
                  <div className="encore__icon">
                    <img src={alternativeItem.icon} alt={alternativeItem.altIcon} />
                  </div>
                  <div
                    className="encore__desc"
                    dangerouslySetInnerHTML={{
                      __html: alternativeItem.description,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="encore__reminder__container">
          <div className="encore__title primary">
            {t("encore.guide.notice.title")}
          </div>
          <div className="encore__border primary">
            {t("encore.guide.notice.description")}
          </div>
        </div>
        <div className="encore__next__button">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              setGeneralData({ step: previousStep });
            }}
          >
            {t("encore.guide.button")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    handleGuide()
  );
};

export default TwelveStatementGuide;
