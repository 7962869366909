import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { StoreContext } from "../../store/context";

export default function GeneralModal() {
  const { t } = useTranslation();
  const { actions, state } = useContext(StoreContext);
  const { setGeneralDialogData } = actions.generalActions;
  const { show, dialogTitle, dialogContent, showButton = true, buttonText, callback } = state?.generalStates?.dialog;

  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") { return; }
    setShowModal(false);
    setGeneralDialogData({
      show: false,
      callback: null
    });
  };

  const handleConfirm = () => {
    if (callback) {
      callback(true);
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        className="general-modal"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">{t(dialogTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent?.header || dialogContent?.body ? (
              <>
                <span
                  className="header"
                  dangerouslySetInnerHTML={{
                    __html: t(dialogContent?.header),
                  }}
                />
                <span
                  className="body"
                  dangerouslySetInnerHTML={{
                    __html: t(dialogContent?.body),
                  }}
                />
              </>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t(dialogContent),
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showButton
          && (
            <Button className="button" onClick={handleConfirm} color="primary" variant="contained">
              {buttonText ? t(buttonText) : t("common.ok")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
