import { initialState } from "../state/initialStates";
import { generalReducer } from "./generalReducer";
import { credentialReducer } from "./credentialReducer";
import { formReducer } from "./formReducer";

const reducer = (state = initialState, action) => {
  return {
    generalStates: generalReducer(state.generalStates, action),
    credentialStates: credentialReducer(state.credentialStates, action),
    formStates: formReducer(state.formStates, action),
  };
};

export { initialState, reducer };
