import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/context";
import {
  clearCacheProgress,
  navigateToBoostCredit,
  navigateToExternal
} from "../../utils";
import emailSuccess from "../../assets/images/encore/email-success.svg";
import emailFail from "../../assets/images/encore/email-fail.svg";

import "./EmailComp.scss";

const EmailComp = ({ step = 10 }) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { cellPhone, email } = state.formStates;
  const {
    reportResponse,
  } = state.generalStates;

  // always true
  const [successSent, setSuccessSent] = useState(true);

  useEffect(() => {
    if (email && cellPhone) {
      clearCacheProgress(email, `+60${cellPhone}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (step == 10) {
  //     setSuccessSent(true);
  //   } else {
  //     setSuccessSent(false);
  //   }
  // }, [step]);

  return (
    <div className="email-comp">
      <div className="banner">
        <img src={successSent ? emailSuccess : emailFail} alt="Email result" />
      </div>
      <div className="title">
        {t(successSent ? "email.success.header" : "email.fail.header")}
      </div>
      <div className="desc">
        {t(successSent ? (reportResponse.productDetail?.remixUrl ? "email.success.subHeader" : "email.success.subHeaderWithoutLink") : "email.fail.subHeader")}
      </div>
      <div className="encore__next__button">
        {reportResponse.productDetail?.remixUrl ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              navigateToExternal(reportResponse.productDetail?.remixUrl);
            }}
          >
            {t("email.success.button")}
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              navigateToBoostCredit();
            }}
          >
            {t("common.returnHome")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmailComp;
