import React, { useContext } from "react";
import { withStyles, useTheme, makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import { StoreContext } from "../../store/context";

const MobileStepperCustom = withStyles((theme) => ({
  root: {
    width: "100vw",
    background: "white",
    padding: "unset",
  },
  progress: {
    height: "6px",
    width: "100%",
    barColorPrimary: {
      backgroundColor: "red",
    },
  }
}))(MobileStepper);

const MobileStepperProgress = ({ history }) => {
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData } = actions.generalActions;
  const { overallStep, step, isError } = state.generalStates;
  const useStyles = makeStyles({
    colorPrimary: {
      backgroundColor: "#f79e9e",
    },
    barColorPrimary: {
      backgroundColor: step == -2 || isError ? "#FF0000" : step == 10 ? "#4BB543" : "#EA0029",
    }
  });

  const classes = useStyles();

  return (
    <MobileStepperCustom
      hidden={step == 0 || step == -1 || step == -3 || step == -4 || step == -5 || step == 4 || step == 7 || step == 10 || step == 6}
      variant="progress"
      LinearProgressProps={{ classes: { colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary } }}
      steps={overallStep + 1}
      position="static"
      activeStep={step == -2 ? 1 : step > overallStep ? overallStep : step}
    />
  );
};

export default MobileStepperProgress;
