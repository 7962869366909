import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/context";
import closeButton from "../../assets/images/leadGen/closeButton.svg";
import thankyouEmail from "../../assets/images/leadGen/thankyouEmail.svg";

import "./LeadGenThankYouPage.scss";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "unset",
    color: "#124A67"
  }
}));

const LeadGenThankYouPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const [isOpen, setIsOpen] = useState(false);

  const Popup = (props) => {
    const { content, handleClose } = props;
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={handleClose}>x</span>
          {content}
        </div>
      </div>
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="leadgen thankyou">
      <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
        <img src={thankyouEmail} alt="thankyouEmail" width="300" />
      </div>
      <br />
      <br />
      <div className="leadGen-thankyou-title">
        {t("leadGen.thankyouModal.title")}
      </div>
      <br />
      <div
        className="leadGen-thankyou-subTitle"
        dangerouslySetInnerHTML={{
          __html: t("leadGen.thankyouModal.subTitle"),
        }}
      />
      <div className="leadGen-thankyou-subTitle">
        <u><span type="button" onClick={togglePopup}>{t("leadGen.thankyouModal.secondSubTitle")}</span></u>
        <Dialog
          classes={classes}
          open={isOpen}
          keepMounted
          onClose={togglePopup}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            style={{ position: "absolute", right: -20, top: -20, zIndex: 10, cursor: "pointer" }}
          >
            <img
              width="40"
              height="40"
              alt="close"
              src={closeButton}
              onClick={togglePopup}
            />
          </div>
          <DialogTitle id="alert-dialog-slide-title">
            <b>{t("leadGen.importantNote.header")}</b>
          </DialogTitle>
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{
                __html: t("leadGen.importantNote.content", { email: t("leadGen.thankyouModal.email") }),
              }}
            />
            <br />
          </DialogContent>
        </Dialog>
      </div>
      <div style={{ marginTop: "15%" }} className="leadGen-thankyou-desc">
        {t("leadGen.thankyouModal.thirdSubTitle")}
      </div>
      <div style={{ marginBottom: "2%" }} className="leadGen-thankyou-desc">
        <b>{t("leadGen.thankyouModal.email")}</b>
      </div>
    </div>
  );
};

export default LeadGenThankYouPage;
