import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import clevertap from "clevertap-web-sdk";
import { StoreContext } from "../../store/context";
import { isKycApproved, isKycPendingRetry, isKycRejected, isKycWaitingOpsReview, isKycWaitingUserReviewNameNric } from "../../constants/KycStatusEnum";
import KycStepEnum from "../../constants/KycStepEnum";
import * as ubd from "../../utils/userBehaviouralData";
import bannerResultPass from "../../assets/images/encore/jumio/eKYC_Result_Pass@2x.png";
import bannerResultFail from "../../assets/images/encore/jumio/eKYC_Result_Fail@2x.png";
import bannerResultFailRetry from "../../assets/images/encore/jumio/eKYC_Result_Fail_Retry@2x.png";
import bannerResultMoreTime from "../../assets/images/encore/jumio/eKYC_Result_Extratime@2x.png";

import "./KYCResult.scss";
import { navigateToHome } from "../../utils";

const mouseSpeed = [];

const KYCResult = () => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { kycResult, kycPromptRetry, kycReasonCode, kycReasonDetail, token } = state.generalStates;
  const { referralCode } = state.formStates;
  const { setGeneralData } = actions.generalActions;
  const { setKycLoading, retryKycBefore } = actions.credentialActions;
  const { handleUserBehaviourData } = actions.otpActions;

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);
  const [resultTile, setResultTile] = useState("");
  const [resultDesc, setResultDesc] = useState("");
  const [resultBanner, setResultBanner] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showRetryKycButton, setShowRetryKycButton] = useState(false);
  const [showRestartJourneyButton, setShowRestartJourneyButton] = useState(false);

  const reasonify = (fallbackMsg) => {
    return kycReasonCode ? `jumio.response.${kycReasonCode}` : fallbackMsg;
  };

  useEffect(() => {
    if (kycResult) {
      setResultTile("");
      setResultDesc("");
      setResultBanner(null);
      setShowNextButton(false);
      setShowRetryKycButton(false);
      setShowRestartJourneyButton(false);

      // Prepare Clevertap event
      let clevertapStatus;

      // handle each kyc result accordingly
      if (isKycApproved(kycResult)) {
        // successful approved
        clevertapStatus = "Success";
        setResultTile("encore.kycResult.pass.title");
        setResultDesc("encore.kycResult.pass.subTitle");
        setResultBanner(bannerResultPass);
        setShowNextButton(true);
      } else if (isKycWaitingUserReviewNameNric(kycResult, kycReasonCode)) {
        // waiting customer manual review name and nric (BCC001~BCC003)
        clevertapStatus = "Pending User and Ops Review Name NRIC";
        setGeneralData({ kycStep: KycStepEnum.REVIEW_NAME_NRIC });
      } else if (isKycWaitingOpsReview(kycResult)) {
        // waiting Ops manual review
        clevertapStatus = "Pending Ops Review";
        setResultTile("encore.kycResult.moreTime.title");
        setResultDesc(reasonify("encore.kycResult.moreTime.subTitle"));
        setResultBanner(bannerResultMoreTime);
        handleEndJourney();
      } else if (isKycPendingRetry(kycResult)) {
        // waiting customer retry as requested by Ops
        clevertapStatus = "Pending User Reattempt as requested by Ops";
        setResultTile("encore.kycResult.pendingRetry.title");
        setResultDesc("encore.kycResult.pendingRetry.subTitle");
        setResultBanner(bannerResultFailRetry);
        setShowRetryKycButton(true);
      } else if (kycPromptRetry) {
        // waiting customer retry as decided by backend (REJECTED reasons)
        // waiting customer retry after inactive (NOT_EXECUTED)
        // waiting customer retry after time-out (FAILED BCC004,BCC005)
        clevertapStatus = "Fail (retryable)";
        setResultTile("encore.kycResult.failRetry.title");
        setResultDesc(reasonify("encore.kycResult.failRetry.subTitle"));
        setResultBanner(bannerResultFailRetry);
        setShowRetryKycButton(true);
      } else if (isKycRejected(kycResult)) {
        // prevent customer retry aka "blocked" as decided by backend (REJECTED reasons)
        clevertapStatus = "Fail";
        setResultTile("encore.kycResult.failNoRetry.title");
        setResultDesc(reasonify("encore.kycResult.failNoRetry.subTitle"));
        setResultBanner(bannerResultFail);
        handleEndJourney();
      } else {
        // Edge cases now terminate journey first
        // FAILED (non BCC001~BCC005)
        clevertapStatus = "Fail";
        setResultTile("encore.kycResult.failed.title");
        setResultDesc(reasonify("encore.kycResult.failed.subTitle"));
        setResultBanner(bannerResultMoreTime);
        setShowRestartJourneyButton(true);
        handleEndJourney();
      }

      // Send Clevertap event
      const kycReasons = !isKycApproved(kycResult) ? (`${kycReasonCode} - ${kycReasonDetail}`) : undefined;
      clevertap.event.push("act_cp_identity_check_result_jumio", {
        "Status": clevertapStatus,
        "TypesOfRejection": kycReasons,
        "ReferralCode": referralCode,
        "FullURL": window.location.href,
      });
    } else {
      setKycLoading(); // return to loading page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycResult, kycPromptRetry, kycReasonCode]);

  const handleNextButton = () => {
    handleEndJourney();
    setGeneralData({ step: 5 }); // location
  };

  const handleRetryKycButton = () => {
    handleEndJourney();
    retryKycBefore();
  };

  const handleRestartJourneyButton = () => {
    handleEndJourney();
    navigateToHome();
  };

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    if (kycResult) {
      const params = {
        step: ubd.STEP_JUMIO_KYC_RESULT_PREFIX + kycResult,
        startDate: journeyStart,
        endDate: singleStepEnd,
        totalMouseSpeed,
      };
      if (singleStepEnd != null) {
        handleUserBehaviourData(params);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent;
    let currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="encore__kyc__result">
      <div className="title">{t(resultTile)}</div>
      <div
        className="desc"
        dangerouslySetInnerHTML={{
          __html: t(resultDesc)
        }}
      />
      {/* { showKycRejectReason && kycReasonCode && (
        <div className="remarks">
          {`${t("encore.kycResult.remark")}${t(`jumioError.${kycReasonCode}`)}`}
        </div>
      ) } */}
      <div className="banner">
        <img src={resultBanner} alt="result_banner" />
      </div>
      <div className="encore__next__button">
        {showRestartJourneyButton && (
          <Button
            variant="contained"
            color="primary"
            className="button"
            onClick={handleRestartJourneyButton}
          >
            {t("encore.kycResult.failed.buttonText")}
          </Button>
        )}
        {showRetryKycButton && (
          <Button
            variant="contained"
            color="primary"
            className="button"
            onClick={handleRetryKycButton}
          >
            {t("encore.kycResult.failRetry.buttonText")}
          </Button>
        )}
        {showNextButton && (
          <Button
            id="btn_kyc_verified_continue"
            variant="contained"
            color="primary"
            className="button"
            onClick={handleNextButton}
          >
            {t("encore.kycResult.pass.buttonText")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default KYCResult;
