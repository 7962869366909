import {
  SET_CREDENTIAL_DATA,
  RESET_CREDENTIAL_DATA
} from "../types";

export const credentialStates = {
  // email: "aaron.liew@axiatadigital.com",
  // password: "3688",
  // email: "alicia.kee@axiatadigital.com",
  // password: "5087",
  email: "",
  password: "",
  productClass: 4,
  distributorID: null,
  prodID: null,
};

export const credentialReducer = (state, action) => {
  switch (action.type) {
    case SET_CREDENTIAL_DATA:
      return {
        ...state,
        ...action.data
      };
    case RESET_CREDENTIAL_DATA:
      return {
        ...credentialStates
      };
    default:
      return state;
  }
};
