import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/context";
import errorBrowser from "../../assets/images/encore/error-browser.svg";
import successBrowser from "../../assets/images/encore/success-browser.svg";
import successCamera from "../../assets/images/encore/success-camera.svg";
import errorCamera from "../../assets/images/encore/error-camera.svg";
import { getLanguage } from "../../utils";

import "./Compatibility.scss";

const Compatibility = ({ history }) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData } = actions.generalActions;
  const { step, webStatus, previousStep } = state.generalStates;
  const [checkList, setCheckList] = useState([]);

  const goToTwelveBankStatement = (event) => {
    setGeneralData({ previousStep: step, step: 1 });
  };

  const checkError = () => {
    return webStatus.hasCameraPermission && webStatus.isChromeOrSafari;
  };

  const setCheckListError = () => {
    const layout = [];

    if (!webStatus.hasCameraPermission) {
      layout.push(
        <div className="encore__container" key={0}>
          <div className="encore__icon">
            <img src={errorCamera} alt="Unable Access Camera" />
          </div>
          <div
            className="encore__title error"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.camereError.title"),
            }}
          />
          <div
            className="encore__desc"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.camereError.description"),
            }}
          />
        </div>
      );
    }

    if (!webStatus.isChromeOrSafari) {
      layout.push(
        <div className="encore__container" key={1}>
          <div className="encore__icon">
            <img src={errorBrowser} alt="Unsupported Browser" />
          </div>
          <div
            className="encore__title error"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.browserError.title"),
            }}
          />
          <div
            className="encore__desc"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.browserError.description"),
            }}
          />
        </div>
      );
    }

    if (webStatus.hasCameraPermission) {
      layout.push(
        <div className="encore__container" key={0}>
          <div className="encore__icon">
            <img src={successCamera} alt="Camera Enabled" />
          </div>
          <div
            className="encore__title"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.cameraSuccess.title"),
            }}
          />
          <div
            className="encore__desc"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.cameraSuccess.description"),
            }}
          />
        </div>
      );
    }

    if (webStatus.isChromeOrSafari) {
      layout.push(
        <div className="encore__container" key={1}>
          <div className="encore__icon">
            <img src={successBrowser} alt="Web Browser Supported" />
          </div>
          <div
            className="encore__title"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.browserSuccess.title"),
            }}
          />
          <div
            className="encore__desc"
            dangerouslySetInnerHTML={{
              __html: t("encore.compatibility.preparation.browserSuccess.description"),
            }}
          />
        </div>
      );
    }

    setCheckList(layout);
  };

  useEffect(() => {
    setCheckListError();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLanguage()]);

  return (
    <div className="encore__compatibility">
      <div className="encore__page__upper">
        <div className="encore__identifier">{t("encore.compatibility.smallTitle")}</div>
        <div className="encore__title">{t("encore.compatibility.title")}</div>
        <div className="encore__desc">{t("encore.compatibility.description")}</div>
        <div className="encore__reminder__title" />
      </div>
      <div className="encore__reminder__container">
        <div className={checkError() ? "encore__title " : "encore__title error"}>
          {t("encore.compatibility.preparation.title")}
        </div>
        <div className="encore__reminder">
          {checkList.map((checkListItem, index) => {
            return (checkListItem);
          })}
        </div>
      </div>
      <div className="encore__next__button">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className="button"
          onClick={() => {
            setGeneralData({ previousStep: step, step: 1 });
          }}
        >
          {t("encore.compatibility.button")}
        </Button>
      </div>
      <div className="encore__next__button">
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          className="button"
          onClick={() => {
            setGeneralData({ step: 0 });
          }}
        >
          {t("common.back")}
        </Button>
      </div>
    </div>
  );
};

export default Compatibility;
