import React from "react";
import ReactDOM from "react-dom";
import StoreProvider from "./store/context";
import * as serviceWorker from "./serviceWorker";
import disableConsoleOutput from "./disableConsoleLog";
import App from "./App";

// Disable some console methods
if (process.env.REACT_APP_ENV === "production") {
  // whitelist: info, warn, error
  disableConsoleOutput(["log", "debug", "time", "timeEnd"]);
  console.warn("Some console methods are disabled in Production mode.");
}

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
