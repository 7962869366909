import React from "react";

import "./PageLoader.scss";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <div className="loader-holder">
        <div className="spinner-grow text-primary loader" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-primary loader" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-primary loader" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
