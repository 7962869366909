import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Progress } from "shards-react";
// import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { StoreContext } from "../../store/context";
import useStateAndRef from "../../hooks/useStateAndRef";
import KycStatusEnum, { isKycApproved } from "../../constants/KycStatusEnum";
import KycStepEnum from "../../constants/KycStepEnum";
import * as ubd from "../../utils/userBehaviouralData";
import boostLogoGif from "../../assets/images/Boost_Logo_Loading_67x67.gif";

import "./KYCLoading.scss";

const BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS = 3;
const LONGER_THAN_USUAL_THRESHOLD_IN_SECONDS = 60; // kycLoadingTimeLeft + 30
const REPEAT_KYC_CHECK_EVERY_X_SECONDS = 5;
// const REPEAT_KYC_CHECK_TIME_OUT_SECONDS = 120;
const mouseSpeed = [];

let animationIntervalID = 0;
let checkKycIntervalID = 0;

const KYCLoading = () => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);

  const { kycResult, isError, kycLoadingTimeLeft, step, kycStep, isResumeJourney, isRestoreSessionKey } = state.generalStates;
  const { setGeneralData, testApi } = actions.generalActions;
  const { getJumioKycStatus, getSessionInfo } = actions.credentialActions;
  const { handleUserBehaviourData } = actions.otpActions;
  const { cellPhone, email } = state.formStates;

  const [loadingSubtitle, setLoadingSubtitle] = useState("encore.kycLoading.subTitle");
  const [processTitle, setProcessTitle] = useState([]);
  const [processTitleColor, setProcessTitleColor] = useState([]);
  const [loadingText, setLoadingText] = useState("");
  const [step1Progress, setStep1Progress] = useState(0);
  const [step2Progress, setStep2Progress] = useState(0);
  const [step3Progress, setStep3Progress] = useState(0);

  const [timeOut, setTimeOut] = useState(kycLoadingTimeLeft);
  const [timeTrack, setTimeTrack, refTimeTrack] = useStateAndRef(0);
  const [hasCompletedAnim, setHasCompletedAnim, refHasCompletedAnim] = useStateAndRef(false);
  const [hasKycResult, setHasKycResult, refHasKycResult] = useStateAndRef(false);

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const pushNewTitle = (titleCode, colorCode) => {
    setProcessTitle((prevProcessTitle) => [
      ...prevProcessTitle,
      titleCode,
    ]);
    setProcessTitleColor((prevProcessTitleColor) => [
      ...prevProcessTitleColor,
      colorCode,
    ]);
  };

  const createAnimationTimer = () => {
    const step1Title = "encore.kycLoading.processing.process1";
    const step1TitleColor = "#05A0D3"; // rgb(5, 160, 211)
    const step2Title = "encore.kycLoading.processing.process2";
    const step2TitleColor = "#05D3B7"; // rgb(5, 211, 183)
    const step3Title = "encore.kycLoading.processing.process3";
    const step3TitleColor = "#05BED3"; // rgb(5, 190, 211)
    const step4Title = "encore.kycLoading.processing.complete";
    const step3AlternateTitle = "encore.kycLoading.processing.process3Alternate";

    // Total up should be 100%
    // const STEP_1_DURATION_PERCENT = 40;
    // const STEP_2_DURATION_PERCENT = 30;
    // const STEP_3_DURATION_PERCENT = 30;
    const STEP_4_RESERVED_PERCENT = 5; // the remaining % of bar to be left empty before result is received

    // const tenPercTime = Math.ceil(kycLoadingTimeLeft / 10);
    // const step1Time = 0 + tenPercTime * (STEP_1_DURATION_PERCENT / 10);
    // const step2Time = step1Time + tenPercTime * (STEP_2_DURATION_PERCENT / 10);
    // const step3Time = step2Time + tenPercTime * (STEP_3_DURATION_PERCENT / 10);

    const oneThirdDuration = Math.ceil((kycLoadingTimeLeft) / 3);
    const colorIncrementPerSec = (1 / (oneThirdDuration * 3)) * 100;
    const step1Time = oneThirdDuration;
    const step2Time = oneThirdDuration * 2;
    const step3Time = (oneThirdDuration * 3) - Math.ceil(STEP_4_RESERVED_PERCENT / colorIncrementPerSec);

    const timeout = step3Time + BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS;
    setTimeOut(timeout);

    let timeElapsed = 0;
    let step1ProgressPercent = 0;
    let step2ProgressPercent = 0;
    let step3ProgressPercent = 0;

    if (isResumeJourney) {
      // Jump to end of step3
      step1ProgressPercent = 100 / 3;
      step2ProgressPercent = 100 / 3;
      step3ProgressPercent = 100 / 3;
      setStep1Progress(step1ProgressPercent);
      setStep2Progress(step2ProgressPercent);
      setStep3Progress(step3ProgressPercent);
      pushNewTitle(step1Title, step1TitleColor);
      pushNewTitle(step2Title, step2TitleColor);
      setLoadingText(step3Title);
      timeElapsed = step3Time;
      setTimeTrack(step3Time);
    }

    setGeneralData({ timerOngoing: true });
    const id = setInterval(() => {
      if (timeElapsed >= 0) {
        if (timeElapsed < step1Time) {
          // step 1: Validating
          step1ProgressPercent += colorIncrementPerSec;
          setStep1Progress(step1ProgressPercent);
          setLoadingText(step1Title);
        } else if (timeElapsed < step2Time) {
          // step 2: Processing
          step2ProgressPercent += colorIncrementPerSec;
          setStep2Progress(step2ProgressPercent);
          setLoadingText(step2Title);
          pushNewTitle(step1Title, step1TitleColor);
        } else if (timeElapsed < step3Time) {
          // step 3: Retrieving results
          step3ProgressPercent += colorIncrementPerSec;
          setStep3Progress(step3ProgressPercent);
          setLoadingText(step3Title);
          pushNewTitle(step2Title, step2TitleColor);
        } else if (refHasKycResult.current && !refHasCompletedAnim.current) {
          // step 4: Completed
          const currentBar = step1ProgressPercent + step2ProgressPercent + step3ProgressPercent;
          if (currentBar < 100) {
            step3ProgressPercent += (100 - currentBar);
            setStep3Progress(step3ProgressPercent);
          }
          setLoadingText(step4Title);
          pushNewTitle(step3Title, step3TitleColor);
          setHasCompletedAnim(true);
          setTimeOut(refTimeTrack.current + 1 + BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS);
        } else if (timeElapsed >= LONGER_THAN_USUAL_THRESHOLD_IN_SECONDS) {
          // show comforting message
          setLoadingSubtitle(step3AlternateTitle);
        }
      }
      timeElapsed += 1;
      setTimeTrack((prevTimeTrack) => prevTimeTrack + 1);
    }, 1000);
    animationIntervalID = id;
  };

  const removeAnimationTimer = () => {
    if (animationIntervalID) {
    // console.log("removeAnimationTimer", animationIntervalID);
      clearInterval(animationIntervalID);
      animationIntervalID = null;
      setGeneralData({ timerOngoing: false });
    }
  };

  const createCheckKycTimer = () => {
    const id = setInterval(() => {
      // Time out handle by services-kyc
      getJumioKycStatus();
    }, REPEAT_KYC_CHECK_EVERY_X_SECONDS * 1000);
    checkKycIntervalID = id;
  };

  const removeCheckKycTimer = () => {
    // console.log("removeCheckKycTimer", checkKycIntervalID);
    clearInterval(checkKycIntervalID);
  };

  const showKycResult = () => {
    handleEndJourney();
    removeAnimationTimer();
    removeCheckKycTimer();
    setGeneralData({ kycStep: KycStepEnum.RESULT });
  };

  useEffect(() => {
    // set timerOngoing to false so that Encore/index.jsx setStepContent() will work
    if (isError || kycStep == KycStepEnum.PRE_JUMIO) {
      removeAnimationTimer();
      if (!isResumeJourney) {
        removeCheckKycTimer();
      }
      return;
    }

    if (timeTrack > timeOut && hasCompletedAnim && hasKycResult) {
      // Animation already finished, now show result
      showKycResult();
    } else if (hasKycResult) {
      if (!isKycApproved(kycResult)) {
        // Ignore animation and immediately show result
        //  - if result is not passed (new journey AND resume journey)
        showKycResult();
      } else {
        // Stop KYC checking and wait for animation to complete
        //  - if result is passed (new journey AND resume journey)
        removeCheckKycTimer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTrack, kycStep]);

  useEffect(() => {
    if (kycResult && kycResult != KycStatusEnum.INITIATED) {
      setHasKycResult(true);
    } else {
      setHasKycResult(false);
    }
  }, [kycResult]);

  useEffect(() => {
    if (step == 4 && kycStep == KycStepEnum.LOADING) {
      // DEV:
      // testApi();
      if (isRestoreSessionKey || !cellPhone || !email) {
        getSessionInfo(); // use sessionKey to fetch user details if missing
      }
      createAnimationTimer();
      if (!isResumeJourney) {
        createCheckKycTimer();
      } else {
      // If isResumeJourney, get result immediately
        getJumioKycStatus();
      }
      return () => {
        removeAnimationTimer();
        if (!isResumeJourney) {
          removeCheckKycTimer();
        }
      };
    }
  }, []);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const getProcessTitle = () => {
    const result = [];
    const color = processTitleColor.filter(onlyUnique).slice();
    const unique = processTitle.filter(onlyUnique);
    unique
      .slice()
      .forEach((title, index) => {
        result.push(
          <tr key={index} style={{ color: color[index] }}>
            <td>
              <CheckCircleIcon />
            </td>
            <td className="movableText">
              {" "}
              {t(`${title}`)}
            </td>
            <td>
              <CheckCircleIcon style={{ opacity: 0 }} />
            </td>
          </tr>
        );
      });
    return result;
  };

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const params = {
      step: ubd.STEP_JUMIO_KYC_LOADING,
      startDate: journeyStart,
      endDate: singleStepEnd,
      totalMouseSpeed,
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent;
    let currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="encore__kyc__loading">
      <div className="spinner">
        {/* <CircularProgress style={{ color: "#231F20" }} size={30} /> */}
        <img src={boostLogoGif} alt="Boost" />
      </div>
      <div className="title">{t("encore.kycLoading.title")}</div>
      <div
        className="desc"
        dangerouslySetInnerHTML={{
          __html: t(loadingSubtitle),
        }}
      />
      <div className="progress-container">
        <Progress multi className="loading-test">
          <Progress bar value={step1Progress} className="blue-testing" />
          <Progress bar value={step2Progress} className="green-testing" />
          <Progress bar value={step3Progress} className="light-blue-testing" />
        </Progress>
      </div>
      <div id="loadingText1" className="loading-text">{t(`${loadingText}`)}</div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <table className="table-style">
          <thead>{getProcessTitle()}</thead>
        </table>
      </div>
      {/* <div className="kyc__reminder__container">
        <div className="reminder__title controlled-width">
          {t("encore.kycLoading.notice.title")}
        </div>
        <div className="reminder__border controlled-width">
          <div className="reminder__desc">
            <img src={iconDocumentRed} alt="Secure" />
            <span
              dangerouslySetInnerHTML={{
                __html: t("encore.kycLoading.notice.description"),
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default KYCLoading;
