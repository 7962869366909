import {
  VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS,
  VERIFY_BASIC_DETAIL_PAGE_LOAD_FAILED,
  ERROR_MESSAGE,
  IS_LOADING,
  IS_ERROR
} from "../types";

import api from "../../utils/api";
import { getStandardizedErrorMsgs } from "../../utils";

export const verifyPageActions = (props) => {
  const showErrorPage = (errorMessage = null) => {
    props.dispatch({ type: IS_LOADING, data: false });
    if (errorMessage) {
      props.dispatch({ type: ERROR_MESSAGE, data: errorMessage });
    }
    props.dispatch({ type: IS_ERROR });
  };

  return {
    verifyReferralKey: (referralKey, email, cellPhone, callback) => {
      return api.funnelService.post("/kyc/uniqueIdValidation", { uniqueId: referralKey, email: email, mobileNumber: `60${cellPhone}` }).then(() => {
        props.dispatch({ type: VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS });
        if (callback) {
          callback();
        }
      }).catch((error) => {
        const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
        showErrorPage(msgForEndUser);
      });
    }
  };
};
