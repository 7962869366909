import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import sadFace from "../../assets/images/leadGen/sadFace.svg";

import "./LeadGenSorryPage.scss";

const LeadGenSorryPage = () => {
  const { t } = useTranslation();

  return (
    <div className="leadgen sorry">
      <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
        <img src={sadFace} alt="sadFace" width="334" />
      </div>
      <div className="leadGen-sorry-title">
        {t("leadGen.sorryModal.title")}
      </div>
      <div className="leadGen-sorry-desc">
        {t("leadGen.sorryModal.subTitle")}
      </div>
      <br />
      <br />
      <div className="leadGen-sorry-button-container">
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={() => {

          }}
        >
          {t("leadGen.sorryModal.buttonTitle")}
        </Button>
      </div>
      <div style={{ marginTop: "15%" }} className="desc">
        {t("leadGen.sorryModal.secondSubTitle")}
      </div>
      <div style={{ marginBottom: "2%" }} className="desc">
        <b>{t("leadGen.sorryModal.email")}</b>
      </div>
    </div>
  );
};

export default LeadGenSorryPage;
