import i18n from "i18next";
import DetectRTC from "detectrtc";

const {
  REACT_APP_TOGGLE_INITIATE_PAYLOAD,
  REACT_APP_BOOST_CREDIT_WEBSITE_URL,
  REACT_APP_BOOST_CREDIT_KK_WEBSITE_URL,
  REACT_APP_CAPITAL_URL,
} = process.env;

export const TYPE_CAPITAL = 8011;
export const TYPE_CAPITAL_PLUS = 8011;

export const MAXIMUM_FILE_UPLOAD = 12;
export const MINIMUM_FILE_UPLOAD = 4;

export const generateRandomID = () => parseInt(Date.now() + ((Math.random() * 100000).toFixed()));
export const toCent = (amount) => amount * 100;
export const toDollar = (amount) => amount / 100;

export const getLanguage = () => localStorage.getItem("language") || i18n.language || window.localStorage.i18nextLng;

export const getToggleInitiatePayload = () => REACT_APP_TOGGLE_INITIATE_PAYLOAD;

export const TIME_ZONE = "Asia/Kuala_Lumpur";
export const DATE_FORMAT = "yyyy-MM-DDTHH:mm:ssZZ";

export const getTermsAndConditionsUrl = (distributorId) => {
  return "https://funnel-ada-matta.s3-ap-southeast-1.amazonaws.com/Funnel-ADA-Privacy-Notice.pdf";
};
export const toDollarDisplay = (amount, changeToDollar, style, showDecimal) => {
  const dollar = changeToDollar ? toDollar(amount) : amount;
  return new Intl.NumberFormat("en-MY", {
    style: style || "currency", // currency or decimal
    currency: "MYR",
    minimumFractionDigits: showDecimal ? 2 : 0,
  }).format(dollar);
};

export const toNumber = (value) => {
  if (typeof value === "undefined") return 0;
  return Number(value.toString().replace(/[,]/g, ""));
};

export const currencyFormatter = (currency) => {
  const filtered = currency.replace(/[^0-9.]/g, "").split(".");
  const integer = filtered.shift();
  const hasDecimal = filtered.length > 0;
  const fraction = filtered.join("").slice(0, 2);
  return new Intl.NumberFormat(["en-MY"], { currency: "MYR" }).format(integer) + (hasDecimal ? `.${fraction}` : "");
};

export const getHomePageUrlParam = () => {
  let url = `${process.env.PUBLIC_URL}`;
  if (sessionStorage.getItem("token")) {
    url += `/?token=${sessionStorage.getItem("token")}`;

    if (sessionStorage.getItem("referral") && sessionStorage.getItem("referralKey")) {
      url = buildUrlQueryParam(url, {
        referral: sessionStorage.getItem("referral"),
        referralKey: sessionStorage.getItem("referralKey"),
      });
    }
  } else {
    url = getBoostCreditUrl();
  }
  return url;
};

export const navigateToHome = () => {
  navigateToExternal(getHomePageUrlParam());
};

export const getBoostCreditUrl = (referral, referralKey) => {
  const ref = referral || sessionStorage.getItem("referral");
  const refKey = referralKey || sessionStorage.getItem("referralKey");
  if (ref && refKey) {
    return buildUrlQueryParam(REACT_APP_BOOST_CREDIT_KK_WEBSITE_URL, {
      referral: ref,
      referralKey: refKey,
    });
  }
  return REACT_APP_BOOST_CREDIT_WEBSITE_URL;
};

export const getMwcUrl = (landingUrl, code, utmSource, utmMedium, referral) => {
  // return `${landingUrl}?code=${code}&skipIntro=1&utm_source=${utmSource}&utm_medium=${utmMedium}${referral ? `&referral=${referral}` : ""}`;
  return buildUrlQueryParam(landingUrl || REACT_APP_CAPITAL_URL, {
    code,
    skipIntro: "1",
    "utm_source": utmSource,
    "utm_medium": utmMedium,
    referral,
    language: getLanguage(),
  });
};

export const navigateToBoostCredit = () => {
  navigateToExternal(getBoostCreditUrl());
};

export const navigateToExternal = (path) => {
  if (path) {
    window.location.replace(path);
  }
};

export const checkBusinessHour = () => {
  // var currentTime = moment.tz(moment(), 'Asia/Kuala_Lumpur').format('HHmm');
  // if (parseInt(currentTime) >= parseInt('0830') && parseInt(currentTime) <= parseInt('2130')) {
  //     return true;
  // } else {
  //     return false;
  // }
  return true;
};

export const getWebStatus = () => {
  return new Promise((resolve, reject) => {
    return DetectRTC.load(() => {
      const data = {
        hasCameraPermission: DetectRTC.isWebsiteHasWebcamPermissions,
        isChromeOrSafari: (DetectRTC.browser.isChrome || DetectRTC.browser.isSafari),
        hasCheckRTC: true,
        step: 1
      };

      if (!DetectRTC.isWebsiteHasWebcamPermissions || (!DetectRTC.browser.isChrome && !DetectRTC.browser.isSafari)) {
        // data.step = -2;
        data.step = 1;
      }
      resolve(data);
    });
  });
  // const data = {
  //     hasCameraPermission: false,
  //     isChromeOrSafari: true,
  //     hasCheckRTC: true,
  //     step: 1
  // }
  // return data;
};

export const mask = (str) => {
  if (str != null && str !== "") {
    return new Array(str.length - 3).join("X") + str.substring(str.length - 4, str.length);
  }
};

export const getLanguageMapping = () => {
  let language;

  switch (getLanguage()) {
    case "en":
      language = "en-gb";
      break;
    case "ms":
      language = "ms";
      break;
    default:
      language = "ms";
  }

  return language;
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const removeLeadingCountryCode = (msisdn = "") => {
  if (msisdn.startsWith("60")) {
    return msisdn.replace("60", "");
  }
  return msisdn;
};

export const saveCacheProgress = (_email, _msisdn, type, payload) => {
  if (_email && _msisdn) {
    console.log(`[SAVE_PROGRESS] Backing up ${type} for ${_email}_${_msisdn}`, payload);

    const cachedData = JSON.parse(localStorage.getItem(`encoreCache_${_email}_${_msisdn}`) || "{}");

    const newData = {
      ...cachedData,
      [type]: payload
    };
    localStorage.setItem(`encoreCache_${_email}_${_msisdn}`, JSON.stringify(newData));
  }
};

export const loadCacheProgress = (_email, _msisdn, type) => {
  if (_email && _msisdn) {
    const cachedData = JSON.parse(localStorage.getItem(`encoreCache_${_email}_${_msisdn}`) || "{}");
    console.log(`[SAVE_PROGRESS] Restoring ${type} for ${_email}_${_msisdn}`, cachedData);
    return cachedData?.[type] || {};
  }
  return {};
};

export const clearCacheProgress = (_email, _msisdn) => {
  if (_email && _msisdn) {
    console.log(`[SAVE_PROGRESS] Deleting cache for ${_email}_${_msisdn}`);
    localStorage.removeItem(`encoreCache_${_email}_${_msisdn}`);
  }
};

export const buildMultiLingualErrorMsg = (i18nMessageCode = "") => {
  if (i18nMessageCode) {
    return {
      en: i18n.t(i18nMessageCode, { lng: "en" }),
      ms: i18n.t(i18nMessageCode, { lng: "ms" })
    };
  }

  return "";
};

export const getStandardizedErrorMsgs = (apiResponse, fallbackMsg) => {
  //   "error": {
  //     "code": "BO-0004",
  //     "message": "Invalid app id"
  //     "en": "",
  //     "ms": ""
  //   }

  // support dynamic backend structure (BE should standardize but here we go)
  const errorObject = apiResponse?.error ?? apiResponse;
  errorObject.code = String(errorObject.code);

  if (errorObject) {
    return {
      msgForAnalytics: getGAErrorMessage(errorObject, fallbackMsg),
      msgForEndUser: getUIErrorMessage(errorObject, fallbackMsg)
    };
  }

  return {};
};

export const getGAErrorMessage = (errorObject, fallbackMsg) => {
  const systemFallbackMsg = i18n.t("common.systemError", { lng: "en" });

  if (errorObject) {
    return errorObject.code || errorObject.message || fallbackMsg || systemFallbackMsg;
  }

  return fallbackMsg || systemFallbackMsg;
};

export const getUIErrorMessage = (errorObject, fallbackMsg) => {
  const systemFallbackMsg = buildMultiLingualErrorMsg("common.systemError");

  if (errorObject) {
    const EXCLUDED_ERROR_CODES = ["-1"];

    if (EXCLUDED_ERROR_CODES.includes(errorObject.code)) {
      // fallback to FE message if BE return -1. This should be changed if BE started to standardize all error messages.
      return appendErrorCode(systemFallbackMsg, errorObject.code);
    } if (errorObject[getLanguage()]) {
      // if detected multi-lingual error messages
      return {
        en: appendErrorCode(errorObject.en, errorObject.code),
        ms: appendErrorCode(errorObject.ms, errorObject.code)
      };
    }

    return appendErrorCode(errorObject.message || fallbackMsg || systemFallbackMsg, errorObject.code);
  }

  return fallbackMsg || systemFallbackMsg;
};

const appendErrorCode = (str = "", code = "") => {
  return str + (code ? ` (${code})` : "");
};

export const buildUrlQueryParam = (url, params = {}) => {
  let temp = url;
  if (temp.includes("?")) {
    if (temp.endsWith("&")) {
      temp = temp.slice(0, -1);
    }
  } else {
    temp += "?";
  }
  if (params && Object.keys(params).length > 0) {
    // { k: v, a: b }
    Object.keys(params).forEach((k) => {
      if (k && params[k]) {
        temp += `&${k}=${params[k]}`;
      }
    });
  }

  return temp;
};
