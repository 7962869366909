import React, { useContext, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Header";
import MobileStepperProgress from "../MobileStepperProgress";
import Footer from "../Footer";
import PageLoader from "../PageLoader";
import { StoreContext } from "../../store/context";
import alert from "../../assets/images/leadGen/alert.svg";
import closeButton from "../../assets/images/leadGen/closeButton.svg";

import "./TemplateFullHeight.scss";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "unset",
    color: "#124A67"
  }
}));

const TemplateFullHeight = (props) => {
  const { step: propStep, children } = props;
  const classes = useStyles();
  const { state } = useContext(StoreContext);
  const { isLoading, step, isMaintenance, maintenanceStep, browserName, browserLogoUrl } = state.generalStates;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const Popup = (options) => {
    const { content, handleClose } = options;
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={handleClose}>x</span>
          {content}
        </div>
      </div>
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  function loadBrowserTab() {
    if (browserName === null || browserName === "" || typeof browserName === "undefined") {
      document.title = document.title;
    } else {
      document.title = browserName;
      document.querySelector("link[rel*='icon'").href = browserLogoUrl;
    }
  }

  return (
    <div className="page">
      {loadBrowserTab()}
      {isLoading && <PageLoader />}
      {step == -5 || step == -4 ? isMaintenance ? maintenanceStep == 100 ? (
        <>
          <Alert className="alert alert-info" role="alert" style={{ top: "0%", backgroundColor: "#FFE95D", display: "flex", justifyContent: "center" }}>
            <div style={{ color: "#000000", display: "flex", gap: "4px" }}>
              <div><img alt="alert" src={alert} /></div>
              <div style={{ textAlign: "left" }}>
                {t("leadGen.register.alertTitle")}
                <u><b><span onClick={togglePopup} style={{ cursor: "pointer" }}>{t("leadGen.thankyouModal.secondSubTitle")}</span></b></u>
              </div>
            </div>
          </Alert>
          <Header />
          <Dialog
            classes={classes}
            open={isOpen}
            keepMounted
            onClose={togglePopup}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              style={{ position: "absolute", right: -20, top: -20, zIndex: 10, cursor: "pointer" }}
            >
              <img
                width="40"
                height="40"
                alt="close"
                src={closeButton}
                onClick={togglePopup}
              />
            </div>
            <DialogTitle id="alert-dialog-slide-title">
              <b>{t("leadGen.importantNote.header")}</b>
            </DialogTitle>
            <DialogContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("leadGen.importantNote.content", { email: t("leadGen.thankyouModal.email") }),
                }}
              />
              <br />
            </DialogContent>
          </Dialog>
        </>
      ) : <Header /> : <></>
        : <Header />}
      <div className="page-container-full-height">
        <MobileStepperProgress />
        {children}
      </div>
      {step == -5 || step == -4 ? isMaintenance ? [100, 101, 102, 103].includes(maintenanceStep) ? <Footer steps={propStep} /> : <></> : <></>
        : <Footer steps={propStep} />}

    </div>
  );
};

export default TemplateFullHeight;
