import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import { StoreContext } from "../../store/context";
// import file from "../../assets/images/encore/file.svg";
import checklist from "../../assets/images/encore/guide/Checklist.svg";
import camera from "../../assets/images/encore/guide/Camera.svg";
import idcard from "../../assets/images/encore/guide/ID.svg";
import internet from "../../assets/images/encore/guide/Internet.svg";
import document from "../../assets/images/encore/guide/Documents.svg";
import { BSA_JOURNEY_STATUS_FORCED, BSA_JOURNEY_STATUS_NOT_REQUIRED } from "../../utils/constants";

import "./Welcome.scss";

const Welcome = ({ history }) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData } = actions.generalActions;
  const { step, webStatus, bankStatementRequired, bsaRequirementStatus, bankStatementCountInMonths } = state.generalStates;
  const [isForcedBsa, setIsForcedBsa] = useState(false);

  useEffect(() => {
    setIsForcedBsa((bsaRequirementStatus == BSA_JOURNEY_STATUS_FORCED) || bankStatementRequired);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaRequirementStatus, bankStatementRequired]);

  const essentialPreparation = [
    {
      icon: camera,
      altIcon: "Camera",
      description: t("encore.welcome.preparation.preparation1")
    },
    {
      icon: idcard,
      altIcon: "MyKad",
      description: t("encore.welcome.preparation.preparation2")
    },
    {
      icon: internet,
      altIcon: "Internet",
      description: t("encore.welcome.preparation.preparation3")
    },
    {
      icon: document,
      altIcon: "Business Registration Number",
      description: t("encore.welcome.preparation.preparation4")
    },
  ];

  const twelveMonthStatement = [
    {
      icon: checklist,
      altIcon: "e-Statement",
      description: t("encore.welcome.twelveMonthStatement.preparation1", { count: bankStatementCountInMonths }),
      goToTwelveBankStatement: t("encore.welcome.twelveMonthStatement.preparation1GoToTwelve")

    },
    {
      icon: document,
      altIcon: "PDF",
      description: t("encore.welcome.twelveMonthStatement.preparation2")
    }
  ];

  const step1 = [
    {
      icon: camera,
      altIcon: "Camera",
      description: t("encore.welcomeMandatory.preparation.preparation1")
    },
    {
      icon: idcard,
      altIcon: "MyKad",
      description: t("encore.welcomeMandatory.preparation.preparation2")
    },
    {
      icon: internet,
      altIcon: "Internet",
      description: t("encore.welcomeMandatory.preparation.preparation3")
    },
    {
      icon: document,
      altIcon: "Business Registration Number",
      description: t("encore.welcomeMandatory.preparation.preparation4")
    },
  ];

  const step2 = [
    {
      icon: checklist,
      altIcon: "e-Statement",
      description: t("encore.welcomeMandatory.twelveMonthStatement.preparation1", { count: bankStatementCountInMonths }),
      goToTwelveBankStatement: t("encore.welcomeMandatory.twelveMonthStatement.preparation1GoToTwelve")

    },
    {
      icon: document,
      altIcon: "PDF",
      description: t("encore.welcomeMandatory.twelveMonthStatement.preparation2")
    }
  ];

  const goToTwelveBankStatement = () => {
    setGeneralData({ previousStep: step, step: -1 });
  };

  const handleWelcomePage = () => {
    if (isForcedBsa) {
      return (
        <div className="encore__welcome">
          <div className="encore__page__upper">
            <div className="encore__identifier">{t("encore.welcomeMandatory.smallTitle")}</div>
            {/* <div className="encore__banner">
              <img src={financing} alt="Financing" width="310" />
            </div> */}
            <div className="encore__title">{t("encore.welcomeMandatory.title")}</div>
            <div className="encore__desc">{t("encore.welcomeMandatory.description")}</div>
            <div className="encore__reminder__title" />
          </div>
          <div className="encore__reminder__container">
            <div className="encore__major__title">
              {t("encore.welcomeMandatory.preparation.majorTitle")}
            </div>
            <div className="encore__title primary">
              {t("encore.welcomeMandatory.preparation.title")}
            </div>
            <div className="encore__reminder">
              {step1.map((step1Item, index) => {
                return (
                  <div className="encore__container" key={index}>
                    <div className="encore__icon">
                      <img src={step1Item.icon} alt={step1Item.altIcon} />
                    </div>
                    <div
                      className="encore__desc"
                      dangerouslySetInnerHTML={{
                        __html: step1Item.description,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="encore__reminder__container">
            <div className="encore__major__title">
              {t("encore.welcomeMandatory.twelveMonthStatement.majorTitle")}
            </div>
            <div className="encore__title primary">
              {t("encore.welcomeMandatory.twelveMonthStatement.title")}
            </div>
            <div className="encore__reminder">
              {step2.map((step2Item, index) => {
                return (
                  <div className="encore__container" key={index}>
                    <div className="encore__icon">
                      <img src={step2Item.icon} alt={step2Item.altIcon} />
                    </div>
                    <div
                      className="encore__desc"
                      dangerouslySetInnerHTML={{
                        __html: step2Item.description,
                      }}
                    />
                    {step2Item.goToTwelveBankStatement
                      ? (
                        <>
                          <div
                            className="div-link-primary"
                            onClick={() => { goToTwelveBankStatement(); }}
                            dangerouslySetInnerHTML={{
                              __html: step2Item.goToTwelveBankStatement,
                            }}
                          />
                        </>
                      ) : <></>}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="encore__next__button">
            <Button
              id="btn_im_ready"
              fullWidth
              variant="contained"
              color="primary"
              className="button"
              onClick={() => {
                setGeneralData({ previousStep: step, step: webStatus.step });
              }}
            >
              {t("encore.welcomeMandatory.button")}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="encore__welcome">
        <div className="encore__page__upper">
          <div className="encore__identifier">{t("encore.welcome.smallTitle")}</div>
          {/* <div className="encore__banner">
                <img src={financing} alt="Financing" width="310" />
              </div> */}
          <div className="encore__title">{t("encore.welcome.title")}</div>
          <div className="encore__desc">{t("encore.welcome.description")}</div>
          <div className="encore__reminder__title" />
        </div>
        <div className="encore__reminder__container">
          <div className="encore__title">
            {t("encore.welcome.preparation.title")}
          </div>
          <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
          <div className="encore__reminder">
            {essentialPreparation.map((essential, index) => {
              return (
                <div className="encore__container" key={index}>
                  <div className="encore__icon">
                    <img src={essential.icon} alt={essential.altIcon} />
                  </div>
                  <div
                    className="encore__desc"
                    dangerouslySetInnerHTML={{
                      __html: essential.description,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {bsaRequirementStatus != BSA_JOURNEY_STATUS_NOT_REQUIRED && (
          <>
            <div className="encore__reminder__container">
              <div className="encore__title">
                {t("encore.welcome.twelveMonthStatement.title")}
              </div>
              <Divider style={{ marginTop: "0.5%", marginBottom: "2%" }} />
              <div className="encore__reminder">
                {twelveMonthStatement.map((twelveMonth, index) => {
                  return (
                    <div className="encore__container" key={index}>
                      <div className="encore__icon">
                        <img src={twelveMonth.icon} alt={twelveMonth.altIcon} />
                      </div>
                      <div
                        className="encore__desc"
                        dangerouslySetInnerHTML={{
                          __html: twelveMonth.description,
                        }}
                      />
                      {twelveMonth.goToTwelveBankStatement
                        ? (
                          <>
                            <div
                              className="div-link-primary"
                              onClick={() => { goToTwelveBankStatement(); }}
                              dangerouslySetInnerHTML={{
                                __html: twelveMonth.goToTwelveBankStatement,
                              }}
                            />
                          </>
                        ) : <></>}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="encore__reminder__container">
              <div className="encore__title primary">
                {t("encore.welcome.notice.title")}
              </div>
              <div className="encore__border primary">
                {t("encore.welcome.notice.description")}
              </div>
            </div>
          </>
        )}

        <div className="encore__next__button">
          <Button
            id="btn_im_ready"
            fullWidth
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              setGeneralData({ previousStep: step, step: webStatus.step });
            }}
          >
            {t("encore.welcome.button")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    handleWelcomePage()
  );
};

export default Welcome;
