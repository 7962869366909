import React from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import "./LanguageToggleButton.scss";

const LanguageToggleButton = () => {
  const { i18n } = useTranslation();

  const handleChange = (event, value) => {
    i18n.changeLanguage(value);
    if (value) {
      localStorage.setItem("language", value);
    }
  };

  return (
    <ToggleButtonGroup className="language-toggle-group" size="small" value={i18n.language} exclusive onChange={handleChange}>
      <ToggleButton className="language-toggle" selected={i18n.language == "en"} key={1} value="en">
        EN
      </ToggleButton>
      <ToggleButton className="language-toggle" selected={i18n.language == "ms"} key={2} value="ms">
        BM
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LanguageToggleButton;
