import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import ReactPixel from "react-facebook-pixel";
import {
  IS_LOADING,
  SET_GENERAL_DATA,
  ERROR_MESSAGE,
  OTP_ERROR_ENABLE,
  SET_FORM_DATA,
  NEXT_STEP,
  IS_ERROR

} from "../types";
import api from "../../utils/api";
import { getLanguageMapping, getStandardizedErrorMsgs } from "../../utils";
import * as ubd from "../../utils/userBehaviouralData";

export const otpActions = (props) => {
  const { gaCategory } = props.state.generalStates;

  const showErrorPage = (errorMessage = null) => {
    props.dispatch({ type: IS_LOADING, data: false });
    if (errorMessage) {
      props.dispatch({ type: ERROR_MESSAGE, data: errorMessage });
    }
    props.dispatch({ type: IS_ERROR });
  };

  let startDate = null;
  let endDate = null;
  let referralUrl = null;
  let userBehavioural = [];
  let userBehaviourReference = [];
  let geolocation = null;

  function submitUtmInfo(sessionKey) {
    props.dispatch({ type: ERROR_MESSAGE, data: null });
    const payload = {
      "utmSource": props.state.generalStates.utmSource,
      "utmMedium": props.state.generalStates.utmMedium,
      "sessionKey": sessionKey,
    };
    return api
      .funnelService
      .post("/campaign/submitUtmInfo", payload)
      .then((res) => { })
      .catch((error) => { });
  }

  async function saveDeviceInformation(sessionKey) {
    const browserInformation = await ubd.getBrowserInformation();
    let networkInformation;
    if (props.state.generalStates.networkInformation != null) {
      networkInformation = props.state.generalStates.networkInformation;
    } else {
      networkInformation = await ubd.getNetworkInformation(browserInformation.userAgent);
      props.dispatch({ type: SET_GENERAL_DATA, data: { networkInformation } });
    }
    const socialMediaLogins = await ubd.getSocialMediaLoginInformation();

    const userBehaviours = (props.state.generalStates.userBehavioural == null || props.state.generalStates.userBehavioural.length == 0 ? userBehavioural
      : props.state.generalStates.userBehavioural);
    userBehaviours.forEach((stepBehaviour) => {
      delete stepBehaviour.typingSpeedKey;
      delete stepBehaviour.valueChangeKey;
    });

    const payload = {
      "journey": {
        "name": "ONBOARDING",
        "subName": "ENCORE",
        "productId": props.state.generalStates.productId,
        "languageCode": getLanguageMapping(),
        "sessionStartDate": (props.state.generalStates.sessionStartDate == null ? startDate : props.state.generalStates.sessionStartDate),
        "sessionEndDate": (props.state.generalStates.sessionEndDate == null ? endDate : props.state.generalStates.sessionEndDate),
        "referralUrl": (props.state.generalStates.referralUrl == null ? referralUrl : props.state.generalStates.referralUrl),
        "userBehaviours": userBehaviours
      },
      "transaction": {
        "type": "SESSION_KEY",
        "id": sessionKey
      },
      "browser": {
        "name": browserInformation.browser.name,
        "version": browserInformation.browser.version,
        "engineName": browserInformation.engine.name,
        "engineVersion": browserInformation.engine.version ? browserInformation.engine.version : null,
        "platformType": browserInformation.platform.type,
        "platformVendor": browserInformation.platform.vendor ? browserInformation.platform.vendor : null,
        "userAgent": browserInformation.userAgent,
        "languageCode": window.navigator.userLanguage || window.navigator.language,
        "cookiesEnabled": ubd.getCookieEnabled(),
      },
      "os": {
        "name": browserInformation.os.name,
        "version": browserInformation.os.version,
        "versionName": browserInformation.os.versionName,
        "timeZone": ubd.getTimeZone()
      },
      "screen": {
        "width": window.screen.width,
        "height": window.screen.height,
        "unit": "PX",
        "depth": window.screen.colorDepth
      },
    };

    if (geolocation) {
      payload.geolocation = geolocation;
    }

    if (networkInformation !== undefined) {
      payload.network = networkInformation;
    }

    if (socialMediaLogins && socialMediaLogins.length > 0) {
      payload.socialMediaLogins = socialMediaLogins;
    }

    console.log(payload);

    return api
      .aspirasiumService
      .post("/analytics/data/device", payload)
      .then((res) => { })
      .catch((error) => {
        if (error?.data?.error?.code == 1002) {
          updateDeviceInformation(payload);
        } else if (error?.data?.error?.code == 1003) {
          saveDeviceInformation(sessionKey);
        }
      });
  }

  async function updateDeviceInformation(payload) {
    return api
      .aspirasiumService
      .put("/analytics/data/device", payload)
      .then((res) => { })
      .catch((error) => {

      });
  }

  return {
    resumeJourneySendOtp: (params) => {
      props.dispatch({ type: IS_LOADING, data: true });
      props.dispatch({
        type: SET_FORM_DATA,
        data: {
          otp: null,
        }
      });
      const payload = {
        sessionKey: props.state.generalStates.sessionKey,
        phoneNumber: params?.cellPhone ? `60${params.cellPhone}` : `60${props.state.formStates.cellPhone}`,
      };

      return api
        .funnelService
        .post("/prejourney/sendOTP", payload)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          saveDeviceInformation(res.data.sessionKey);
          submitUtmInfo(res.data.sessionKey);
        })
        .catch((error) => {
          showErrorPage();
        });
    },
    sendOtp: (params, submitUserInfo, getFbPixelId) => {
      props.dispatch({ type: IS_LOADING, data: true });
      props.dispatch({
        type: SET_FORM_DATA,
        data: {
          otp: null,
        }
      });
      const payload = {
        sessionKey: props.state.generalStates.step == 1 ? null : props.state.generalStates.sessionKey,
        phoneNumber: params && params.cellPhone.length > 0 ? `60${params.cellPhone}` : `60${props.state.formStates.cellPhone}`,
      };

      return api
        .funnelService
        .post("/prejourney/sendOTP", payload)
        .then((res) => {
          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              sessionKey: res.data.sessionKey,
            }
          });

          if (!props.state.generalStates.sessionKey) {
            // if new journey (missing sessionKey), use new key
            submitUserInfo(res.data.sessionKey, params);
          } else if (props.state.generalStates.sessionKey != res.data.sessionKey) {
            // if somehow BE return a new key, force use new key
            // not expected to reach here
            //    either BE not reusing the same sessionKey as provided by FE
            //    or something weird going on in FE
            submitUserInfo(res.data.sessionKey, params);
          } else {
            // if BE return same key as FE, means step!=1, possible resendOTP
            props.dispatch({ type: IS_LOADING, data: false });
          }
          saveDeviceInformation(res.data.sessionKey);
          submitUtmInfo(res.data.sessionKey);
        })
        .catch((error) => {
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    },
    verifyOtp: (params, getFbPixelId, setKycLoading) => {
      props.dispatch({ type: IS_LOADING, data: true });
      const payload = {
        sessionKey: props.state.generalStates.sessionKey,
        phoneNumber: `60${props.state.formStates.cellPhone}`,
        otp: params.otp
      };
      ReactPixel.trackSingle(getFbPixelId(), "InitiateCheckout", { content_name: "Verify OTP" });
      return api
        .funnelService
        .post("/prejourney/verifyOTP", payload)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          ReactGA.event({
            category: gaCategory,
            action: "Verify OTP"
          });
          mixpanel.track(gaCategory, { "action": "Verify OTP" });
          clevertap.event.push("act_cp_verify_otp", {
            "Status": "Success",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });

          props.dispatch({ type: SET_FORM_DATA, data: { otpError: false } });
          props.dispatch({ type: ERROR_MESSAGE, data: null });
          props.dispatch({ type: IS_LOADING, data: false });

          if (props.state.generalStates.isResumeJourney) {
            // If resumeJourney, skip to KYC result page
            setKycLoading();
          } else {
            // Else, go to step 3
            props.dispatch({ type: NEXT_STEP });
          }
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: ERROR_MESSAGE, data: null });

          const { msgForAnalytics, msgForEndUser } = getStandardizedErrorMsgs(error.data);

          clevertap.event.push("act_cp_verify_otp", {
            "Status": "Fail",
            "Error": msgForAnalytics,
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });

          if (error.data.error?.code === "OTP-0007") {
            props.dispatch({ type: SET_GENERAL_DATA, data: { otpError: true } });
            props.dispatch({ type: ERROR_MESSAGE, data: msgForEndUser });
            return;
          }

          // OTP-0003, OTP-0006
          showErrorPage(msgForEndUser);
        });
    },
    handleJourneyInfo: (params) => {
      if (!props.state.generalStates.sessionStartDate) {
        props.dispatch({ type: SET_GENERAL_DATA, data: { sessionStartDate: params.startDate } });
      }
      if (!props.state.generalStates.referralUrl) {
        props.dispatch({ type: SET_GENERAL_DATA, data: { referralUrl: params.referralUrl } });
      }
      startDate = params.startDate ? params.startDate : null;
      referralUrl = params.referralUrl ? params.referralUrl : null;
    },
    handleUserBehaviourData: (params) => {
      userBehavioural = props.state.generalStates.userBehavioural;
      userBehaviourReference = props.state.generalStates.userBehaviourReference;
      const steps = [];
      const visits = [];
      const duration = ubd.calculateDuration(params.endDate, params.startDate);
      const mouseMovementSpeed = Math.round((params.totalMouseSpeed / duration._data.seconds), 2);
      visits.push({
        "visitCount": 1,
        "startDate": params.startDate,
        "endDate": params.endDate,
        "duration": duration._milliseconds
      });
      const requestPayload = {
        "stepId": params.step,
        "stepStartDate": params.startDate,
        "stepEndDate": params.endDate,
        "stepTotalDuration": duration._milliseconds,
        "visits": visits,
        "typingDuration": params.typingDuration,
        "typingSpeedKey": params.typingSpeedKey,
        "valueChangeKey": params.valueChangeKey,
        "typingSpeed": params.typingSpeed,
        "valueChange": params.valueChange,
        "mouseMovement": {
          "mouseMovementSpeed": {
            "averageMovementSpeed": mouseMovementSpeed,
            "unit": "px/s"
          }
        },
        "clipboardCopy": params.clipboardCopy,
        "clipboardCut": params.clipboardCut,
        "clipboardPaste": params.clipboardPaste
      };

      if (userBehavioural && userBehavioural.length) {
        userBehavioural.forEach((elm) => {
          steps.push(elm.stepId);
          if (elm.stepId == params.step) {
            elm.stepTotalDuration += duration._milliseconds;
            elm.typingDuration += params.typingDuration;
            elm.typingSpeed = params.typingSpeed;
            elm.valueChange = params.valueChange;
            elm.mouseMovement.mouseMovementSpeed.averageMovementSpeed = mouseMovementSpeed;
            elm.clipboardCopy = params.clipboardCopy;
            elm.clipboardCut = params.clipboardCut;
            elm.clipboardPaste = params.clipboardPaste;
            elm.visits.push({
              "visitCount": elm.visits.length + 1,
              "startDate": params.startDate,
              "endDate": params.endDate,
              "duration": duration._milliseconds
            });
          }
        });
        if (!steps.includes(params.step)) {
          userBehavioural.push(requestPayload);
        }
      } else {
        userBehavioural.push(requestPayload);
      }
      props.dispatch({ type: SET_GENERAL_DATA, data: { userBehavioural } });

      const referencePayload = {
        "stepId": params.step,
        "typingSpeedKey": params.typingSpeedKey,
        "valueChangeKey": params.valueChangeKey,
        "clipboardCopy": params.clipboardCopy,
        "clipboardCut": params.clipboardCut,
        "clipboardPaste": params.clipboardPaste
      };

      if (userBehaviourReference && userBehaviourReference.length) {
        userBehaviourReference.forEach((elm) => {
          if (elm.stepId === params.step) {
            elm.typingSpeedKey = params.typingSpeedKey;
            elm.valueChangeKey = params.valueChangeKey;
            elm.clipboardCopy = params.clipboardCopy;
            elm.clipboardCut = params.clipboardCut;
            elm.clipboardPaste = params.clipboardPaste;
          }
        });
        if (!steps.includes(params.step)) {
          userBehaviourReference.push(referencePayload);
        }
      } else {
        userBehaviourReference.push(referencePayload);
      }
      props.dispatch({ type: SET_GENERAL_DATA, data: { userBehaviourReference } });

      if (params.step === ubd.STEP_CONGRATULATION) {
        endDate = params.endDate;
        if (props.state.generalStates.sessionEndDate == null) {
          props.dispatch({ type: SET_GENERAL_DATA, data: { sessionEndDate: params.endDate } });
        }
      }

      if (props.state.generalStates.sessionKey) {
        saveDeviceInformation(props.state.generalStates.sessionKey);
      }
    },
    storeLocation: (params) => {
      const payload = {
        "accuracy": params.coords.accuracy,
        "altitude": params.coords.altitude,
        "altitudeAccuracy": params.coords.altitudeAccuracy,
        "heading": params.coords.heading,
        "latitude": params.coords.latitude,
        "longitude": params.coords.longitude,
        "speed": params.coords.speed
      };
      geolocation = payload;
      saveDeviceInformation(params.sessionKey);
    },
    resetUserBehaviour: () => {
      userBehaviourReference = props.state.generalStates.userBehaviourReference;
      userBehaviourReference.forEach((stepBehaviour) => {
        delete stepBehaviour.stepStartDate;
        delete stepBehaviour.stepEndDate;
        delete stepBehaviour.stepTotalDuration;
        delete stepBehaviour.visits;
        delete stepBehaviour.typingSpeed;
        delete stepBehaviour.valueChange;
        delete stepBehaviour.mouseMovement;
        delete stepBehaviour.clipboardCopy;
        delete stepBehaviour.clipboardCut;
        delete stepBehaviour.clipboardPaste;
      });
      props.dispatch({ type: SET_GENERAL_DATA, data: { userBehavioural: [] } });
      props.dispatch({ type: SET_GENERAL_DATA, data: { sessionKey: null } });
    },
    saveJourneyEndDate: (journeyEndDate) => {
      // used to update User Behavioural Data - Journey End Date when user reaches Error Page
      if (props.state.generalStates.sessionKey) {
        endDate = journeyEndDate;
        props.dispatch({ type: SET_GENERAL_DATA, data: { sessionEndDate: journeyEndDate } });
        saveDeviceInformation(props.state.generalStates.sessionKey);
      }
    }

  };
};
