import React, { useContext, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Chip from "@material-ui/core/Chip";
import mixpanel from "mixpanel-browser";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Input } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StoreContext } from "../../store/context";
import CustomSelect from "../CustomSelect";
import lock from "../../assets/images/encore/lock.svg";
import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
import { mask, getLanguage } from "../../utils";
import * as ubd from "../../utils/userBehaviouralData";
import checkedBox from "../../assets/images/encore/checkbox-active.svg";
import uncheckedBox from "../../assets/images/encore/checkbox-inactive.svg";

import "./BasicDetail.scss";

const mouseSpeed = [];

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "30px",
    "&:first-child": {
      marginTop: "0px",
    }
  },
  stepLabel: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "bold !important",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconImg: {
    height: "100%",
    width: "100%",
  },
  imgPreview: {
    objectFit: "contain",
    width: "100%",
  },
}));

const StyleChip = withStyles({
  root: {
    backgroundColor: "#F2CA00"
  }
})(Chip);

const BasicDetail = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, actions } = useContext(StoreContext);
  const { submitUserInfo } = actions.credentialActions;
  const { setGeneralData, getFbPixelId, checkIfProductIsEasyEat } = actions.generalActions;
  const { verifyPhoneNoAndEmail, checkCurseWord } = actions.credentialActions;
  const { setFormData } = actions.formActions;
  const { verifyReferralKey } = actions.verifyPageActions;
  const { sendOtp, handleJourneyInfo, handleUserBehaviourData, resetUserBehaviour } = actions.otpActions;
  const { overallStep, step, gaCategory, userBehaviourReference } = state.generalStates;
  const { fullName, email, agreementCheck, cellPhone, referralCode, referralKey, question, answer } = state.formStates;
  const formikRef = useRef(null);
  const [haveCurseWord, setHaveCurseWord] = useState(null);

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [referralUrl, setReferralUrl] = useState(null);
  const [onTypingStart, setOnTypingStart] = useState(null);
  const [onTypingEnd, setOnTypingEnd] = useState(null);
  const [totalTypingDuration, setTotalTypingDuration] = useState(null);
  const [typingSpeed, setTypingSpeed] = useState([]);
  const [valueChange, setValueChange] = useState([]);
  const [selectedText, setSelectedText] = useState(null);
  const [copiedText, setCopiedText] = useState([]);
  const [cutText, setCutText] = useState([]);
  const [pastedText, setPastedText] = useState([]);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const isOthersChecked = () => {
    if (!question) {
      return false;
    }
    return question.toLowerCase() == "others".toLowerCase();
  };

  const personalSchemaObject = {
    fullName: yup.string().required(t("encore.basicDetail.field.fullNameField.requiredError")).matches(/^[a-zA-Z/.@\\ '-]*$/, t("encore.basicDetail.field.fullNameField.regexError")),
    email: yup.string().email(t("encore.basicDetail.field.emailField.regexError")).required(t("encore.basicDetail.field.emailField.requiredError")),
    cellPhone: yup
      .string()
      .matches(/^[0-9]{9,11}$/, t("encore.basicDetail.field.phoneNumberField.regexError"))
      .required(t("encore.basicDetail.field.phoneNumberField.requiredError"))
      .test("excludeZero", t("encore.basicDetail.field.phoneNumberField.excludeZeroError"), (value) => {
        if (value) {
          return !(value[0] === "0");
        }
      }),
    agreementCheck: yup.bool().oneOf([true], t("encore.basicDetail.field.privateNoticeCheckField.checkRequired")),
    question: !checkIfProductIsEasyEat() ? yup.string().required(t("encore.basicDetail.field.questionField.requiredError")) : "",
    answer: isOthersChecked()
      ? yup.string().required(t("encore.basicDetail.field.questionField.answer.requiredError")).nullable()
        .test("haveCurseWord", haveCurseWord?.code == "GE-0001" && t("encore.basicDetail.field.questionField.answer.curseWord"), (value) => {
          return !(haveCurseWord?.code == "GE-0001");
        })
      : "",
  };

  // const[personalSchemaState, setPersonalSchemaState] = useState(personalSchemaObject);

  const personalSchema = yup.object().shape(personalSchemaObject);

  const dynamicQuestionnaires = [
    {
      "questionId": 1,
      "type": "SELECT",
      "required": true,
      "en": {
        "question": "Q1 English Language",
        "placeholder": "English Language",
        "errorMessage": "English Language Required",
        "options": [
          {
            "label": "en",
            "value": "en"
          },
          {
            "label": "ms",
            "value": "ms"
          },
        ]
      },
      "ms": {
        "question": "Q1 Malay Language",
        "placeholder": "Malay Language",
        "errorMessage": "Malay Language Required",
        "options": [
          {
            "label": "en",
            "value": "en"
          },
          {
            "label": "ms",
            "value": "ms"
          },
        ]
      }
    }
  ];

  const getDynamicQuestionnaires = (handleChange) => {
    const layout = [];
    dynamicQuestionnaires.map((questionnaire, index) => {
      const languageValue = questionnaire[getLanguage()];
      if (questionnaire.required) {
        personalSchemaObject[questionnaire.questionId] = yup.string().required(languageValue.errorMessage);
      }
      layout.push(
        <div className="field-container" key={questionnaire.questionId}>
          <div className="field-label">{languageValue.question}</div>
          {renderFieldAccordingToType(questionnaire, handleChange)}
        </div>
      );
    });

    return layout;
  };

  const renderFieldAccordingToType = (questionnaire, handleChange) => {
    const languageValue = questionnaire[getLanguage()];
    if (questionnaire.type == "SELECT") {
      return (
        <Field name={questionnaire.questionId}>
          {({ field, form: { touched, errors, setFieldValue } }) => (
            <>
              <CustomSelect
                {...field}
                id={questionnaire.questionId}
                className={`input-field select ${question ? "selected" : ""}`}
                labelId={`${questionnaire.questionId}Label`}
                value={question || ""}
                displayEmpty
                onChange={async (e) => {
                  handleChange(e);
                  await setFormData({
                    question: e.target.value
                  });
                  setFieldValue("question", e.target.value);
                }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  {languageValue.placeholder}
                </MenuItem>
                {getQuestionnaire()}
              </CustomSelect>
              {touched[field.name] && errors[field.name] && (
                <div className="error-text">
                  {errors[field.name]}
                </div>
              )}
            </>
          )}
        </Field>
      );
    }
    return "";
  };

  const questionnaires = [
    {
      "label": t("encore.basicDetail.field.questionField.option.0.label"),
      "value": t("encore.basicDetail.field.questionField.option.0.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.1.label"),
      "value": t("encore.basicDetail.field.questionField.option.1.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.2.label"),
      "value": t("encore.basicDetail.field.questionField.option.2.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.3.label"),
      "value": t("encore.basicDetail.field.questionField.option.3.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.4.label"),
      "value": t("encore.basicDetail.field.questionField.option.4.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.5.label"),
      "value": t("encore.basicDetail.field.questionField.option.5.value")
    },
    {
      "label": t("encore.basicDetail.field.questionField.option.6.label"),
      "value": t("encore.basicDetail.field.questionField.option.6.value")
    }
  ];

  function getQuestionnaire() {
    const layout = [];
    questionnaires.map((item, key) => layout.push(
      <MenuItem key={key} value={item.value}>
        {item.label}
      </MenuItem>
    ));
    return layout;
  }

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const duration = ubd.calculateDuration(onTypingEnd, onTypingStart);
    setTotalTypingDuration(duration._milliseconds + (totalTypingDuration != null ? totalTypingDuration : 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypingEnd]);

  useEffect(() => {
    const fieldNames = ["fullName", "email", "cellPhone"];
    const typingSpeedArray = [];
    const valueChangeArray = [];
    fieldNames.forEach((name) => {
      if (typingSpeed[name] != null) {
        typingSpeedArray.push(typingSpeed[name]);
      }
      if (valueChange[name] != null) {
        valueChangeArray.push(valueChange[name]);
      }
    });
    const params = {
      step: ubd.STEP_BASIC_DETAIL,
      startDate: journeyStart,
      endDate: singleStepEnd,
      typingDuration: totalTypingDuration,
      typingSpeed: typingSpeedArray,
      typingSpeedKey: typingSpeed,
      valueChange: valueChangeArray,
      valueChangeKey: valueChange,
      totalMouseSpeed,
      clipboardCopy: copiedText,
      clipboardCut: cutText,
      clipboardPaste: pastedText
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  const checkBehaviourExist = () => {
    if (userBehaviourReference != null) {
      for (let i = 0; i < userBehaviourReference.length; i++) {
        if (userBehaviourReference[i].stepId == ubd.STEP_BASIC_DETAIL) {
          if (userBehaviourReference[i].typingSpeedKey != null) {
            setTypingSpeed(userBehaviourReference[i].typingSpeedKey);
          }
          if (userBehaviourReference[i].valueChangeKey != null) {
            setValueChange(userBehaviourReference[i].valueChangeKey);
          }
        }
      }
      resetUserBehaviour();
    }
  };

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    ubd.getReferralUrl(setReferralUrl);
    checkBehaviourExist();
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="encore__basic_detail__guide">
      <IconButton
        color="primary"
        onClick={() => {
          setGeneralData({ step: 0 });
        }}
        className="encore__page__back_holder"
      >
        <img src={circleLeftArrow} alt="Back" />
      </IconButton>
      <div className="encore__page__upper">
        <div className="encore__identifier">
          <div>
            {`${t("common.step_plural")} ${step} ${t("common.of")} ${overallStep}`}
          </div>
          <div>
            <img src={lock} alt="Secure" />
          </div>
          <div>
            {referralCode ? (
              <>
                <div style={{ marginLeft: "2%" }}>
                  <StyleChip size="small" label={`REFERRAL ID : ${mask(referralCode)}`} />
                </div>
              </>
            ) : <></>}
          </div>
        </div>
        <div className="encore__title">{t("encore.basicDetail.title")}</div>
        <div className="encore__desc">{t("encore.basicDetail.description")}</div>
      </div>

      <div className="formik-form">
        <Formik
          innerRef={formikRef}
          initialValues={{
            // DEV:
            // fullName: `${fullName || "LOO ZHU HANG"}`,
            // email: `${email || "jasper.loo@myboost.co"}`,
            // agreementCheck,
            // cellPhone: `${cellPhone || "10912405011"}`,
            // question: `${question || ""}`,
            // answer: `${answer || ""}`,
            fullName: `${fullName || ""}`,
            email: `${email || ""}`,
            agreementCheck,
            cellPhone: `${cellPhone || ""}`,
            question: `${question || ""}`,
            answer: `${answer || ""}`,
          }}
          validationSchema={personalSchema}
          onSubmit={async (values) => {
            if (!checkIfProductIsEasyEat()) {
              let curseWordAwaitCheck = null;
              setHaveCurseWord(curseWordAwaitCheck);
              const questionerPayload = {
                questionId: 1,
                question: "How did you find out about us?"
              };
              if (!isOthersChecked()) {
                values.answer = null;
                questionerPayload.answer = values.question;
              } else {
                questionerPayload.answer = values.answer;
                curseWordAwaitCheck = await checkCurseWord(values.answer);
              }
              values.questionnaires = [questionerPayload];
              if (curseWordAwaitCheck) {
                setHaveCurseWord(curseWordAwaitCheck);
                if (formikRef.current) {
                  console.log(formikRef.current);
                  formikRef.current.validateField("answer");
                }
                return;
              }
            } else {
              values.questionnaires = [];
            }

            setFormData(values);

            // hotjar attributation
            if (values.email) {
              console.log(`window.hj identify ${values.email}`);
              window.hj("identify", values.email, {
                "Remark": "Repo = app-stardust-encore"
              });
            }

            if (referralKey) {
              verifyReferralKey(referralKey, values.email, values.cellPhone, () => {
                verifyPhoneNoAndEmail(values, sendOtp, submitUserInfo, getFbPixelId);
              });
            } else {
              verifyPhoneNoAndEmail(values, sendOtp, submitUserInfo, getFbPixelId);
            }

            const info = {
              startDate: journeyStart,
              referralUrl,
            };
            handleJourneyInfo(info);
            handleEndJourney();
            // setGeneralData({step: previousStep});
          }}
        >
          {({ isSubmitting, values, handleChange, handleBlur }) => (
            <Form autoComplete="off">
              <FormControl variant="outlined" className={classes.formControl}>
                <p className="input-label">{t("encore.basicDetail.field.fullNameField.label")}</p>
                <Field name="fullName">
                  {({ field, form: { touched, errors } }) => (
                    <>
                      <Input
                        {...field}
                        id="fullName"
                        name="fullName"
                        className="input-field"
                        placeholder={t("encore.basicDetail.field.fullNameField.placeholder")}
                        error={Boolean(touched[field.name] && errors[field.name])}
                        onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                        onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                        onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                        onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                        onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                        onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                        onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error-text">
                          {errors[field.name]}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <p className="input-label">{t("encore.basicDetail.field.emailField.label")}</p>
                <Field name="email">
                  {({ field, form: { touched, errors } }) => (
                    <>
                      <Input
                        {...field}
                        id="email"
                        name="email"
                        className="input-field"
                        type="email"
                        placeholder={t("encore.basicDetail.field.emailField.placeholder")}
                        error={Boolean(touched[field.name] && errors[field.name])}
                        onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                        onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                        onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                        onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                        onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                        onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                        onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error-text">
                          {errors[field.name]}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <p className="input-label">{t("encore.basicDetail.field.phoneNumberField.label")}</p>
                <Field name="cellPhone">
                  {({ field, form: { touched, errors } }) => (
                    <>
                      <div className="form-flex">
                        {/* <div className="mobile-no-index">
                          <span style={{ fontWeight: "bold" }}>+60</span>
                        </div> */}
                        <div className="item">
                          <span className="mobile-no-index">+60</span>
                          <Input
                            {...field}
                            id="cellPhone"
                            name="cellPhone"
                            className="input-field"
                            placeholder={t("encore.basicDetail.field.phoneNumberField.placeholder")}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                            onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                            onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                            onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                            onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                            onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                            onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                          />
                        </div>
                      </div>
                      {touched[field.name] && errors[field.name] && (
                        <div className="error-text" style={{ marginLeft: "48px" }}>
                          {errors[field.name]}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>

              {!checkIfProductIsEasyEat() && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.basicDetail.field.questionField.label")}</p>
                  <Field name="question">
                    {({ field, form: { touched, errors, setFieldValue } }) => (
                      <>
                        <CustomSelect
                          {...field}
                          labelId="questionLabel"
                          id="question"
                          name="question"
                          className={`input-field select ${question ? "selected" : ""}`}
                          value={question || ""}
                          displayEmpty
                          onChange={async (e) => {
                            handleChange(e);
                            await setFormData({
                              question: e.target.value
                            });
                            setFieldValue("question", e.target.value);
                          }}
                          variant="outlined"
                        >
                          <MenuItem disabled value="">
                            {t("encore.basicDetail.field.questionField.placeholder")}
                          </MenuItem>
                          {getQuestionnaire()}
                        </CustomSelect>
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
              )}

              {isOthersChecked()
                && (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Field name="answer">
                      {({ field, form: { touched, errors } }) => (
                        <>
                          <Input
                            {...field}
                            id="answer"
                            name="answer"
                            className="input-field"
                            type="text"
                            placeholder={t("encore.basicDetail.field.questionField.answer.placeholder")}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            onKeyDown={(e) => {
                              setHaveCurseWord(null);
                            }}
                          />
                          {touched[field.name] && errors[field.name] && (
                            <div className="error-text">
                              {errors[field.name]}
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </FormControl>
                )}
              {/* {getDynamicQuestionnaires(handleChange)} */}
              <br />
              <FormControl variant="outlined" className={classes.formControl}>
                <Field name="agreementCheck">
                  {({ field, form: { touched, errors, setFieldValue } }) => (
                    <>
                      <div className="checkbox-container">
                        <FormControlLabel
                          className={agreementCheck ? "checkbox-checked" : "checkbox"}
                          control={(
                            <img
                              className="agreement-checkbox"
                              onClick={(e) => {
                                setFieldValue("agreementCheck", !agreementCheck);
                                setFormData({ agreementCheck: !agreementCheck });
                                handleChange(e);
                              }}
                              alt="agreementWakalah"
                              height="24"
                              src={agreementCheck ? checkedBox : uncheckedBox}
                            />
                          )
                          }
                          label={(
                            <span className="checkbox-label">
                              {t("encore.basicDetail.field.privateNoticeCheckField.label")}
                              {" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://funnel-ada-matta.s3-ap-southeast-1.amazonaws.com/Funnel-ADA-Privacy-Notice.pdf"
                              >
                                {t("encore.basicDetail.field.privateNoticeCheckField.linkClickLabel")}
                              </a>
                              .
                            </span>
                          )}
                        />
                      </div>
                    </>
                  )}
                </Field>
              </FormControl>
              <div className="encore__next__button">
                <Button
                  id="btn_get_started"
                  variant="contained"
                  color="primary"
                  className="button"
                  type="submit"
                  disabled={!agreementCheck}
                  // disabled={isSubmitting}
                >
                  {t("encore.basicDetail.button")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BasicDetail;
