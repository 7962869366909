import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Input } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { StoreContext } from "../../store/context";

import "./KYCReviewNameNric.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "30px",
    "&:first-child": {
      marginTop: "0px",
    }
  },
  stepLabel: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "bold !important",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconImg: {
    height: "100%",
    width: "100%",
  },
  imgPreview: {
    objectFit: "contain",
    width: "100%",
  },
}));

const KYCReviewNameNric = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, actions } = useContext(StoreContext);
  const { setConfirmationDialogData } = actions.generalActions;
  const { setFormData } = actions.formActions;
  const { resolveFailedVerification } = actions.credentialActions;
  const { fullName, idNumber } = state.formStates;
  const formikRef = useRef(null);

  const kycUpdateSchemaObject = {
    fullName: yup.string().required(t("encore.basicDetail.field.fullNameField.requiredError")).matches(/^[a-zA-Z/.@\\ '-]*$/, t("encore.basicDetail.field.fullNameField.regexError")),
    idNumber: yup
      .string()
      .matches(
        /^\d{6}-\d{2}-\d{4}$/,
        t("encore.businessDetail.field.icNumberField.regexError")
      )
      .test(
        "checkAge",
        t("encore.businessDetail.field.icNumberField.ageError"),
        (value) => {
          if (value) {
            if (value.length) {
              if (value.length > 5) {
                const nric = value;
                const dob = nric.split("-")[0].substring(0, 6);
                const momentDate = moment(dob, "YYMMDD");
                if (momentDate.isValid()) {
                  if (momentDate.year() > moment().year()) {
                    momentDate.subtract(100, "year");
                  }
                  const date_convert_age = moment().diff(momentDate, "years");
                  if (date_convert_age > 65 || date_convert_age < 18) {
                    return false;
                  }
                  return true;
                }
                return false;
              }
            }
          }
          return true;
        }
      )
      .required(t("encore.businessDetail.field.icNumberField.requiredError")),
  };

  const kycUpdateSchema = yup.object().shape(kycUpdateSchemaObject);

  return (
    <div className="encore__kycReviewNameNric">
      <div className="encore__page__upper">
        <div
          className="encore__title"
          dangerouslySetInnerHTML={{
            __html: t("encore.kycReviewNameNric.title"),
          }}
        />
        <div
          className="encore__desc"
          dangerouslySetInnerHTML={{
            __html: t("encore.kycReviewNameNric.description"),
          }}
        />
      </div>

      <div className="formik-form">
        <Formik
          innerRef={formikRef}
          initialValues={{
            fullName: `${fullName || ""}`,
            idNumber: `${idNumber || ""}`,
          }}
          validationSchema={kycUpdateSchema}
          onSubmit={(values) => {
            setConfirmationDialogData({
              show: true,
              dialogTitle: "encore.kycReviewNameNric.confirmationTitle",
              dialogContent: "encore.kycReviewNameNric.confirmationContent",
              callback: (result) => {
                if (result) {
                  setFormData(values);
                  resolveFailedVerification(values);
                }
              }
            });
          }}
        >
          {({ isSubmitting, values, handleChange, handleBlur }) => (
            <Form autoComplete="off">
              <FormControl variant="outlined" className={classes.formControl}>
                <p className="input-label">{t("encore.basicDetail.field.fullNameField.label")}</p>
                <Field name="fullName">
                  {({ field, form: { touched, errors } }) => (
                    <>
                      <Input
                        {...field}
                        name="fullName"
                        className="input-field"
                        placeholder={t("encore.basicDetail.field.fullNameField.placeholder")}
                        error={Boolean(touched[field.name] && errors[field.name])}
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error-text">
                          {errors[field.name]}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <p className="input-label">{t("encore.businessDetail.field.icNumberField.label")}</p>
                <Field name="idNumber">
                  {({ field, form: { touched, errors, setFieldValue } }) => (
                    <>
                      <Input
                        {...field}
                        name="idNumber"
                        className="input-field"
                        placeholder={t("encore.businessDetail.field.icNumberField.placeholder")}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value.trim());
                        }}
                        error={Boolean(touched[field.name] && errors[field.name])}
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error-text">
                          {errors[field.name]}
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
              <div className="encore__next__button">
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  type="submit"
                >
                  {t("common.confirm")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default KYCReviewNameNric;
