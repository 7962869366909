/**
 * https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { useEffect } from "react";
import ReactGA from "react-ga";

export default function WithTracker(WrappedComponent, options = {}, extraProps = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = (props) => {
    const { location } = props;
    useEffect(() => trackPage(location.pathname), [
      location.pathname
    ]);
    return <WrappedComponent {...props} {...extraProps} />;
  };
  return HOC;
}
