import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import MomentTimezone from "moment-timezone";
import { StoreContext } from "../../store/context";
import NotFound from "../NotFound";
import TemplateFullHeight from "../../components/TemplateFullHeight";
import redBgMobile from "../../assets/images/encore/red-bg-mobile.svg";
import TwelveStatementGuide from "../../components/TwelveStatementGuide";
import Compatibility from "../../components/Compatibility";
import BasicDetail from "../../components/BasicDetail";
import OTP from "../../components/OTP";
import BusinessDetail from "../../components/BusinessDetail";
import KYCBefore from "../../components/KYCBefore";
import KYCLoading from "../../components/KYCLoading";
import KYCResult from "../../components/KYCResult";
import KYCReviewNameNric from "../../components/KYCReviewNameNric";
import GeneralModal from "../../components/GeneralModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import IcCaptureTutorial from "../../components/IcCaptureTutorial";
import SelfieCaptureTutorial from "../../components/SelfieCaptureTutorial";
import Maintenance from "../../components/Maintenance";
import LeadGen from "../../components/LeadGen";
import LeadGenThankYouPage from "../../components/LeadGenThankYouPage";
import LeadGenSorryPage from "../../components/LeadGenSorryPage";
import EmailComp from "../../components/EmailComp";
import CongraluatePage from "../../components/CongraluatePage";
import ReportLoading from "../../components/ReportLoading";
import Welcome from "../../components/Welcome";
import Location from "../../components/Location";
import ACPMobiusLanding from "../../components/ACPMobiusLanding";
import KycStepEnum from "../../constants/KycStepEnum";
import CongratsResultTypeEnum from "../../constants/CongratsResultTypeEnum";
import { TIME_ZONE, DATE_FORMAT, navigateToHome, getLanguage } from "../../utils";

import "./Encore.scss";

const { REACT_APP_SUPPORT_EMAIL } = process.env;

const Encore = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  // const resultType = CongratsResultTypeEnum.FRESH_CUSTOMER; // DEV
  const {
    resultType,
    step,
    maintenanceStep,
    isError,
    errorMessage,
    errorMessageMulti,
    isMaintenance,
    icTutorialCapture,
    selfieTutorialCapture,
    kycStep,
    timerOngoing,
  } = state.generalStates;
  const { nextStep, checkIfJourneyShouldSkipKYC } = actions.generalActions;
  const { saveJourneyEndDate } = actions.otpActions;

  const [stepContent, setStepContent] = useState(<></>);

  const currentDate = () => MomentTimezone.tz(undefined, TIME_ZONE);
  const parseDateFormat = (date, format = DATE_FORMAT) => MomentTimezone.tz(date, TIME_ZONE).format(format);

  useEffect(() => {
    // skip KYC steps
    if (!isError && !isMaintenance && step == 4 && checkIfJourneyShouldSkipKYC()) {
      nextStep();
      return;
    }

    // UBD analytics
    if (isError) {
      saveJourneyEndDate(parseDateFormat(currentDate()));
    }

    // update step component render
    if (!timerOngoing) {
      if (isError) {
        setStepContent(financingErrorUi());
      } else {
        setStepContent(getStep());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isError, timerOngoing, isMaintenance, maintenanceStep, kycStep, resultType, errorMessage, errorMessageMulti, getLanguage()]);

  const financingErrorUi = () => {
    return (
      <div className="encore__financing__error__ui">
        <div className="header-container" style={{ backgroundImage: `url(${redBgMobile})` }}>
          <div className="title">{t("encore.errorPage.title")}</div>
          <div className="desc">
            {errorMessageMulti || errorMessage
              ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errorMessageMulti?.[getLanguage()] || errorMessage,
                  }}
                />
              )
              : t("common.systemError")}
          </div>
        </div>
        <div className="encore__next__button">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              navigateToHome();
            }}
          >
            {t("common.returnHome")}
          </Button>
        </div>
        <div className="encore__next__button">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            className="button"
            onClick={() => {
              window.open(`mailto:${REACT_APP_SUPPORT_EMAIL}`);
            }}
          >
            {t("common.contactSupport")}
          </Button>
        </div>
      </div>
    );
  };

  // const financingOnTheWay = () => {
  //   return (
  //     <div className="encore__financing__error__ui">
  //       <div className="header-container" style={{ backgroundImage: `url(${greenBgMobile})` }}>
  //         <div className="title">{t("encore.financingOntheWay.title")}</div>
  //         <div className="desc">
  //           {t("encore.financingOntheWay.subTitle")}
  //         </div>
  //       </div>
  //       <div className="encore__next__button">
  //         <Button
  //           fullWidth
  //           variant="contained"
  //           color="primary"
  //           className="button"
  //           onClick={() => {
  //             navigateToHome();
  //           }}
  //         >
  //           {t("common.returnHome")}
  //         </Button>
  //       </div>
  //       <div className="encore__next__button">
  //         <Button
  //           fullWidth
  //           variant="outlined"
  //           color="secondary"
  //           className="button"
  //           onClick={() => {
  //             window.open(`mailto:${REACT_APP_SUPPORT_EMAIL}`);
  //           }}
  //         >
  //           {t("common.contactSupport")}
  //         </Button>
  //       </div>
  //     </div>
  //   );
  // };

  const getResultStep = () => {
    switch (resultType) {
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA:
        return <CongraluatePage />;
      default:
        return NotFound;
    }
  };

  const getKycStep = () => {
    // return <KYC />;
    switch (kycStep) {
      case KycStepEnum.PRE_JUMIO:
        return <KYCBefore />;
      case KycStepEnum.LOADING:
        return <KYCLoading />;
      case KycStepEnum.RESULT:
        return <KYCResult />;
      case KycStepEnum.REVIEW_NAME_NRIC:
        return <KYCReviewNameNric />;
      default:
        return <NotFound />;
    }
  };

  const getStep = () => {
    if (isMaintenance) {
      switch (maintenanceStep) {
        case 100:
          return <LeadGen />;
        case 101:
          return <LeadGenThankYouPage />;
        case 102:
          return <LeadGenSorryPage />;
        case 103:
          return <Maintenance />;
        default:
          return <Maintenance />;
      }
    }

    switch (step) {
      case 0:
        return <Welcome history={history} />;

        // DEV:
        // return <Compatibility />;
        // return <TwelveStatementGuide />;
        // return <ACPMobiusLanding />;
        // return <LeadGen />;
        // return <LeadGenThankYouPage />;
        // return <LeadGenSorryPage />;
        // return <Maintenance />;

        // return <BasicDetail />;
        // return <OTP />;
        // return <BusinessDetail />;
        // return <KYC />;
        // return <KYCBefore />;
        // return <KYCLoading />;
        // return <KYCResult />;
        // return <KYCReviewNameNric />;
        // return <Location />;
        // return <ReportLoading />;
        // return financingErrorUi();
        // return financingOnTheWay();
        // return <CongraluatePage />;
        // return <EmailComp step={step} />;

      case -1:
        return <TwelveStatementGuide />;
      case -2:
        return <Compatibility />;
      case -3:
        return <EmailComp step={step} />;
      case -4:
        return <></>;
      case -5:
        return <ACPMobiusLanding />;
      case 1:
        return <BasicDetail />;
      case 2:
        return <OTP />;
      case 3:
        return <BusinessDetail />;
      case 4:
        return getKycStep();
      case 5:
        return <Location />;
      case 6:
        return <ReportLoading />;
      case 7:
        return getResultStep();
      case 10:
        return <EmailComp step={step} />;
      default:
        return NotFound;
    }
  };

  return (
    <div className={(icTutorialCapture || selfieTutorialCapture) ? "encore blur" : "encore"}>
      <GeneralModal />
      <ConfirmationModal />
      <IcCaptureTutorial />
      <SelfieCaptureTutorial />
      {/* <ConfirmContinue /> */}
      <TemplateFullHeight>
        {stepContent}
      </TemplateFullHeight>
    </div>
  );
};

export default withRouter(Encore);
