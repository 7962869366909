import {
  IS_LOADING,
  SET_CODE,
  NEXT_STEP,
  PREVIOUS_STEP,
  RESET_STEP,
  SET_STEP,
  SET_GENERAL_DATA,
  RESET_GENERAL_DATA,
  SET_PDF_DIALOG,
  IS_ERROR,
  ERROR_MESSAGE,
  IC_IMAGE_RETRIES,
  SELFIE_IMAGE_RETRIES,
  SUPPORT_EMAIL,
  MAINTENANCE_SET_STEP,
  MAINTENANCE_NEXT_STEP,
  SET_CONFIRMATION_DIALOG_DATA,
  SET_GENERAL_DIALOG_DATA,
} from "../types";
import KycStepEnum from "../../constants/KycStepEnum";
import CongratsResultTypeEnum from "../../constants/CongratsResultTypeEnum";
import boostCreditLogoRed from "../../assets/images/boost-credit.svg";

const {
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_SUPPORT_EMAIL,
} = process.env;

export const generalStates = {
  // Side Items
  isLoading: false,
  isError: false,
  isMaintenance: false,
  maintenanceStep: 103,

  step: -4,
  previousStep: 0,
  overallStep: 5,
  bankStatementRequired: false,
  bankStatementCountInMonths: 0,
  bsaRequirementStatus: "",

  browserName: null,
  browserLogoUrl: null,
  productLogoUrl: boostCreditLogoRed,
  productLogoHeight: 22,
  productMobileLogoHeight: 22,
  productPhoneNumber: REACT_APP_SUPPORT_PHONE,
  productEmail: REACT_APP_SUPPORT_EMAIL,

  // congraluate page
  resultType: CongratsResultTypeEnum.NONE,

  hideFinancingDetail: true,
  hideRemixContainer: true,
  hideMwcContainer: true,
  // specialCase: true,
  existingUser: false,

  trackingInitiated: false,

  webStatus: null,
  hasCheckRTC: false,
  gaCategory: "Capital Prejourney",
  // confirmContinue: false,
  errorMessage: null,
  errorMessageMulti: null,
  otpError: false,
  token: null,
  productId: null,
  utmSource: "",
  utmMedium: "",
  supportEmail: REACT_APP_SUPPORT_EMAIL,
  // referral: "0108255179",
  sessionKey: null,
  // Camera Related
  triesExceded: false,
  icImageRetries: 0,
  selfieImageRetries: 0,
  icImageTotalRetries: 3,
  selfieImageTotalRetries: 3,
  icCameraActive: true,
  selfieCameraActive: true,
  icCameraCaptured: false,
  selfieCameraCaptured: false,
  icTutorialCapture: false,
  selfieTutorialCapture: false,
  verifiedNricAndSelfieResult: false,
  // Congraluate Page Details
  reportResponse: {
    eligibilityAmount: 0,
    productDetail: {
      name: "",
      featureUrl: "",
      featureText: {
        "ms": "",
        "en": ""
      },
      mwcUrl: "",
      remixUrl: "",
      capXUrl: "",
    },
    creditScoringDetail: {
      monthlyProfitRate: 0.0,
      tenure: 0
    },
    downSell: false
  },
  reportLoadingDone: false,
  timerOngoing: false,
  // reportResponse: {
  //   eligibilityAmount: 100000,
  //   productDetail: {
  //     name: "Aspirasi Capital Lift Plus",
  //     featureUrl: "google.com",
  //     featureText: {
  //       "ms": "Malay",
  //       "en": "English"
  //     },
  //     mwcUrl: "https://uat.aspirasi.co/aspirasi-capital-v2/?product=8049&distributor=8049",
  //     remixUrl: "https://uat.aspirasi.co/aspirasi-capital-remix-v2/?distributorId=8049&productId=8049",
  //   },
  //   creditScoringDetail: {
  //     monthlyProfitRate: "1.4",
  //     tenure: 10
  //   },
  //   downSell: false
  // },
  // reportResponse: {
  //   eligibilityAmount: 500000,
  //   productDetail: {
  //     name: "Capital (EasyEat)",
  //     featureUrl: "https://aspirasi.co/pasar/capital-plus",
  //     featureText: {
  //       "ms": null,
  //       "en": null
  //     },
  //     mwcUrl: "https://uat.aspirasi.co/aspirasi-capital-v2?distributor=9152&product=9152&code=47136457b68c84232e560dd59e5c5a562eaa6720a92e55dff353891eb986ea124aa51e0122d5c0118c9eac7cb7652408&skipIntro=1&language=en",
  //     remixUrl: `${"null&code=dce3c58cf7bd956c86b90d3275f46781b3f5323fa74ec2ca21bd9c66fb539759"}&language=${getLanguage()}`,
  //   },
  //   creditScoringDetail: {
  //     monthlyProfitRate: 1.0,
  //     tenure: 12
  //   },
  //   downSell: false
  // },
  enableMerchantIdField: false,

  dialog: {
    show: false,
    dialogTitle: null,
    dialogContent: null,
    showButton: true,
    buttonText: null,
    callback: null
  },
  confirmationDialog: {
    show: false,
    dialogTitle: null,
    dialogContent: null,
    hideCancelButton: false,
    confirmText: null,
    confirmIcon: null,
    callback: null,
    stayOpenAfterCallback: false,
  },
  code: null,
  ipAddress: null,

  ramciLoadingTimeLeft: 20,
  sessionStartDate: null,
  sessionEndDate: null,
  referralUrl: null,
  userBehavioural: [],
  userBehaviourReference: [],
  networkInformation: null,

  kycStep: KycStepEnum.PRE_JUMIO,
  kycLoadingTimeLeft: 30,
  kycResult: null,
  kycPromptRetry: null,
  kycReasonCode: null,
  kycReasonDetail: null,
  kycJumioUrl: "",
  isResumeJourney: false,
  isRestoreSessionKey: false,

  /*
    TEST:
    kycResult: "REJECTED",
    kycPromptRetry: false,
    kycReasonCode: "OVE001C",
    kycReasonDetail: "FACE_NOT_FULLY_VISIBLE",
    ---
    kycResult: "WARNING",
    kycPromptRetry: false,
    kycReasonCode: "ODE005A",
    kycReasonDetail: "DIFFERENT_PERSON",
    ---
    kycResult: "REJECTED",
    kycPromptRetry: true,
    kycReasonCode: "ODE001A",
    kycReasonDetail: "NOT_A_DOCUMENT",
   */
};
// distributor ID 0 is Prima

export const generalReducer = (state, action) => {
  switch (action.type) {
    case SET_PDF_DIALOG:
      return {
        ...state,
        isPdfDialogShow: action.data,
      };
    case IS_ERROR:
      return {
        ...state,
        isError: true,
      };
    case ERROR_MESSAGE: {
      if (action.data?.en) {
        return {
          ...state,
          errorMessageMulti: action.data,
          errorMessage: null
        };
      }
      return {
        ...state,
        errorMessage: action.data,
        errorMessageMulti: null
      };
    }
    case SET_GENERAL_DIALOG_DATA: {
      return {
        ...state,
        dialog: action.data
      };
    }
    case SET_CONFIRMATION_DIALOG_DATA:
      return {
        ...state,
        confirmationDialog: action.data
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case SET_CODE:
      return {
        ...state,
        code: action.data,
      };
    case NEXT_STEP:
      return {
        ...state,
        previousStep: state.step,
        step: state.step + 1,
      };
    case PREVIOUS_STEP:
      return {
        ...state,
        step: state.step - 1
      };
    case RESET_STEP:
      return {
        ...state,
        step: 0
      };
    case SET_STEP:
      return {
        ...state,
        step: action.data
      };
    case SET_GENERAL_DATA:
      return {
        ...state,
        ...action.data
      };
    case RESET_GENERAL_DATA:
      return {
        ...generalStates
      };

    case IC_IMAGE_RETRIES:
      return {
        ...state,
        icImageRetries: state.icImageRetries + 1,
      };
    case SELFIE_IMAGE_RETRIES:
      return {
        ...state,
        selfieImageRetries: state.selfieImageRetries + 1,
      };
    case SUPPORT_EMAIL:
      window.open(`mailto:${state.supportEmail}`);
      return {
        ...state
      };
    case MAINTENANCE_NEXT_STEP:
      return {
        ...state,
        maintenanceStep: 101,
      };
    case MAINTENANCE_SET_STEP:
      return {
        ...state,
        maintenanceStep: action.data
      };
    default:
      return state;
  }
};
