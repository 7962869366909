import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Progress } from "shards-react";
// import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { StoreContext } from "../../store/context";
import useStateAndRef from "../../hooks/useStateAndRef";
import * as ubd from "../../utils/userBehaviouralData";
import boostLogoGif from "../../assets/images/Boost_Logo_Loading_67x67.gif";

import "./ReportLoading.scss";

const BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS = 3;
const LONGER_THAN_USUAL_THRESHOLD_IN_SECONDS = 25; // ramciLoadingTimeLeft + 5
const mouseSpeed = [];

let animationIntervalID = 0;

const ReportLoading = () => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);

  const { reportLoadingDone, isError, ramciLoadingTimeLeft, step } = state.generalStates;
  const { setGeneralData, testApi } = actions.generalActions;
  const { handleUserBehaviourData } = actions.otpActions;

  const [loadingSubtitle, setLoadingSubtitle] = useState("encore.reportLoading.subTitle");
  const [processTitle, setProcessTitle] = useState([]);
  const [processTitleColor, setProcessTitleColor] = useState([]);
  const [loadingText, setLoadingText] = useState("");
  const [step1Progress, setStep1Progress] = useState(0);
  const [step2Progress, setStep2Progress] = useState(0);
  const [step3Progress, setStep3Progress] = useState(0);

  const [timeOut, setTimeOut] = useState(ramciLoadingTimeLeft);
  const [timeTrack, setTimeTrack, refTimeTrack] = useStateAndRef(0);
  const [hasCompletedAnim, setHasCompletedAnim, refHasCompletedAnim] = useStateAndRef(false);
  const [hasRamciResult, setHasRamciResult, refHasRamciResult] = useStateAndRef(false);

  // user behavioural data states
  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const pushNewTitle = (titleCode, colorCode) => {
    setProcessTitle((prevProcessTitle) => [
      ...prevProcessTitle,
      titleCode,
    ]);
    setProcessTitleColor((prevProcessTitleColor) => [
      ...prevProcessTitleColor,
      colorCode,
    ]);
  };

  const createAnimationTimer = () => {
    const step1Title = "encore.reportLoading.processing.process1";
    const step1TitleColor = "#05A0D3"; // rgb(5, 160, 211)
    const step2Title = "encore.reportLoading.processing.process2";
    const step2TitleColor = "#05D3B7"; // rgb(5, 211, 183)
    const step3Title = "encore.reportLoading.processing.process3";
    const step3TitleColor = "#05BED3"; // rgb(5, 190, 211)
    const step4Title = "encore.reportLoading.processing.complete";
    const step3AlternateTitle = "encore.kycLoading.processing.process3Alternate";

    const STEP_4_RESERVED_PERCENT = 5; // the remaining % of bar to be left empty before result is received

    const oneThirdDuration = Math.ceil((ramciLoadingTimeLeft) / 3);
    const colorIncrementPerSec = (1 / (oneThirdDuration * 3)) * 100;
    const step1Time = oneThirdDuration;
    const step2Time = oneThirdDuration * 2;
    const step3Time = (oneThirdDuration * 3) - Math.ceil(STEP_4_RESERVED_PERCENT / colorIncrementPerSec);

    const timeout = step3Time + BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS;
    setTimeOut(timeout);

    let timeElapsed = 0;
    let step1ProgressPercent = 0;
    let step2ProgressPercent = 0;
    let step3ProgressPercent = 0;

    setGeneralData({ timerOngoing: true });
    const id = setInterval(() => {
      if (timeElapsed >= 0) {
        if (timeElapsed < step1Time) {
          // step 1: Validating
          step1ProgressPercent += colorIncrementPerSec;
          setStep1Progress(step1ProgressPercent);
          setLoadingText(step1Title);
        } else if (timeElapsed < step2Time) {
          // step 2: Processing
          step2ProgressPercent += colorIncrementPerSec;
          setStep2Progress(step2ProgressPercent);
          setLoadingText(step2Title);
          pushNewTitle(step1Title, step1TitleColor);
        } else if (timeElapsed < step3Time) {
          // step 3: Retrieving results
          step3ProgressPercent += colorIncrementPerSec;
          setStep3Progress(step3ProgressPercent);
          setLoadingText(step3Title);
          pushNewTitle(step2Title, step2TitleColor);
        } else if (refHasRamciResult.current && !refHasCompletedAnim.current) {
          // step 4: Completed
          const currentBar = step1ProgressPercent + step2ProgressPercent + step3ProgressPercent;
          if (currentBar < 100) {
            step3ProgressPercent += (100 - currentBar);
            setStep3Progress(step3ProgressPercent);
          }
          setLoadingText(step4Title);
          pushNewTitle(step3Title, step3TitleColor);
          setHasCompletedAnim(true);
          setTimeOut(refTimeTrack.current + 1 + BEFORE_REDIRECT_TO_RESULT_COUNTDOWN_X_SECONDS);
        } else if (timeElapsed >= LONGER_THAN_USUAL_THRESHOLD_IN_SECONDS) {
          // show comforting message
          setLoadingSubtitle(step3AlternateTitle);
        }
      }
      timeElapsed += 1;
      setTimeTrack((prevTimeTrack) => prevTimeTrack + 1);
    }, 1000);
    animationIntervalID = id;
  };

  const removeAnimationTimer = () => {
    if (animationIntervalID) {
      // console.log("removeAnimationTimer", animationIntervalID);
      clearInterval(animationIntervalID);
      animationIntervalID = null;
      setGeneralData({ timerOngoing: false });
    }
  };

  const showRamciResult = () => {
    handleEndJourney();
    removeAnimationTimer();
    setGeneralData({ step: 7 }); // credit scoring
  };

  useEffect(() => {
    // set timerOngoing to false so that Encore/index.jsx setStepContent() will work
    if (isError) {
      removeAnimationTimer();
      return;
    }

    if (timeTrack > timeOut && hasCompletedAnim && hasRamciResult) {
      // Animation already finished, now show result
      showRamciResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTrack]);

  useEffect(() => {
    setHasRamciResult(reportLoadingDone);
  }, [reportLoadingDone]);

  useEffect(() => {
    if (step == 6) {
      // DEV:
      // testApi();
      createAnimationTimer();
      return () => {
        removeAnimationTimer();
      };
    }
  }, []);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const getProcessTitle = () => {
    const result = [];
    const color = processTitleColor.filter(onlyUnique).slice();
    const unique = processTitle.filter(onlyUnique);
    unique
      .slice()
      .forEach((title, index) => {
        result.push(
          <tr key={index} style={{ color: color[index] }}>
            <td>
              <CheckCircleIcon />
            </td>
            <td className="movableText">
              {" "}
              {t(`${title}`)}
            </td>
            <td>
              <CheckCircleIcon style={{ opacity: 0 }} />
            </td>
          </tr>
        );
      });
    return result;
  };

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const params = {
      step: ubd.STEP_REPORT_LOADING,
      startDate: journeyStart,
      endDate: singleStepEnd,
      totalMouseSpeed,
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="encore__ramci__loading">
      <div className="spinner">
        {/* <CircularProgress style={{ color: "#231F20" }} size={30} /> */}
        <img src={boostLogoGif} alt="Boost" />
      </div>
      <div className="title">{t("encore.reportLoading.title")}</div>
      <div
        className="desc"
        dangerouslySetInnerHTML={{
          __html: t(loadingSubtitle),
        }}
      />
      <div className="progress-container">
        <Progress multi className="loading-test">
          <Progress bar value={step1Progress} className="blue-testing" />
          <Progress bar value={step2Progress} className="green-testing" />
          <Progress bar value={step3Progress} className="light-blue-testing" />
        </Progress>
      </div>
      <div id="loadingText1" className="loading-text">{t(`${loadingText}`)}</div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <table className="table-style">
          <thead>{getProcessTitle()}</thead>
        </table>
      </div>
    </div>
  );
};

export default ReportLoading;
