import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { StoreContext } from "../../store/context";
import { BSA_JOURNEY_STATUS_FORCED, BSA_JOURNEY_STATUS_NOT_REQUIRED } from "../../utils/constants";
import CongratsResultTypeEnum from "../../constants/CongratsResultTypeEnum";
import {
  toDollar,
  getLanguage,
  clearCacheProgress,
  navigateToExternal,
  navigateToBoostCredit
} from "../../utils";
import * as ubd from "../../utils/userBehaviouralData";
import goldBgMobile from "../../assets/images/encore/gold-bg-mobile.svg";
import blueBgMobile from "../../assets/images/encore/blue-bg-mobile.svg";
import turquoiseBgMobile from "../../assets/images/encore/turquoise-bg-mobile.svg";
import redBgMobile from "../../assets/images/encore/red-bg-mobile.svg";
import { ReactComponent as MailIcon } from "../../assets/images/encore/goldenPage/Mail Read.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/encore/goldenPage/File Upload.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/encore/goldenPage/Documents.svg";
import bannerAlmostDone from "../../assets/images/encore/YourAreAlmostDone@2x.png";

import "./CongraluatePage.scss";

const mouseSpeed = [];

const CongraluatePage = ({ history }) => {
  const { t, i18n } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { sendRemixEmail } = actions.credentialActions;
  // const resultType = CongratsResultTypeEnum.FRESH_CUSTOMER; // DEV
  const {
    resultType,
    reportResponse,
    step,
    hideFinancingDetail,
    hideRemixContainer,
    hideMwcContainer,
    bankStatementRequired,
    bankStatementCountInMonths,
    bsaRequirementStatus
  } = state.generalStates;
  const { cellPhone, email } = state.formStates;
  const { setGeneralData, checkIfProductIsShortened, setConfirmationDialogData } = actions.generalActions;
  const { handleUserBehaviourData } = actions.otpActions;

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const [isForcedBsa, setIsForcedBsa] = useState(false);
  const [boxContents, setBoxContents] = useState(null);

  const [expandedFeatures, setExpandedFeatures] = useState(false);

  async function handleEndJourney() {
    try {
      return new Promise((resolve) => {
        ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
        setSingleStepEnd(ubd.formatCurrentDateTime());
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const params = {
      step: ubd.STEP_CONGRATULATION,
      startDate: journeyStart,
      endDate: singleStepEnd,
      totalMouseSpeed,
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (email && cellPhone) {
      clearCacheProgress(email, `+60${cellPhone}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportResponse) {
      setIsForcedBsa((bsaRequirementStatus == BSA_JOURNEY_STATUS_FORCED) || bankStatementRequired || reportResponse.eligibilityAmount == 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportResponse, bsaRequirementStatus, bankStatementRequired]);

  useEffect(() => {
    setEnableDisabledContainers();
    setBoxContents(getHeaderContainerTitleAndDesc());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultType, bsaRequirementStatus, isForcedBsa, i18n.language]);

  const goToTwelveBankStatement = (event) => {
    setGeneralData({ previousStep: step, step: -1 });
  };

  const getImageBg = () => {
    switch (resultType) {
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER:
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
        return checkIfProductIsShortened() ? turquoiseBgMobile : goldBgMobile;
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA:
        return blueBgMobile;
      default:
        return redBgMobile;
    }
  };

  const getHeaderContainerTitleAndDesc = () => {
    switch (resultType) {
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA: {
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType4or5.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType4or5.subTitle"),
              }}
            />
          </>
        );
      }
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA: {
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t(!isForcedBsa ? "encore.goldenPage.resultType0.title" : "encore.goldenPage.resultTypeNew.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: checkIfProductIsShortened() ? t("encore.goldenPage.resultTypeNew.subTitleShortened") : t(!isForcedBsa ? "encore.goldenPage.resultType0.subTitle" : "encore.goldenPage.resultTypeNew.subTitle"),
              }}
            />
            {![CongratsResultTypeEnum.FRESH_CUSTOMER, CongratsResultTypeEnum.EXISTING_CUSTOMER, CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL].includes(resultType) && !isForcedBsa && (
              <div className="subDesc">
                {t("encore.goldenPage.resultType0.financingAmount")}
                <b>
                  RM
                  {toDollar(reportResponse.eligibilityAmount).toLocaleString()}
                </b>
                .
              </div>
            )}
          </>
        );
      }
      default:
        return <div>Invalid Result</div>;
    }

    /*
    switch (resultType) {
      // 0/1 = new customer
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType0.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType0.subTitle"),
              }}
            />
          </>
        );
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType1.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType1.secondTitle"),
              }}
            />
            <div />
          </>
        );
      // 2/3 = existing customer
      case CongratsResultTypeEnum.EXISTING_CUSTOMER:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType2or3.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType2or3.subTitle"),
              }}
            />
            { (resultType == CongratsResultTypeEnum.EXISTING_CUSTOMER && !isForcedBsa) && (
              <div className="subDesc">
                {t("encore.goldenPage.resultType2or3.financingAmount")}
                <b>
                  RM
                  {toDollar(reportResponse.eligibilityAmount).toLocaleString()}
                </b>
                .
              </div>
            ) }
          </>
        );
      // 4/5 = partners
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA:
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType4or5.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType4or5.subTitle"),
              }}
            />
          </>
        );
      // 6/7 = approved in principals
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA:
        return (
          <>
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType6or7.title"),
              }}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.resultType6or7.subTitle"),
              }}
            />
          </>
        );
      default:
        return <div>Not Found</div>;
    }
    */
  };

  const setEnableDisabledContainers = () => {
    switch (resultType) {
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA: {
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: true || bsaRequirementStatus == BSA_JOURNEY_STATUS_NOT_REQUIRED,
          hideMwcContainer: true,
        });
        break;
      }
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER: {
        setGeneralData({
          hideFinancingDetail: false,
          hideRemixContainer: checkIfProductIsShortened() || bsaRequirementStatus == BSA_JOURNEY_STATUS_NOT_REQUIRED,
          hideMwcContainer: false,
        });
        break;
      }
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA: {
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: checkIfProductIsShortened() || bsaRequirementStatus == BSA_JOURNEY_STATUS_NOT_REQUIRED,
          hideMwcContainer: true,
        });
        break;
      }
      default:
        break;
    }

    /*
    switch (resultType) {
      // 0/1 = new customer
      case CongratsResultTypeEnum.FRESH_CUSTOMER:
        setGeneralData({
          hideFinancingDetail: isForcedBsa,
          hideRemixContainer: false,
          hideMwcContainer: isForcedBsa,
          specialCase: false
        });
        break;
      case CongratsResultTypeEnum.FRESH_CUSTOMER_BSA:
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: false,
          hideMwcContainer: true,
          specialCase: false
        });
        break;
      // 2/3 = existing customer
      case CongratsResultTypeEnum.EXISTING_CUSTOMER:
        setGeneralData({
          hideFinancingDetail: isForcedBsa,
          hideRemixContainer: false,
          hideMwcContainer: isForcedBsa,
          specialCase: false
        });
        break;
      case CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA:
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: false,
          hideMwcContainer: true,
          specialCase: false
        });
        break;
      // 4/5 = partners
      case CongratsResultTypeEnum.PARTNER:
      case CongratsResultTypeEnum.PARTNER_BSA:
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: true,
          hideMwcContainer: true,
          specialCase: true
        });
        break;
      // 6/7 = approved in principals
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL:
      case CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA:
        setGeneralData({
          hideFinancingDetail: true,
          hideRemixContainer: false,
          hideMwcContainer: true,
          specialCase: true
        });
        break;
      default:
        return <div>Not Found</div>;
    }
    */
  };

  const handleAcceptAndFinalize = () => {
    if (hideRemixContainer) {
      // immediately redirect
      handleEndJourney().then(() => {
        navigateToExternal(reportResponse.productDetail.mwcUrl);
      });
    } else {
      // show confirmation dialog
      // setGeneralData({ confirmContinue: true }); // old modal
      setConfirmationDialogData({
        show: true,
        dialogTitle: "encore.confirmContinue.title",
        dialogContent: t("encore.confirmContinue.desc", { count: bankStatementCountInMonths }),
        confirmText: "encore.confirmContinue.container.continue.title",
        stayOpenAfterCallback: true,
        callback: (result) => {
          if (result) {
            setConfirmationDialogData({
              show: true,
              dialogTitle: "encore.confirmContinue.title",
              dialogContent: "encore.confirmContinue.container.continue.desc",
              callback: () => {
                handleEndJourney().then(() => {
                  navigateToExternal(reportResponse.productDetail.mwcUrl);
                });
              }
            });
          }
        }
      });
    }
  };

  const handleUploadBankStmtNow = () => {
    handleEndJourney().then(() => {
      navigateToExternal(reportResponse.productDetail.remixUrl);
    });
  };

  const handleUploadBankStmtLater = () => {
    handleEndJourney().then(() => {
      sendRemixEmail();
    });
  };

  return (
    checkIfProductIsShortened() ? (
      <div className="encore__financing__ui">
        <div className="offline_holding">
          <div className="title">
            {t("encore.goldenPage.resultTypeNew.title")}
          </div>
          <div className="desc">
            {t("encore.goldenPage.resultTypeNew.subTitleShortened")}
          </div>
          <div className="banner">
            <img src={bannerAlmostDone} alt="result_banner" />
          </div>
        </div>
      </div>
    ) : (
      <div className="encore__financing__ui">
        <div className="header-container" style={{ backgroundImage: `url(${getImageBg()})` }}>
          {boxContents}
        </div>

        <div
          className="credit-limit-container"
          hidden={hideFinancingDetail}
        >
          <div className="label-holder">{t("common.financingAmountLimit")}</div>
          <div className="price-holder">
            {`RM ${toDollar(reportResponse.eligibilityAmount).toLocaleString()}`}
          </div>
          <hr />
          <div className="info-holder">
            <div className="product-holder">
              <div className="label-holder">{t("common.product")}</div>
              <div className="value-holder">
                {reportResponse.productDetail?.name}
              </div>
            </div>
            <div className="product-info-group">
              <div className="product-info-holder">
                <div className="label-holder">{t("common.profitRate")}</div>
                <div className="value-holder">
                  {`${reportResponse.creditScoringDetail?.monthlyProfitRate}% `}
                  <span className="label-holder">{t("common.perMonth")}</span>
                </div>
              </div>
              <div className="product-info-holder">
                <div className="label-holder">{t("common.tenure")}</div>
                <div className="value-holder">
                  {reportResponse.creditScoringDetail?.tenure}
                  {" "}
                  {t("common.month_plural")}
                </div>
              </div>
            </div>
          </div>
          {(reportResponse.productDetail?.featureText?.[getLanguage()]) || reportResponse.productDetail?.featureUrl
            ? (
              <>
                <div className="label-holder mt-4">{t("common.feature")}</div>
                <div className="desc-value-holder">
                  {reportResponse?.productDetail?.featureText?.[getLanguage()]}
                  {" "}
                  {reportResponse.productDetail.featureUrl ? (
                    <a
                      href={reportResponse.productDetail.featureUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#CC8200",
                        textDecoration: "underline",
                      }}
                    >
                      {t("common.learnMoreHere")}
                    </a>
                  )
                    : <></>}
                </div>
              </>
            )
            : <></>}
          <div
            className="collapse-click"
            onClick={() => {
              setExpandedFeatures(!expandedFeatures);
            }}
          >
            {t("common.whatDoesThisMean")}
            {" "}
            {expandedFeatures ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
          </div>
          {expandedFeatures && (
            <div className="collapse-content">
              {t("common.whatDoesThisMeanContent")}
            </div>
          )}
        </div>

        <div
          className="body-container"
          hidden={hideMwcContainer}
        >
          <hr className="mt-4 mb-4" />
          <div
            className="body-content"
          >
            {/* <div className="header">
              <span>{t("encore.goldenPage.containerSecond.resultType0.head")}</span>
            </div> */}

            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("encore.goldenPage.containerSecond.resultType0.head"),
              }}
            />

            <div
              className="red button-container"
              onClick={handleAcceptAndFinalize}
            >
              <div className="button-text-holder">
                <div
                  className="title uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerSecond.redContainer.head"),
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: hideRemixContainer ? t("encore.goldenPage.containerSecond.redContainer.descNoRemix") : t("encore.goldenPage.containerSecond.redContainer.desc"),
                  }}
                />
              </div>
              <div className="button-icon-holder">
                <DocumentIcon />
              </div>
            </div>
          </div>
        </div>

        <div
          className="body-container"
          hidden={hideRemixContainer}
        >
          <hr className="mt-4 mb-4" />

          <div
            className="body-content"
          >
            { isForcedBsa ? (
              <>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.resultType1.title"),
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.resultType1.desc", { count: bankStatementCountInMonths }),
                  }}
                />
              </>
            )
              : (
                <>
                  {!hideMwcContainer
                    ? (
                      <>
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: t("encore.goldenPage.containerThird.resultType0.title"),
                          }}
                        />
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: t("encore.goldenPage.containerThird.resultType0.desc", { count: bankStatementCountInMonths }),
                          }}
                        />
                      </>
                    )
                    : (
                      <>
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: t("encore.goldenPage.containerThird.resultType1.title"),
                          }}
                        />
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: t("encore.goldenPage.containerThird.resultType1.desc", { count: bankStatementCountInMonths }),
                          }}
                        />
                      </>
                    )}
                </>
              )}
            <div
              id="btn_accept_upload_bsa"
              className="gold button-container"
              onClick={handleUploadBankStmtNow}
            >
              <div className="button-text-holder">
                <div
                  className="title uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.goldContainer.head"),
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.goldContainer.desc", { count: bankStatementCountInMonths }),
                  }}
                />
              </div>
              <div className="button-icon-holder">
                <UploadIcon />
              </div>
            </div>

            <div
              id="btn_accept_upload_later"
              className="red button-container"
              onClick={handleUploadBankStmtLater}
            >
              <div className="button-text-holder">
                <div
                  className="title uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.redContainer.head"),
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: t("encore.goldenPage.containerThird.redContainer.desc"),
                  }}
                />
              </div>
              <div className="button-icon-holder">
                <MailIcon />
              </div>
            </div>

          </div>
        </div>

        <div
          className="encore__section__title"
          hidden={hideRemixContainer}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              goToTwelveBankStatement();
            }}
          >
            {t("encore.goldenPage.containerThird.link")}
          </a>
        </div>

        <div className="encore__next__button" hidden={!hideRemixContainer || !hideMwcContainer}>
          <Button
            variant="contained"
            color="primary"
            className="button"
            onClick={() => {
              handleEndJourney().then(() => {
                navigateToBoostCredit();
              });
            }}
          >
            {t("common.returnHome")}
          </Button>
        </div>
      </div>
    )
  );
};

export default CongraluatePage;
