import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { StoreContext } from "../../store/context";
import facebook from "../../assets/images/socialMedia/facebook.svg";
import instagram from "../../assets/images/socialMedia/instagram.svg";
import linkedin from "../../assets/images/socialMedia/linkedin.svg";
import youtube from "../../assets/images/socialMedia/youtube.svg";
import my from "../../assets/images/my.svg";
import en from "../../assets/images/en.svg";
import axiata from "../../assets/images/MobiusACPLanding/axiata.svg";
import boost from "../../assets/images/MobiusACPLanding/boost.svg";
import celcom from "../../assets/images/MobiusACPLanding/celcom.svg";
import boostCredit from "../../assets/images/MobiusACPLanding/boost-credit.svg";
import personOnPhone from "../../assets/images/MobiusACPLanding/person-on-phone.png";
import womanIpad from "../../assets/images/MobiusACPLanding/woman-ipad.png";
import iconSyariah from "../../assets/images/MobiusACPLanding/KeyBenefit/icon-Syariah.svg";
import iconProfitRate from "../../assets/images/MobiusACPLanding/KeyBenefit/icon-Profit-Rate.svg";
import iconFinTenure from "../../assets/images/MobiusACPLanding/KeyBenefit/icon-Fin-Tenure.svg";
import iconFinAmount from "../../assets/images/MobiusACPLanding/KeyBenefit/icon-Fin-Amount.svg";
import {
  getLanguage
} from "../../utils";

import "./ACPMobiusLanding.scss";

const {
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_SUPPORT_EMAIL,
} = process.env;

const SCREEN_SIZE_PERCENTAGE = 70;
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#124A67",
    color: theme.palette.common.white,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  body: {
    fontSize: 14,
    color: "#124A67",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF"
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F2F5F7",
    }
  },
}))(TableRow);

const ACPMobiusLanding = ({ history }) => {
  const { t, i18n } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData } = actions.generalActions;
  const [scrollY, setScrollY] = useState(0);
  const [screenSize, setScreenSize] = useState(0);
  const { bankStatementCountInMonths } = state.generalStates;
  const useStyles = makeStyles({
    table: {
      // maxWidth: 700,
      // width: 400
    },
    root: {
      width: screenSize < SCREEN_SIZE_PERCENTAGE ? "100%" : "65%",
      ["@media (max-device-width:768px)"]: { // eslint-disable-line no-useless-computed-key
        width: "100%"
      },
      ["@media (min-device-width : 769px) and (max-device-width : 1024px)"]: { // eslint-disable-line no-useless-computed-key
        width: "100%"
      }
    }
  });

  const classes = useStyles();

  const keyTermsAndBenefit = [
    {
      icon: iconFinAmount,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key1.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key1.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key1.description")
    },
    {
      icon: iconFinTenure,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key2.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key2.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key2.description")
    },
    {
      icon: iconProfitRate,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key3.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key3.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key3.description")
    },
    {
      icon: iconSyariah,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key4.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key4.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key4.description")
    },
    /* {
      icon: iconAccidentalDeath,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key5.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key5.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key5.description")
    },
    {
      icon: iconCriticalIllness,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key6.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key6.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key6.description")
    },
    {
      icon: iconTransfer,
      altIcon: t("acpMobiusLanding.firstBody.keyTerms.benefits.key7.title"),
      title: t("acpMobiusLanding.firstBody.keyTerms.benefits.key7.title"),
      description: t("acpMobiusLanding.firstBody.keyTerms.benefits.key7.description")
    }, */
  ];

  const faqs = [
    {
      title: t("acpMobiusLanding.firstBody.right.faqs.item1.title"),
      description: t("acpMobiusLanding.firstBody.right.faqs.item1.description")
    },
    {
      title: t("acpMobiusLanding.firstBody.right.faqs.item2.title"),
      description: t("acpMobiusLanding.firstBody.right.faqs.item2.description")
    },
    {
      title: t("acpMobiusLanding.firstBody.right.faqs.item3.title"),
      description: t("acpMobiusLanding.firstBody.right.faqs.item3.description")
    },
    {
      title: t("acpMobiusLanding.firstBody.right.faqs.item4.title"),
      description: t("acpMobiusLanding.firstBody.right.faqs.item4.description")
    }
  ];

  const handleAllTable = (keyName, count) => {
    const layout = [];
    layout.push(
      <StyledTableRow key={0}>
        <StyledTableCell colSpan="4" style={{ backgroundColor: "white", border: "unset" }} width="5" />
      </StyledTableRow>
    );
    for (let i = 1; i <= count; i++) {
      layout.push(
        <StyledTableRow key={i}>
          <StyledTableCell style={{ backgroundColor: "white", border: "unset" }} width="5" />
          <StyledTableCell
            component="th"
            scope="row"
            width="450"
            dangerouslySetInnerHTML={{
              __html: t(`acpMobiusLanding.secondBody.${keyName}.row${i}.title`),
            }}
          />
          <StyledTableCell
            align="center"
            width="450"
            dangerouslySetInnerHTML={{
              __html: t(`acpMobiusLanding.secondBody.${keyName}.row${i}.descripton`),
            }}
          />
          <StyledTableCell style={{ backgroundColor: "white", border: "unset" }} width="5" />
        </StyledTableRow>
      );
    }
    layout.push(
      <StyledTableRow key={count + 1}>
        <StyledTableCell colSpan="4" style={{ backgroundColor: "white", border: "unset" }} width="5" />
      </StyledTableRow>
    );
    return layout;
  };

  const socialMedia = [
    {
      "icon": facebook,
      "altIcon": "Facebook",
      "title": "Facebook",
      "link": "https://www.facebook.com/MYBoostCredit/"
    },
    {
      "icon": linkedin,
      "altIcon": "LinkedIn",
      "title": "LinkedIn",
      "link": "https://www.instagram.com/myboostcredit/"
    },
    {
      "icon": instagram,
      "altIcon": "Instagram",
      "title": "Instagram",
      "link": "https://www.linkedin.com/company/myboostcredit/"
    },
    {
      "icon": youtube,
      "altIcon": "YouTube",
      "title": "YouTube",
      "link": "https://www.youtube.com/channel/UCR-UFeVSCAl0SUTu0EqjF7A"
    }
  ];

  const handleChange = (event, value) => {
    i18n.changeLanguage(value);
    if (value) {
      localStorage.setItem("language", value);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
    setScreenSize(Math.round(window.devicePixelRatio * 100));
    setScrollY(window.scrollY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.onscroll = () => {
      setScrollY(window.scrollY);
    };
    window.onresize = () => {
      setScreenSize(Math.round(window.devicePixelRatio * 100));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLanguage()]);

  return (
    <div className="acp_mobius_landing">
      <div className="language-movable font-size-14" style={{ color: "white" }}>
        <Hidden only={["xs", "sm"]}>
          <div
            style={{ color: getLanguage() == "ms" ? "#EDA521" : "white", cursor: "pointer" }}
            onClick={(e) => {
              handleChange(e, "ms");
            }}
          >
            <div className={getLanguage() == "ms" ? "ring" : ""}>
              <img src={my} alt="MY" />
            </div>
            <div>MY</div>
          </div>
          <div
            className="mt-3"
            style={{ color: getLanguage() == "en" ? "#EDA521" : "white", cursor: "pointer" }}
            onClick={(e) => {
              handleChange(e, "en");
            }}
          >
            <div className={getLanguage() == "en" ? "ring" : ""}>
              <img src={en} alt="EN" />
            </div>
            <div>EN</div>
          </div>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          {getLanguage() == "ms"
            ? (
              <>
                <div
                  style={{ color: "#EDA521", cursor: "pointer" }}
                  onClick={(e) => {
                    handleChange(e, "en");
                  }}
                >
                  <div className="ring">

                    <img src={en} alt="EN" />
                  </div>
                  {/* <div>MY</div> */}
                </div>
              </>
            )
            : (
              <>
                <div
                  style={{ color: "#EDA521", cursor: "pointer" }}
                  onClick={(e) => {
                    handleChange(e, "ms");
                  }}
                >
                  <div className="ring">
                    <img src={my} alt="MY" />
                  </div>
                  {/* <div>EN</div> */}
                </div>
              </>
            )}
        </Hidden>
      </div>
      <span style={{ position: "fixed", width: "100%", top: "0", zIndex: "999" }}>
        <div className={scrollY > 60 ? "primary-color uppercase font-size-10 bold header-closed" : "header primary-color uppercase font-size-10 bold"}>
          <div className="item-flex">
            <div>
              {t("acpMobiusLanding.header.left.text1")}
            </div>
            <div>
              <img src={axiata} alt="Axiata" width="80" className="margin-top-minus-9" />
            </div>
            <div>
              {t("acpMobiusLanding.header.left.text2")}
            </div>
          </div>
          <Hidden only={["xs", "sm"]}>
            <div className="item-flex">
              <div>
                {t("acpMobiusLanding.header.right.text1")}
              </div>
              <div>
                <img src={axiata} alt="Axiata" width="60" height="40" />
              </div>
              <div>
                <img src={boost} alt="Boost" width="60" height="40" />
              </div>
              <div>
                <img src={celcom} alt="Celcom" width="60" height="40" className="margin-top-minus-9" />
              </div>
            </div>
          </Hidden>
        </div>
        <div className={scrollY > 60 ? "subheader sticky " : "subheader"} id="subheader">
          <div>
            <img src={boostCredit} alt="Boost Credit" width="130" />
          </div>
          <Hidden only={["xs", "sm"]}>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="button"
                onClick={() => {
                  setGeneralData({ step: 0 });
                }}
              >
                {t("common.applyNow")}
              </Button>
            </div>
          </Hidden>
        </div>
      </span>
      <div className="header-bg" style={{ marginTop: "40px" }}>
        <div className={screenSize < SCREEN_SIZE_PERCENTAGE ? "container header-content" : "header-content"}>
          <div
            className="bold font-size-21 primary-color-light"
            dangerouslySetInnerHTML={{
              __html: t("acpMobiusLanding.headerBg.text1"),
            }}
          />
          <div
            className="font-size-48 primary-color-dark"
            dangerouslySetInnerHTML={{
              __html: t("acpMobiusLanding.headerBg.text2"),
            }}
          />
          <div
            className="font-size-21 primary-color-light"
            dangerouslySetInnerHTML={{
              __html: t("acpMobiusLanding.headerBg.text3"),
            }}
          />
          <div className="mt-5">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => {
                setGeneralData({ step: 0 });
              }}
            >
              {t("common.applyNow")}
            </Button>
          </div>
        </div>
      </div>
      <div className={screenSize < SCREEN_SIZE_PERCENTAGE ? "first-body container" : "first-body"}>
        <div className="left">
          <div className="image-text mb-5">
            <div className="image">
              <img src={personOnPhone} alt="About" width="261" height="220" />
            </div>
            <div className="line-height-24 ml-lg-5 ml-md-4">
              <div
                className="bold primary-color-light uppercase font-size-21"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.item1.title"),
                }}
              />
              <div
                className="primary-color-light font-size-16 mt-3 mb-5"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.item1.description"),
                }}
              />
            </div>
          </div>
          <div className="image-text mt-lg-5 mt-5">
            <div className="line-height-24 text-align-right mr-4 mr-lg-5">
              <div
                className="bold primary-color-light uppercase font-size-21"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.item2.title"),
                }}
              />
              <div
                className="primary-color-light font-size-16 mt-3"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.item2.description"),
                }}
              />
            </div>
            <div className="image text-align-right">
              <img src={womanIpad} alt="Got More For You" width="261" height="378" />
            </div>
          </div>
          <div className="key-terms">
            <div
              className="bold font-size-21 primary-color-light uppercase mt-10"
              dangerouslySetInnerHTML={{
                __html: t("acpMobiusLanding.firstBody.keyTerms.title"),
              }}
            />
            <div className="content">
              {keyTermsAndBenefit.map((benefit, index) => {
                return (
                  <div className="item" key={index}>
                    <div>
                      <img src={benefit.icon} alt={benefit.altIcon} />
                    </div>
                    <div className="font-size-16 primary-color-light bold line-height-24 mt-2">
                      {benefit.title}
                    </div>
                    <div className="font-size-16 primary-color-light-soft line-height-21 mt-2">
                      {benefit.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="middle" />
        <div className="right">
          <div className="upper-content">
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold">
              {t("acpMobiusLanding.firstBody.right.title")}
            </div>
            <hr className="primary-color-light" />
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold mt-4">
              {t("acpMobiusLanding.firstBody.right.subtitle")}
            </div>
            <ul style={{ padding: "15px" }} className="primary-color-light font-size-12 line-height-18 mt-1">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.right.pointsEligibility"),
                }}
              />
            </ul>
            <hr className="primary-color-light" />
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold mt-4">
              {t("acpMobiusLanding.firstBody.right.secondSubtitle")}
            </div>
            <ul style={{ padding: "15px" }} className="primary-color-light font-size-12 line-height-18 mt-1">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.right.pointsApplicationDetails", { count: bankStatementCountInMonths }),
                }}
              />
            </ul>
            <hr className="primary-color-light" />
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold mt-4">
              {t("acpMobiusLanding.firstBody.right.thirdSubtitle")}
            </div>
            <ul style={{ padding: "15px" }} className="primary-color-light font-size-12 line-height-18 mt-1">
              <span
                className="underline"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.right.download"),
                }}
              />
            </ul>
            <hr className="primary-color-light" />
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold mt-4">
              {t("acpMobiusLanding.firstBody.right.fourthSubtitle")}
            </div>
            {faqs.map((faq, index) => {
              return (
                <div className="left-border" key={index}>
                  <div
                    className="primary-color-light font-size-14 line-height-21 bold mt-3 ml-3"
                    dangerouslySetInnerHTML={{
                      __html: faq.title,
                    }}
                  />
                  <div
                    className="primary-color-light font-size-12 line-height-18 ml-3"
                    dangerouslySetInnerHTML={{
                      __html: faq.description,
                    }}
                  />
                </div>
              );
            })}
            {/* <hr className="primary-color-light" />
            <div className="primary-color-light font-size-14 line-height-21 uppercase bold mt-4">
              {t("acpMobiusLanding.firstBody.right.fifthSubtitle")}
            </div>
            <div className="left-border mt-3">
              <div
                className="primary-color-light font-size-12 line-height-18 ml-3"
                dangerouslySetInnerHTML={{
                  __html: t("acpMobiusLanding.firstBody.right.disclosureDescription"),
                }}></div>
            </div>
            <hr className="primary-color-light" />
            <div
              className="primary-color-light font-size-12 line-height-18 ml-3"
              dangerouslySetInnerHTML={{
                __html: t("acpMobiusLanding.firstBody.right.shariah"),
              }}></div> */}
            <div className="mt-5">
              <Button
                variant="contained"
                color="primary"
                className="w-100"
                onClick={() => {
                  setGeneralData({ step: 0 });
                }}
              >
                {t("common.applyNow")}
              </Button>
            </div>
          </div>
          {/* <div
            className="primary-color-very-light font-size-12 line-height-18 text-align-right mt-2"
            dangerouslySetInnerHTML={{
              __html: t("acpMobiusLanding.firstBody.right.subjectTerms"),
            }}></div> */}
        </div>
      </div>
      <div className="second-body">
        <div className={screenSize < SCREEN_SIZE_PERCENTAGE ? "container content" : "content"}>
          <div
            className="primary-color-light font-size-48"
            dangerouslySetInnerHTML={{
              __html: t("acpMobiusLanding.secondBody.title"),
            }}
          />
          <div>
            <TableContainer component={Paper} className={classes.root}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan="1" />
                    <StyledTableCell colSpan="3">{t("acpMobiusLanding.secondBody.acp.head")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleAllTable("acp", 14)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <div className="mt-5">
            <TableContainer component={Paper} className={classes.root}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan="1"></StyledTableCell>
                    <StyledTableCell colSpan="3">{t(`acpMobiusLanding.secondBody.ownerProtect.head`)}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleAllTable("ownerProtect", 6)}
                </TableBody>
              </Table>
            </TableContainer>
          </div> */}
          {/* <div className="mt-5 covid">
            <div>
              <img src={covid19} alt="Covid 19" width="50" />
            </div>
            <div className="font-size-14 uppercase primary-color-dark bold">
              {t(`acpMobiusLanding.secondBody.covid19.title`)}
            </div>
          </div>   */}
          {/* <div className="mt-2">
            <TableContainer component={Paper} className={classes.root}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan="1"></StyledTableCell>
                    <StyledTableCell colSpan="3">{t(`acpMobiusLanding.secondBody.covid19.head`)}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleAllTable("covid19", 2)}
                </TableBody>
              </Table>
            </TableContainer>
          </div> */}
          {/* <div className="mt-2 font-size-12 primary-color-very-light">
            {t(`acpMobiusLanding.secondBody.covid19.smallText`)}
          </div> */}
          {/* <div className="mt-5 font-size-12 primary-color-light great-eastern">
            <div>
              {t(`acpMobiusLanding.secondBody.greatEastern.content`)}
            </div>
            <div className="">
              <img src={greatEastern} alt="Great Eastern" width="100" className="float-right" />
            </div>
          </div> */}
          <div className="mt-5">
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={() => {
                setGeneralData({ step: 0 });
              }}
            >
              {t("common.applyNow")}
            </Button>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className={screenSize < SCREEN_SIZE_PERCENTAGE ? "container upper" : "upper"}>
          <div className="w-50">
            <div className="font-size-14 line-height-21 uppercase primary-color-light bold">
              {t("acpMobiusLanding.footer.upper.contactUs.title")}
            </div>
            <div className="content font-size-14 line-height-21 primary-color-light-soft">
              <div>
                <div>
                  <a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`} className="primary-color-light-soft">{REACT_APP_SUPPORT_EMAIL}</a>
                </div>
                <div className="mt-2 mt-md-0 mt-lg-0">
                  <a href={`tel:${REACT_APP_SUPPORT_PHONE}`} className="primary-color-light-soft">{REACT_APP_SUPPORT_PHONE}</a>
                </div>
              </div>
              <div className="mt-2 mt-md-0 mt-lg-0">
                Axiata Tower, Level 32,
                <br />
                9 Jalan Stesen Sentral 5,
                <br />
                Kuala Lumpur Sentral,
                <br />
                50470 Kuala Lumpur,
                <br />
                Malaysia.
              </div>
            </div>
          </div>
          <div>
            <div className="font-size-14 line-height-21 uppercase primary-color-light bold">
              {t("acpMobiusLanding.footer.upper.connectWithUs.title")}
            </div>
            <div className="social-media">
              {socialMedia.map((socialMediaItem, index) => {
                return (
                  <a href={socialMediaItem.link} style={{ padding: "unset" }} key={socialMediaItem.title} className="item">
                    <div style={{ width: "18%" }} className="mb-1">
                      <img src={socialMediaItem.icon} alt={socialMediaItem.altIcon} />
                    </div>
                    <div className="font-size-14 line-height-21 primary-color-light-soft ml-1">
                      {socialMediaItem.title}
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-lower">
        <div className={screenSize < SCREEN_SIZE_PERCENTAGE ? "container lower primary-color-light font-size-14 line-height-21" : "lower primary-color-light font-size-14 line-height-21"}>
          <div>
            {t("acpMobiusLanding.footer.lower.text1")}
          </div>
          <div>
            {t("acpMobiusLanding.footer.lower.text2")}
          </div>
        </div>
      </div>
      {/* banner */}
    </div>
  );
};

export default ACPMobiusLanding;
