import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { StoreContext } from "../../store/context";
import correctSelfie from "../../assets/images/encore/selfieCaptureTutorial/correctSelfie.png";
import backgroundSelfie from "../../assets/images/encore/selfieCaptureTutorial/backgroundSelfie.png";
import doubleFaceSelfie from "../../assets/images/encore/selfieCaptureTutorial/doubleFaceSelfie.png";
import LanguageToggleButton from "../LanguageToggleButton";

import "./SelfieCaptureTutorial.scss";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  carousel: {
    background: "",
    backgroundSize: "",
    backgroundRepeat: "",
    backgroundPosition: ""
  }
}));
const SelfieCaptureTutorial = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const carouselRef = useRef();
  const [current, setCurrent] = React.useState(0);
  const { state, actions } = useContext(StoreContext);
  const { selfieTutorialCapture, url } = state.generalStates;
  const {
    // closeSelfieCaptureTutorial,
    // modalDialogOpen,
    // setSelfieCameraActive,
    setGeneralData
  } = actions.generalActions;
  const { resetSelfieImage } = actions.formActions;

  // function handleClick() {
  //   window.location.assign(url);
  // }

  function handleClose() {
    setGeneralData({ selfieTutorialCapture: false });
  }

  const items = [
    {
      title: "encore.tutorialModel.selfie.step_1.label",
      subtitle: "encore.tutorialModel.selfie.step_1.content",
      image: correctSelfie,
    },
    {
      title: "encore.tutorialModel.selfie.step_2.label",
      subtitle: "encore.tutorialModel.selfie.step_2.content",
      image: backgroundSelfie,
    },
    {
      title: "encore.tutorialModel.selfie.step_3.label",
      subtitle: "encore.tutorialModel.selfie.step_3.content",
      image: doubleFaceSelfie,
    },
  ];

  function getItem() {
    const layout = [];

    items.forEach((item) => {
      layout.push(
        <div key={item}>
          {/* <Typography variant="h5" gutterBottom className="title">
            {t(item.title)}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t(item.subtitle)}
          </Typography> */}
          <Typography
            variant="subtitle1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(item.title),
            }}
          />
          <div className="logo-container-ic-tutorial">
            <CardMedia
              component="img"
              image={item.image}
              className="logo-ic-tutorial"
            />
          </div>
        </div>
      );
    });
    return layout;
  }

  function getCarousel() {
    return (
      <Carousel
        ref={carouselRef}
        // nextIcon={ArrowForwardIosIcon}
        // prevIcon={ArrowBackIosIcon}
        navButtonsAlwaysInvisible
        animation="slide"
        autoPlay={false}
      >
        {getItem()}
      </Carousel>
    );
  }

  return (
    <div>
      <Dialog
        open={selfieTutorialCapture}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        // onClose={handleClose}
        className="dialog-selfie-tutorial"
        aria-labelledby="alert-dialog-tutorial-slide-title"
        aria-describedby="alert-dialog-tutorial-slide-description"
      >
        <div className="selfieCaptureTutorialModal">
          <div className="selfieCaptureTutorialModal__header">
            <div className="btn-close">
              <CloseIcon onClick={handleClose} />
            </div>
            <div>
              <LanguageToggleButton />
            </div>
          </div>
        </div>
        <div className="center-text">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2}>
              <ArrowBackIosIcon
                onClick={async () => {
                  await carouselRef.current.prev();
                  setCurrent(carouselRef.current.state.active);
                }}
                className={
                  current < 1 ? "arrowIconStyleHidden" : "arrowIconStyle"
                }
                fontSize="large"
              />
            </Grid>
            <Grid item xs={8}>
              {getCarousel()}
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIosIcon
                onClick={async () => {
                  await carouselRef.current.next();
                  setCurrent(carouselRef.current.state.active);
                }}
                className={
                  current === items.length - 1
                    ? "arrowIconStyleHidden"
                    : "arrowIconStyle"
                }
                fontSize="large"
              />
            </Grid>
          </Grid>
          <div className="button_holder">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="buttonStyle"
              onClick={async () => {
                if (current === items.length - 1) {
                  resetSelfieImage();
                  setGeneralData({ selfieCameraActive: false, selfieTutorialCapture: false });
                  handleClose();
                } else {
                  await carouselRef.current.next();
                  setCurrent(carouselRef.current.state.active);
                }
              }}
            >
              {current === items.length - 1
                ? t("encore.tutorialModel.button.gotIt")
                : t("encore.tutorialModel.button.label")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SelfieCaptureTutorial;
