import {
  VERIFY_BASIC_DETAIL_PAGE_LOAD,
  VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS,
  VERIFY_BASIC_DETAIL_PAGE_LOAD_FAILED
} from "../types";

export const verifyPageLoadStates = {
  isPageLoading: false,
  isErrorPageLoading: false
};

export const verifyPageLoadReducer = (state, action) => {
  switch (action.type) {
    case VERIFY_BASIC_DETAIL_PAGE_LOAD:
      return {
        ...state,
        isPageLoading: true,
        isErrorPageLoading: false
      };
    case VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case VERIFY_BASIC_DETAIL_PAGE_LOAD_FAILED:
      return {
        ...state,
        isPageLoading: false,
        isErrorPageLoading: true
      };
    default:
      return state;
  }
};
