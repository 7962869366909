import { useRef, useState } from "react";

const useStateAndRef = (initial) => {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
};

export default useStateAndRef;
