// general
export const IS_LOADING = "IS_LOADING";
export const IS_CALCULATING = "IS_CALCULATING";
export const SET_CODE = "SET_CODE";
export const SET_REQUEST_OTP_STATUS = "SET_REQUEST_OTP_STATUS";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const RESET_STEP = "RESET_STEP";
export const SET_STEP = "SET_STEP";
export const SET_GENERAL_DATA = "SET_GENERAL_DATA";
export const RESET_GENERAL_DATA = "RESET_GENERAL_DATA";
export const SET_GENERAL_DIALOG_DATA = "SET_GENERAL_DIALOG_DATA";
export const SET_CONFIRMATION_DIALOG_DATA = "SET_CONFIRMATION_DIALOG_DATA";
export const SET_CURLEC_BANK_DATA = "SET_CURLEC_BANK_DATA";
export const SET_SKIP_PAYMENT_ENROLMENT = "SET_SKIP_PAYMENT_ENROLMENT";
export const SET_PDF_DIALOG = "SET_PDF_DIALOG";
export const BUTTON_IS_LOADING = "BUTTON_IS_LOADING";

// credential
export const SET_CREDENTIAL_DATA = "SET_CREDENTIAL_DATA";
export const RESET_CREDENTIAL_DATA = "RESET_CREDENTIAL_DATA";

// financial
export const SET_FINANCING_AMOUNT = "SET_FINANCING_AMOUNT";
export const SET_FINANCING_FORM_DATA = "SET_FINANCING_FORM_DATA";
export const RESET_FINANCING_FORM_DATA = "RESET_FINANCING_FORM_DATA";
export const SET_DURATION = "SET_DURATION";
export const SET_TENURE = "SET_TENURE";
export const SET_AGREEMENT = "SET_AGREEMENT";
export const SET_AGREEMENT_WAKALAH = "SET_AGREEMENT_WAKALAH";

// business
export const SET_BUSINESS_FORM_DATA = "SET_BUSINESS_FORM_DATA";
export const RESET_BUSINESS_FORM_DATA = "RESET_BUSINESS_FORM_DATA";
export const SET_STORE_IMAGE_URL = "SET_STORE_IMAGE_URL";

// Personal
export const SET_PERSONAL_FORM_DATA = "SET_PERSONAL_FORM_DATA";
export const RESET_PERSONAL_FORM_DATA = "RESET_PERSONAL_FORM_DATA";
export const SET_SELFIE = "SET_SELFIE";
export const SET_IC_IMG = "SET_IC_IMG";

// Supplementary
export const SET_SUPPLEMENTARY_FORM_DATA = "SET_SUPPLEMENTARY_FORM_DATA";
export const RESET_SUPPLEMENTARY_FORM_DATA = "RESET_SUPPLEMENTARY_FORM_DATA";
export const SET_SERVICE_SUBSCRIPTION_DATA = "SET_SERVICE_SUBSCRIPTION_DATA";
export const SET_CHECKED_ITEMS_DATA = "SET_CHECKED_ITEMS_DATA";

// Statement
export const SET_STATEMENT_DATA = "SET_STATEMENT_DATA";
export const RESET_STATEMENT_DATA = "RESET_STATEMENT_DATA";
export const SET_STATEMENT_STEP = "SET_STATEMENT_STEP";
export const SET_FILES = "SET_FILES";

// Encore
// general
export const IS_ERROR = "IS_ERROR";
// export const IS_FINANCING_ON_THE_WAY = "IS_FINANCING_ON_THE_WAY";
export const ERROR_MESSAGE = "ERROR_MESSAGE";

export const MODAL_DIALOG_OPEN = "MODAL_DIALOG_OPEN";
export const MODAL_DIALOG_CLOSE = "MODAL_DIALOG_CLOSE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";
export const OPEN_FINANCING_ON_THE_WAY_MODAL = "OPEN_FINANCING_ON_THE_WAY_MODAL";
export const CLOSE_FINANCING_ON_THE_WAY_MODAL = "CLOSE_FINANCING_ON_THE_WAY_MODAL";
export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";
export const OTP_ERROR_ENABLE = "OTP_ERROR_ENABLE";
export const IC_IMAGE_ACTIVE = "IC_IMAGE_ACTIVE";
export const SELFIE_IMAGE_ACTIVE = "SELFIE_IMAGE_ACTIVE";
export const IC_IMAGE_RETRIES = "IC_IMAGE_RETRIES";
export const SELFIE_IMAGE_RETRIES = "SELFIE_IMAGE_RETRIES";
export const SUPPORT_EMAIL = "SUPPORT_EMAIL";
export const BUTTON_LOADING = "BUTTON_LOADING";
export const CLOSE_IC_CAPTURE_TUTORIAL = "CLOSE_IC_CAPTURE_TUTORIAL";
export const OPEN_IC_CAPTURE_TUTORIAL = "OPEN_IC_CAPTURE_TUTORIAL";
export const OPEN_SELFIE_CAPTURE_TUTORIAL = "OPEN_SELFIE_CAPTURE_TUTORIAL";
export const CLOSE_SELFIE_CAPTURE_TUTORIAL = "CLOSE_SELFIE_CAPTURE_TUTORIAL";

// form
export const SET_FORM_DATA = "SET_FORM_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const SET_IC_IMAGE = "SET_IC_IMAGE";
export const RESET_IC_IMAGE = "RESET_IC_IMAGE";
export const SET_SELFIE_IMAGE = "SET_SELFIE_IMAGE";
export const RESET_SELFIE_IMAGE = "RESET_SELFIE_IMAGE";

// Maintenance
export const MAINTENANCE_NEXT_STEP = "MAINTENANCE_NEXT_STEP";
export const MAINTENANCE_SET_STEP = "MAINTENANCE_SET_STEP";

// verify basic details page load
export const VERIFY_BASIC_DETAIL_PAGE_LOAD = "VERIFY_BASIC_DETAIL_PAGE_LOAD";
export const VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS = "VERIFY_BASIC_DETAIL_PAGE_LOAD_SUCCESS";
export const VERIFY_BASIC_DETAIL_PAGE_LOAD_FAILED = "VERIFY_BASIC_DETAIL_PAGE_LOAD_FAILED";
