import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StoreContext } from "../../store/context";
import map from "../../assets/images/encore/map.png";
import lock from "../../assets/images/encore/lock.svg";
import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
import * as ubd from "../../utils/userBehaviouralData";

import "./Location.scss";

const mouseSpeed = [];

const Location = ({ history }) => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData, getFbPixelId } = actions.generalActions;
  const { captureLocation } = actions.credentialActions;
  const { storeLocation, handleUserBehaviourData } = actions.otpActions;
  const { previousStep, overallStep, step, sessionKey, existingUser } = state.generalStates;

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const params = {
      step: ubd.STEP_LOCATION,
      startDate: journeyStart,
      endDate: singleStepEnd,
      totalMouseSpeed,
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  const invokeLocation = () => {
    setDisable(true);
    if (navigator.geolocation) {
      handleEndJourney();
      navigator.geolocation.getCurrentPosition(showPosition, showError, options);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  function showPosition(position) {
    position.sessionKey = sessionKey;
    captureLocation(position, storeLocation, getFbPixelId);
    setDisable(false);
  }

  function showError(error) {
    captureLocation(null, null, getFbPixelId);
    setDisable(false);
    // switch(error.code) {
    //   case error.PERMISSION_DENIED:
    //     console.log("User denied the request for Geolocation.");
    //     break;
    //   case error.POSITION_UNAVAILABLE:
    //     console.log("Location information is unavailable.");
    //     break;
    //   case error.TIMEOUT:
    //     console.log("The request to get user location timed out.");
    //     break;
    //   case error.UNKNOWN_ERROR:
    //     console.log("An unknown error occurred.");
    //     break;
    // }
  }

  return (
    <>
      <div className="encore__location__guide">
        <IconButton
          color="primary"
          onClick={() => {
            setGeneralData({ step: 4 });
          }}
          className="encore__page__back_holder"
        >
          <img src={circleLeftArrow} alt="Back" />
        </IconButton>
        <div className="encore__page__upper">
          <div className="encore__identifier">
            <div>
              {`${t("common.step_plural")} ${step} ${t("common.of")} ${overallStep}` }
            </div>
            <div>
              <img src={lock} alt="Secure" />
            </div>
          </div>
          <div className="encore__title">{t("encore.location.title")}</div>
          <div className="encore__banner">
            <img src={map} alt="Map" width="480" />
          </div>
          <div
            className="encore__desc"
            dangerouslySetInnerHTML={{
              __html: t("encore.location.description"),
            }}
          />
          {/* {existingUser?
          <>
            <div className="encore__desc"
                dangerouslySetInnerHTML={{
                  __html: t('encore.location.existingDescription'),
            }}></div>
          </>
          :
          <>
            <div className="encore__desc"
                dangerouslySetInnerHTML={{
                  __html: t('encore.location.description'),
            }}></div>
          </>} */}
        </div>
        <div className="encore__next__button">
          <Button
            id="btn_allow_location"
            variant="contained"
            color="primary"
            className="button"
            disabled={disable}
            onClick={() => {
              invokeLocation();
            }}
          >
            {disable ? <CircularProgress size={25} />
              : (
                <>
                  {t("encore.location.button")}
                </>
              )}
          </Button>
        </div>
      </div>
      {/* <div className="disclaimer" >
        {t("encore.location.disclaimer.line_1")}
        <a href="#">
          {t("encore.location.disclaimer.line_2")}
        </a>
        {t("encore.location.disclaimer.line_3")}
        <a href="#">
          {t("encore.location.disclaimer.line_4")}
        </a>
          {t("encore.location.disclaimer.line_5")}
      </div> */}
    </>
  );
};

export default Location;
