import {
  SET_FORM_DATA,
  RESET_FORM_DATA,
  SET_IC_IMAGE,
  RESET_IC_IMAGE,
  SET_SELFIE_IMAGE,
  RESET_SELFIE_IMAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  IS_ERROR
} from "../types";
import api from "../../utils/api";

export const formActions = (props) => {
  const showErrorPage = (errorMessage = null) => {
    props.dispatch({ type: IS_LOADING, data: false });
    if (errorMessage) {
      props.dispatch({ type: ERROR_MESSAGE, data: errorMessage });
    }
    props.dispatch({ type: IS_ERROR });
  };

  return {
    setFormData: (data) => {
      props.dispatch({ type: SET_FORM_DATA, data });
    },
    resetFormDate: () => {
      props.dispatch({ type: RESET_FORM_DATA });
    },
    setIcImage: (data) => {
      props.dispatch({ type: SET_IC_IMAGE, data });
    },
    resetIcImage: () => {
      props.dispatch({ type: RESET_IC_IMAGE });
    },
    setSelfieImage: (data) => {
      props.dispatch({ type: SET_SELFIE_IMAGE, data });
    },
    resetSelfieImage: () => {
      props.dispatch({ type: RESET_SELFIE_IMAGE });
    },
    getBusinessCategories: () => {
      return api
        .funnelService
        .get("/kyc/getBusinessCategories"
          + "?productId=8011")
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_FORM_DATA, data: { parentCategory: res.data.parentCategory, childCategory: res.data.childCategory } });
        })
        .catch((error) => {
          showErrorPage();
        });
    },
    getStateAndPostcode: () => {
      return api
        .funnelService
        .get("/kyc/getBusinessAddress")
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          const state = Object.assign({}, ...res.data.states.map((s) => ({ [s.stateId]: s })));
          const postOffice = Object.assign({}, ...res.data.postcodes.map((s) => ({ [s.postOfficeId]: s })));
          props.dispatch({
            type: SET_FORM_DATA,
            data: {
              stateList: res.data.states,
              postOfficeList: res.data.postcodes,
              stateHashMap: state,
              postOfficeHashMap: postOffice
            }
          });
        })
        .catch((error) => {
          showErrorPage();
        });
    }
  };
};
