import React, { useState, useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Input } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { StoreContext } from "../../store/context";
import * as ubd from "../../utils/userBehaviouralData";
import { OTP_TIMER } from "../../utils/constants";
import { getLanguage } from "../../utils";
import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
import lock from "../../assets/images/encore/lock.svg";

import "./OTP.scss";

const mouseSpeed = [];

const BasicDetail = ({ history }) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { setGeneralData, getFbPixelId } = actions.generalActions;
  const { setKycLoading, getSessionInfo } = actions.credentialActions;
  const { sendOtp, resumeJourneySendOtp, verifyOtp, handleUserBehaviourData } = actions.otpActions;
  const { overallStep, step, otpError, errorMessage, errorMessageMulti, userBehaviourReference, isResumeJourney } = state.generalStates;
  const { setFormData } = actions.formActions;
  const { otp, cellPhone } = state.formStates;
  const [countdown, setCountdownStart] = React.useState(true);
  const [timer, setTimer] = React.useState(OTP_TIMER);
  const formikRef = useRef(null);

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [onTypingStart, setOnTypingStart] = useState(null);
  const [onTypingEnd, setOnTypingEnd] = useState(null);
  const [totalTypingDuration, setTotalTypingDuration] = useState(null);
  const [typingSpeed, setTypingSpeed] = useState([]);
  const [valueChange, setValueChange] = useState([]);
  const [selectedText, setSelectedText] = useState(null);
  const [copiedText, setCopiedText] = useState([]);
  const [cutText, setCutText] = useState([]);
  const [pastedText, setPastedText] = useState([]);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const otpSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^[0-9]{6}$/, t("encore.otp.field.otpField.requiredError"))
      .required(t("encore.otp.field.otpField.regexError")),
  });

  let timerCountDown = null;
  if (timer > 0) {
    timerCountDown = setTimeout(() => {
      setTimer(timer - 1);
      if (timer === 1) {
        timerComplete();
      }
    }, 1000);
  }

  const timerComplete = () => {
    clearTimeout(timerCountDown);
    setCountdownStart(false);
    setTimer(0);
  };

  const timerStart = () => {
    setCountdownStart(true);
    setTimer(OTP_TIMER);
  };

  // const handleChange = (otp) => {
  //   setFormData({ otp });
  // };

  const inputfocus = (elmnt) => {
    // if((elmnt.keyCode < 48 || elmnt.keyCode > 57) || (elmnt.keyCode < 96 && elmnt.keyCode > 105)){
    //   if(elmnt.key !== "Delete" && elmnt.key !== "Backspace"){
    //     elmnt.target.value="";
    //     return;
    //   }
    // }
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
      return;
    }
    const theEvent = elmnt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
      elmnt.target.value = "";
      return;
    }

    const next = elmnt.target.tabIndex;
    if (elmnt.target.value) {
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleOtp = () => {
    let otpTemp = "";
    if (document.getElementById("otp1").value) {
      otpTemp += document.getElementById("otp1").value;
    }
    if (document.getElementById("otp2").value) {
      otpTemp += document.getElementById("otp2").value;
    }
    if (document.getElementById("otp3").value) {
      otpTemp += document.getElementById("otp3").value;
    }
    if (document.getElementById("otp4").value) {
      otpTemp += document.getElementById("otp4").value;
    }
    if (document.getElementById("otp5").value) {
      otpTemp += document.getElementById("otp5").value;
    }
    if (document.getElementById("otp6").value) {
      otpTemp += document.getElementById("otp6").value;
    }

    setFormData({ otp: otpTemp });
    if (formikRef.current) {
      setTimeout(() => {
        formikRef.current.handleSubmit();
      }, 200);
    }
  };

  // const handleOtpChange = (otp) => setFormData({ otp });

  function validate(evt) {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleResendOtp = () => {
    if (isResumeJourney) {
      resumeJourneySendOtp();
      timerStart();
    } else {
      sendOtp(undefined, undefined, getFbPixelId);
      timerStart();
    }
  };

  useEffect(() => {
    if (isResumeJourney) {
      getSessionInfo(resumeJourneySendOtp);
    }
  }, []);

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const duration = ubd.calculateDuration(onTypingEnd, onTypingStart);
    setTotalTypingDuration(duration._milliseconds + (totalTypingDuration != null ? totalTypingDuration : 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypingEnd]);

  useEffect(() => {
    const fieldNames = ["otp"];
    const typingSpeedArray = [];
    const valueChangeArray = [];
    fieldNames.forEach((name) => {
      if (typingSpeed[name] != null) {
        typingSpeedArray.push(typingSpeed[name]);
      }
      if (valueChange[name] != null) {
        valueChangeArray.push(valueChange[name]);
      }
    });
    const params = {
      step: ubd.STEP_OTP,
      startDate: journeyStart,
      endDate: singleStepEnd,
      typingDuration: totalTypingDuration,
      typingSpeed: typingSpeedArray,
      typingSpeedKey: typingSpeed,
      valueChange: valueChangeArray,
      valueChangeKey: valueChange,
      totalMouseSpeed,
      clipboardCopy: copiedText,
      clipboardCut: cutText,
      clipboardPaste: pastedText
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  const checkBehaviourExist = () => {
    if (userBehaviourReference != null) {
      for (let i = 0; i < userBehaviourReference.length; i++) {
        if (userBehaviourReference[i].stepId == ubd.STEP_OTP) {
          if (userBehaviourReference[i].typingSpeedKey != null) {
            setTypingSpeed(userBehaviourReference[i].typingSpeedKey);
          }
          if (userBehaviourReference[i].clipboardCopy != null) {
            setCopiedText(userBehaviourReference[i].clipboardCopy);
          }
          if (userBehaviourReference[i].clipboardCut != null) {
            setCutText(userBehaviourReference[i].clipboardCut);
          }
          if (userBehaviourReference[i].clipboardPaste != null) {
            setPastedText(userBehaviourReference[i].clipboardPaste);
          }
          if (userBehaviourReference[i].valueChangeKey != null) {
            setValueChange(userBehaviourReference[i].valueChangeKey);
          }
        }
      }
    }
  };

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    checkBehaviourExist();
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="encore__otp__guide">
      { !isResumeJourney && (
        <IconButton
          color="primary"
          onClick={() => {
            setGeneralData({ step: 1 });
          }}
          className="encore__page__back_holder"
        >
          <img src={circleLeftArrow} alt="Back" />
        </IconButton>
      ) }

      <div className="encore__page__upper">
        <div className="encore__identifier">
          <div>
            {`${t("common.step_plural")} ${step} ${t("common.of")} ${overallStep}` }
          </div>
          <div>
            <img src={lock} alt="Secure" />
          </div>
        </div>
        <div className="encore__title">{t("encore.otp.title")}</div>
        <div className="encore__desc">
          {t("encore.otp.description")}
          {" "}
          {`+60 ${cellPhone}`}
          {" "}
        </div>
      </div>

      <div className="formik-form">
        <Formik
          innerRef={formikRef}
          initialValues={{
            otp: `${otp || ""}`,
          }}
          validationSchema={otpSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setFormData(values);
            handleEndJourney();
            timerComplete();
            verifyOtp(values, getFbPixelId, setKycLoading);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, handleBlur }) => (
            <Form>
              <Field name="otp">
                {({ field, form: { touched, errors } }) => (
                  <>
                    <div className="">
                      <Input
                        {...field}
                        id="otp"
                        name="otp"
                        className="input-field"
                        placeholder={t("encore.otp.field.otpField.placeholder")}
                        error={Boolean(touched[field.name] && errors[field.name])}
                        onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                        onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                        onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                        onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                        onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                        onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                        onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                        onKeyPress={(e) => { ubd.checkEnterKey(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                      />
                    </div>
                    {touched[field.name] && errors[field.name] && (
                      <div className="error-text">
                        {errors[field.name]}
                      </div>
                    )}
                  </>
                )}

              </Field>
              <p
                className="otp-text-position"
                style={{ color: "red" }}
              >
                {otpError ? (errorMessageMulti?.[getLanguage()] || errorMessage) : ""}
              </p>

              <p className="otp-text-position">
                {t("encore.otp.sixDigitOtpCode")}
              </p>
              <div className="encore__next__button">
                <Button
                  id="btn_verify_otp"
                  variant="contained"
                  color="primary"
                  className="button"
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={()=>{
                  //   handleOtp();
                  // }}
                >
                  {t("encore.otp.button")}
                </Button>
              </div>
              <div className="encore__next__button">
                <Button
                  variant="outlined"
                  color="secondary"
                  className="button"
                  disabled={timer > 0}
                  onClick={handleResendOtp}
                >
                  {timer > 0 ? timer : `${" "}${t("encore.otp.resendButton")}`}
                </Button>
              </div>

            </Form>
          )}
        </Formik>
      </div>

    </div>
  );
};

export default BasicDetail;
