import {
  SET_FORM_DATA,
  RESET_FORM_DATA,
  SET_IC_IMAGE,
  RESET_IC_IMAGE,
  SET_SELFIE_IMAGE,
  RESET_SELFIE_IMAGE
} from "../types";

export const formStates = {
  // fullName: "NG WAN SENG",
  // email: "aaron.liew@hotmail.com",
  // agreementCheck: true,
  // cellPhone: "60173291000",
  // otp: null,
  // idNumber: "800918-06-7616",
  // businessName: "FUNLIAN SDN.BHD.",
  // businessRegistrationNumber: "136912W",
  // businessCategory: "408",
  // businessAddress1: "Batu Caves",
  // businessAddress2: "Abc 123",
  // businessAddress3: "F 123",
  // businessCity: "Selangor",
  // businessPostcode: "61800",
  // businessState: "Kuala Lumpur",
  // bankId: 6,
  // bankAccountNumber: "1111111111",
  // icImage: null,
  // selfieImage: null

  // fullName: "YUVARAJ",
  // email: "testgsga@gmail.com",
  // agreementCheck: true,
  // cellPhone: "108255179",
  fullName: null,
  email: null,
  agreementCheck: false,
  cellPhone: null,
  question: null,
  answer: null,
  otp: null,
  idNumber: null,
  businessName: null,
  businessRegistrationNumber: null,
  companyRegistrationType: null,
  businessCategory: null,
  subBusinessCategory: null,
  businessAddress1: null,
  businessAddress2: null,
  businessAddress3: null,
  businessCity: null,
  businessPostcode: null,
  businessState: null,
  businessPostOfficeId: null,
  bankId: null,
  bankAccountNumber: null,
  icImage: null,
  selfieImage: null,
  parentCategory: null,
  childCategory: null,
  referralCode: "",
  referralKey: "",
  tenantInfo: {},
  merchantId: null,
  stateList: [],
  postOfficeList: [],
  stateHashMap: null,
  postOfficeHashMap: null,
  leadGenName: null,
  leadGenEmail: null,
  leadGenMsisdn: null,
  leadGenAgreementCheck: false

};

export const formReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.data
      };
    case RESET_FORM_DATA:
      return {
        ...formStates
      };
    case SET_IC_IMAGE:
      return {
        ...state,
        icImage: action.data
      };
    case RESET_IC_IMAGE:
      return {
        ...state,
        icImage: null
      };
    case SET_SELFIE_IMAGE:
      return {
        ...state,
        selfieImage: action.data
      };
    case RESET_SELFIE_IMAGE:
      return {
        ...state,
        selfieImage: null
      };
    default:
      return state;
  }
};
