import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import {
  IS_LOADING,
  NEXT_STEP,
  PREVIOUS_STEP,
  RESET_STEP,
  SET_STEP,
  SET_GENERAL_DATA,
  RESET_GENERAL_DATA,
  SET_GENERAL_DIALOG_DATA,
  SET_CONFIRMATION_DIALOG_DATA,
  SET_SKIP_PAYMENT_ENROLMENT,
  SET_PDF_DIALOG,
  SUPPORT_EMAIL,
  MAINTENANCE_SET_STEP,
  MAINTENANCE_NEXT_STEP,
  ERROR_MESSAGE,
  IS_ERROR,
} from "../types";
import { navigateToBoostCredit } from "../../utils";

export const generalActions = (props) => {
  const showErrorPage = (errorMessage = null) => {
    props.dispatch({ type: IS_LOADING, data: false });
    if (errorMessage) {
      props.dispatch({ type: ERROR_MESSAGE, data: errorMessage });
    }
    props.dispatch({ type: IS_ERROR });
  };

  const setGeneralDialogData = (data) => {
    props.dispatch({ type: SET_GENERAL_DIALOG_DATA, data });
  };

  const checkIfJourneyShouldBeShutdown = () => {
    if (process.env.REACT_APP_JOURNEY_SHUTDOWN_BY_REDIRECT_UPON_LANDING == "1") {
      return true;
    }
    return false;
  };

  const checkIfJourneyShouldSkipKYC = () => {
    const workingProductId = props.state.generalStates.productId;
    const workingReferral = props.state.formStates.referralCode;

    if (process.env.REACT_APP_JOURNEY_SKIP_KYC_FOR_KK == "1" && workingReferral && workingProductId) {
      const kkProductId = [];
      if (process.env.REACT_APP_ENV == "production") {
        kkProductId.push(8963);
      } else {
        kkProductId.push(8049);
      }

      return kkProductId.includes(Number(workingProductId));
    }

    return false;
  };

  const validateProductShouldBeClosed = (productId = null) => {
    const workingProductId = productId || props.state.generalStates.productId;

    const closedProductIds = [];
    if (process.env.REACT_APP_ENV == "production") {
      closedProductIds.push(8963, 8914);
    }
    if (closedProductIds.includes(Number(workingProductId))) {
      navigateToBoostCredit();
      return true;
    }
    return false;
  };

  const checkIfProductIsShortened = (productId = null) => {
    const workingProductId = productId || props.state.generalStates.productId;

    const shortenedProductIds = [];
    if (process.env.REACT_APP_ENV == "production") {
      shortenedProductIds.push(9194);
    } else {
      shortenedProductIds.push(9194);
    }
    return shortenedProductIds.includes(Number(workingProductId));
  };

  const checkIfProductIsPartner = (productId = null) => {
    const workingProductId = productId || props.state.generalStates.productId;

    const nonPartnerProductIds = [];
    if (process.env.REACT_APP_ENV == "production") {
      nonPartnerProductIds.push(8020, 8963, 8965, 9152);
    } else {
      nonPartnerProductIds.push(8020, 8963, 8965, 9152);
      nonPartnerProductIds.push(8049);
    }
    return !nonPartnerProductIds.includes(Number(workingProductId));
  };

  const checkIfProductIsEasyEat = (productId = null) => {
    const workingProductId = productId || props.state.generalStates.productId;

    let easyEatProductId;
    if (process.env.REACT_APP_ENV == "production") {
      easyEatProductId = 9152;
    } else {
      easyEatProductId = 9152;
    }
    return easyEatProductId == Number(workingProductId);
  };

  return {
    showErrorPage,
    setPdfDialog: (data) => {
      props.dispatch({ type: SET_PDF_DIALOG, data: data.show });
    },
    setSkipPaymentEnrolment: (data) => {
      props.dispatch({ type: SET_SKIP_PAYMENT_ENROLMENT, data });
    },
    setGeneralData: (data) => {
      props.dispatch({ type: SET_GENERAL_DATA, data });
    },
    resetGeneralData: () => {
      props.dispatch({ type: RESET_GENERAL_DATA });
    },
    setGeneralDialogData: setGeneralDialogData,
    setConfirmationDialogData: (data) => {
      props.dispatch({ type: SET_CONFIRMATION_DIALOG_DATA, data });
    },
    isLoading: (data) => {
      props.dispatch({ type: IS_LOADING, data });
    },
    nextStep: () => {
      props.dispatch({ type: NEXT_STEP });
    },
    previousStep: () => {
      props.dispatch({ type: PREVIOUS_STEP });
    },
    resetStep: () => {
      props.dispatch({ type: RESET_STEP });
    },
    setStep: (data) => {
      props.dispatch({ type: SET_STEP, data });
    },
    // redirectBoostCreditPasarCapital: () => {
    //   // No idea what this is for, but keep it here as comment first
    //   // if (REACT_APP_TOGGLE_REDIRECT) {
    //   //   window.history.back();
    //   // }
    //   let subUrl = "capital";
    //   const { token } = props.state.generalStates;
    //   if (process.env.REACT_APP_ENV === "development") {
    //     if (token == "6973f103-a3b1-11ea-a1ff-0694c746bcf4") {
    //       subUrl = "capital-plus";
    //     }
    //   } else if (token == "cae7736fd8ff2f1aa6a7b7a31bf7c32a") {
    //     subUrl = "capital-plus";
    //   }
    //   navigateToExternal(constant.getRedirectLink.get(props.state.generalStates.utmSource, props.state.generalStates.utmMedium, subUrl));
    // },
    emailSupport: () => {
      props.dispatch({ type: SUPPORT_EMAIL });
    },
    handleErrorPrompt: (title, description) => {
      setGeneralDialogData({
        show: true,
        dialogTitle: title,
        dialogContent: description
      });
    },
    maintenanceNextStep: () => {
      props.dispatch({ type: MAINTENANCE_NEXT_STEP });
    },
    maintenanceSetStep: (data) => {
      props.dispatch({ type: MAINTENANCE_SET_STEP, data });
    },
    // changeBankStatementMonth: (str) => {
    //     var inputString = str;
    //     var replaced = "";
    //     var parts = inputString.split(/(\w+)/g).map(function (v) {
    //     replaced = v.replace(/([1-9][0-9]+?)/g, props.state.generalStates.bankStatementCountInMonths);
    //         return replaced;
    //     });
    //     return parts.join("");
    // },
    getFbPixelId: (productId = null) => {
      const {
        REACT_APP_OPEN_MARKET_FB_PIXEL,
        REACT_APP_PARTNER_FB_PIXEL
      } = process.env;
      return checkIfProductIsPartner(productId) ? REACT_APP_PARTNER_FB_PIXEL : REACT_APP_OPEN_MARKET_FB_PIXEL;
    },
    initiateTracking: (productId) => {
      const {
        REACT_APP_OPEN_MARKET_GA_ID,
        REACT_APP_OPEN_MARKET_GTM_ID,
        REACT_APP_OPEN_MARKET_FB_PIXEL,
        REACT_APP_OPEN_MARKET_MIX_PANEL_ID,
        REACT_APP_OPEN_MARKET_CLEVERTAP_ID,
        REACT_APP_PARTNER_GA_ID,
        REACT_APP_PARTNER_GTM_ID,
        REACT_APP_PARTNER_FB_PIXEL,
        REACT_APP_PARTNER_MIX_PANEL_ID,
        REACT_APP_PARTNER_CLEVERTAP_ID
      } = process.env;

      const isPartner = checkIfProductIsPartner(productId);
      let REACT_APP_GA_ID = REACT_APP_PARTNER_GA_ID;
      let REACT_APP_GTM_ID = REACT_APP_PARTNER_GTM_ID;
      let REACT_APP_FB_PIXEL = REACT_APP_PARTNER_FB_PIXEL;
      let REACT_APP_MIX_PANEL_ID = REACT_APP_PARTNER_MIX_PANEL_ID;
      let REACT_APP_CLEVERTAP_ID = REACT_APP_PARTNER_CLEVERTAP_ID;

      if (!isPartner) {
        REACT_APP_GA_ID = REACT_APP_OPEN_MARKET_GA_ID;
        REACT_APP_GTM_ID = REACT_APP_OPEN_MARKET_GTM_ID;
        REACT_APP_FB_PIXEL = REACT_APP_OPEN_MARKET_FB_PIXEL;
        REACT_APP_MIX_PANEL_ID = REACT_APP_OPEN_MARKET_MIX_PANEL_ID;
        REACT_APP_CLEVERTAP_ID = REACT_APP_OPEN_MARKET_CLEVERTAP_ID;
      }

      // const tagManagerArgs = {
      //     gtmId: REACT_APP_GTM_ID
      // };
      //
      // TagManager.initialize(tagManagerArgs);
      ReactGA.initialize(REACT_APP_GA_ID,
        { debug: process.env.REACT_APP_ENV !== "production" });

      mixpanel.init(REACT_APP_MIX_PANEL_ID);
      clevertap.init(REACT_APP_CLEVERTAP_ID);
      clevertap.spa = true;
      clevertap.setLogLevel(3);

      const options = {
        autoConfig: true, // set pixel’s autoConfig
        debug: process.env.REACT_APP_ENV !== "production", // enable logs
      };

      if (REACT_APP_FB_PIXEL !== "") {
        ReactPixel.init(REACT_APP_FB_PIXEL, null, options);
      }

      // let updateTrackingStatus = {
      //     trackingInitiated: true,
      // }
      // setTimeout(() => {
      //     props.dispatch({ type: SET_GENERAL_DATA, data: updateTrackingStatus })
      // }, 400)
      props.dispatch({ type: SET_GENERAL_DATA, data: { trackingInitiated: true, productId } });
      props.dispatch({ type: IS_LOADING, data: false });

      if (isPartner) {
        ReactPixel.init("2494819120747706", null, options);
        ReactPixel.pageView(); // For tracking page view
      }
    },
    validateProductShouldBeClosed,
    checkIfJourneyShouldBeShutdown,
    checkIfJourneyShouldSkipKYC,
    checkIfProductIsShortened,
    checkIfProductIsEasyEat,
    testApi: () => {
      const timeToWait = 5;
      console.log(`[testApi] Responding after ${timeToWait} seconds...`);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          showErrorPage();
          // props.dispatch({
          //   type: SET_GENERAL_DATA,
          //   data: {
          //     resultType: 0,
          //     reportLoadingDone: true
          //   }
          // });

          // props.dispatch({
          //   type: SET_GENERAL_DATA,
          //   data: {
          //     kycResult: "REJECTED",
          //     kycPromptRetry: true,
          //     kycReasonCode: "ODE001B",
          //     kycReasonDetail: "SOMETHING_BAD",
          //   }
          // });

          resolve();
        }, timeToWait * 1000);
      });
    }
  };
};
