import { generalStates } from "../reducers/generalReducer";
import { credentialStates } from "../reducers/credentialReducer";
import { formStates } from "../reducers/formReducer";
import { verifyPageLoadReducer as pageStates } from "../reducers/verifyPageLoadReducer";

export const initialState = {
  generalStates,
  credentialStates,
  formStates,
  pageStates
};
