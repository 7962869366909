import MomentTimezone from "moment-timezone";
import moment from "moment";
import Bowser from "bowser";
import publicIp from "public-ip";
import { IpregistryClient, IpregistryOptions, UserAgent } from "@ipregistry/client";

const {
  REACT_APP_IPREGISTRY_API_KEY,
  REACT_APP_NETWORK_SPEED_IMAGE_URL
} = process.env;

export const TIME_ZONE = "Asia/Kuala_Lumpur";
export const DATE_FORMAT = "yyyy-MM-DDTHH:mm:ssZZ";
export const generateRandomID = () => parseInt(Date.now() + ((Math.random() * 100000).toFixed()));

export const STEP_BASIC_DETAIL = "BASIC_DETAIL";
export const STEP_OTP = "OTP";
export const STEP_BUSINESS_DETAIL = "BUSINESS_DETAIL";
export const STEP_KYC = "KYC";
export const STEP_JUMIO_KYC_BEFORE_REDIRECT = "JUMIO_KYC_BEFORE_REDIRECT";
export const STEP_JUMIO_KYC_LOADING = "JUMIO_KYC_LOADING";
export const STEP_JUMIO_KYC_RESULT_PREFIX = "JUMIO_KYC_RESULT_";
export const STEP_LOCATION = "LOCATION";
export const STEP_REPORT_LOADING = "REPORT_LOADING";
export const STEP_CONGRATULATION = "CONGRATULATION";

const ipregistryClient = new IpregistryClient(REACT_APP_IPREGISTRY_API_KEY);

const currentDate = () => MomentTimezone.tz(undefined, TIME_ZONE);
const parseDateFormat = (date, format = DATE_FORMAT) => MomentTimezone.tz(date, TIME_ZONE).format(format);

//= ======== browser & windows information capture methods =========//

// browser info
export const getBrowserInformation = async () => {
  const browserInformation = Bowser.parse(window.navigator.userAgent);
  browserInformation.userAgent = window.navigator.userAgent;
  return browserInformation;
};

// get & set redirected parent url
export const getReferralUrl = (setReferralUrl) => {
  setReferralUrl(document.referrer);
};

// check if cookies enabled in browser
export const getCookieEnabled = () => {
  const { cookieEnabled } = navigator;
  return cookieEnabled;
};

// get Timezone info
export const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

//= ======== browser & windows information capture methods END =========//

//= ======== user behavioural data capture methods =========//

// total up typing duration
export const getTotalTypingDuration = (onTypingEnd, onTypingStart, totalTypingDuration, setTotalTypingDuration) => {
  const ms = moment(onTypingEnd, "yyyy-MM-DD'T'HH:mm:ssZ").diff(moment(onTypingStart, "yyyy-MM-DD'T'HH:mm:ssZ"));
  const duration = moment.duration(ms);
  setTotalTypingDuration(duration._milliseconds + (totalTypingDuration != null ? totalTypingDuration : 0));
};

// format date time
export const formatCurrentDateTime = () => {
  return parseDateFormat(currentDate());
};

// get & set current date time
export const getCurrentDateTime = (setDateTime) => {
  setDateTime(formatCurrentDateTime());
};

// calculate mousespeed
export const calculateMouseMovementTotalSpeed = (mouseSpeed, setTotalMouseSpeed) => {
  let totalSpeed = 0;
  mouseSpeed.forEach((speed) => {
    totalSpeed += speed;
  });
  setTotalMouseSpeed(totalSpeed);
};

// calculate duration
export const calculateDuration = (endTime, startTime) => {
  const difference = moment(endTime, DATE_FORMAT).diff(moment(startTime, DATE_FORMAT));
  const duration = moment.duration(difference);
  return duration;
};

// method to calculate typing speed
const calculateTypingSpeed = (input, duration) => {
  let speed = 0;
  if (input != null && input != "" && duration != null && duration != "") {
    speed = Math.round((input.length / duration) * 60000, 2);
  }
  return speed;
};

// calculate and set typingSpeed
const trackTypingSpeed = (input, elementName, duration, setTypingSpeed) => {
  return new Promise((resolve) => {
    const speed = calculateTypingSpeed(input, duration);
    setTypingSpeed((oldTypingSpeed) => ({
      ...oldTypingSpeed,
      [elementName]: {
        elementId: elementName,
        value: speed,
        unit: "CPM",
      },
    }));
    resolve();
  });
};

// set typing start time when focus on textfield
export const focusTextField = (setOnTypingStart) => {
  setOnTypingStart(formatCurrentDateTime());
};

// set typing end time and track typing speed when blurred text field
export const blurTextField = async (
  e,
  onTypingStart,
  setOnTypingEnd,
  setTypingSpeed
) => {
  const input = e.target.value;
  const elementName = e.target.name;
  const onTypingEnd = formatCurrentDateTime();
  setOnTypingEnd(onTypingEnd);
  const duration = calculateDuration(onTypingEnd, onTypingStart);
  await trackTypingSpeed(
    input,
    elementName,
    duration._milliseconds,
    setTypingSpeed
  );
};

export const checkEnterKey = (e, onTypingStart, setOnTypingEnd, setTypingSpeed) => {
  if (e.key == "Enter") {
    blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed);
  }
};

// set highlited text into variable
export const getSelectedText = (e, selectedText, setSelectedText) => {
  const { value } = e.target;
  const { selectionStart } = e.target;
  const { selectionEnd } = e.target;
  const temp = value.substring(selectionStart, selectionEnd);
  setSelectedText(temp.length ? temp : null);
};

// track user copied text from clipboard
export const trackCopyText = (e, setCopiedText, selectedText) => {
  const selectionText = document.getSelection();
  e.clipboardData.setData("text/plain", selectionText.toString());
  const copiedText = e.clipboardData.getData("text/plain");
  setCopiedText((oldCopiedTexts) => [
    ...oldCopiedTexts,
    {
      copiedText: copiedText || selectedText,
      copiedData: formatCurrentDateTime(),
    },
  ]);
};

// track user cut text from clipboard
export const trackCutText = (e, setCutText, selectedText) => {
  const selectionText = document.getSelection();
  e.clipboardData.setData("text/plain", selectionText.toString());
  const cutText = e.clipboardData.getData("text/plain");
  setCutText((oldCutTexts) => [
    ...oldCutTexts,
    {
      cutText: cutText || selectedText,
      cutDate: formatCurrentDateTime(),
    },
  ]);
};

// track user pasted text from interacted textFields
export const trackPastedText = (e, setPastedText) => {
  let inputValue = "";
  let elementId = "";
  if (e.target.value != null) {
    inputValue = e.target.value;
    elementId = e.target.name;
  }
  const pastedText = e.clipboardData.getData("text/plain");
  if (pastedText) {
    setPastedText((oldPastedTexts) => [
      ...oldPastedTexts,
      {
        elementId,
        previousText: inputValue,
        pastedText,
        pastedDate: formatCurrentDateTime(),
      },
    ]);
  }
};

// track user input on text field
export const handleOnKeyInput = (e, valueChange, setValueChange) => {
  let elementId = "";
  let inputValue = "";
  if (e.target.value != null) {
    elementId = e.target.name;
    inputValue = e.target.value;
  }
  const currentVal = valueChange[elementId];
  if (currentVal) {
    if (inputValue.length < currentVal.currentValue.length) {
      currentVal.previousValues.push({
        value: currentVal.currentValue,
        deletionDate: formatCurrentDateTime(),
        fullyDeleted: inputValue.length == 0,
      });
      currentVal.backspaceCount += 1;
    }

    currentVal.currentValue = inputValue;
    currentVal.updateDate = formatCurrentDateTime();
    setValueChange((prevState) => ({
      ...prevState,
      [elementId]: currentVal,
    }));
    return;
  }
  setValueChange((prevState) => ({
    ...prevState,
    [elementId]: {
      elementId,
      currentValue: inputValue,
      backspaceCount: 0,
      updateDate: formatCurrentDateTime(),
      previousValues: [],
    },
  }));
};

//= ======== user behavioural data capture methods END =========//

//= ======== social media login data capture methods =========//
export async function getSocialMediaLoginInformation() {
  const socialMediaLogins = [];
  const fbLoginInformation = await getFacebookLoginInformation();
  if (fbLoginInformation !== undefined) {
    socialMediaLogins.push(fbLoginInformation);
  }

  const googleLoginInformation = await getGoogleLoginInformation();
  if (googleLoginInformation !== undefined) {
    socialMediaLogins.push(googleLoginInformation);
  }

  return socialMediaLogins;
}

// Google
export function getGoogleLoginInformation() {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = "https://accounts.google.com/CheckCookie?continue=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&followup=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&chtml=LoginDoneHtml&checkedDomains=youtube&checkConnection=youtube%3A291%3A1";
    img.onload = function () {
      resolve({
        name: "google",
        loggedIn: true
      });
    };

    img.onerror = function () {
      resolve({
        name: "google",
        loggedIn: false
      });
    };
  }).catch((error) => {
    console.error(error);
  });
}

// Facebook
export function getFacebookLoginInformation() {
  return new Promise((resolve, reject) => {
    try {
      window.FB.getLoginStatus((response) => {
        const fbLoginResponse = {
          name: "facebook",
          loggedIn: response.status !== "unknown"
        };
        resolve(fbLoginResponse);
      }, true);
    } catch (e) {
      reject(e);
    }
  }).catch((error) => {
    console.error(error);
  });
}

//= ======== social media login data capture methods END =========//

//= ======== network information capture methods =========//

// calculate download speed
export async function calculateNetworkDownlinkSpeed() {
  return new Promise((resolve) => {
    const startTime = Date.now();
    const fileSizeInBytes = 5300000; // 5.3mb
    const imageUrl = `${REACT_APP_NETWORK_SPEED_IMAGE_URL}?${generateRandomID()}`; // to prevent browser from getting cached image
    const img = new Image();
    img.src = imageUrl;

    img.onload = function () {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000;
      // Convert bytes into bits by multiplying with 8
      const bitsLoaded = fileSizeInBytes * 8;
      const bps = (bitsLoaded / duration).toFixed(2);
      const kbps = (bps / 1000).toFixed(2);
      const mbps = (kbps / 1000).toFixed(2);
      resolve({
        "downlinkSpeed": mbps,
        "unit": "Mbps"
      });
    };

    img.onerror = function () {
      resolve();
    };
  }).catch((error) => {
    console.error(error);
  });
}

// retrieve network info from ipregistry
export async function getNetworkInformation(userAgent) {
  let ipv4;
  try {
    ipv4 = await publicIp.v4({
      onlyHttps: true,
      fallbackUrls: [
        "https://ipecho.net/plain",
        "https://l2.io/ip",
        "https://api.kwelo.com/v1/network/ip-address/my",
        "https://myexternalip.com/raw",
      ],
    });
  } catch (e) {
    console.error(e);
    ipv4 = null;
  }

  const isBot = UserAgent.isBot(userAgent);
  const downlinkSpeedInformation = await calculateNetworkDownlinkSpeed();

  const networkInformation = {
    "ipAddress": ipv4,
    "isBot": isBot,
    "speed": downlinkSpeedInformation
  };

  if (!isBot) {
    try {
      const response = await ipregistryClient.originLookup(IpregistryOptions.filter("connection,security"));
      return { ...networkInformation, ...response.data };
    } catch (error) {
      console.error(error);
    }
  }

  return networkInformation;
}

//= ======== network information capture methods END =========//
