// DOCS - resultType
// evens - BSA not required
// 0 = "CONGRATS_PAGE_GOLD" FRESH USER
// 2 = "EXISTING_CUSTOMER_CONGRATS_PAGE_GOLD" ALT CONGRATS PAGE
// 4 = "PARTNER_CONGRATS_PAGE_GOLD"
// 6 = "APPROVED_IN_PRINCIPAL_CONGRATS_PAGE_GOLD"

// odds - BSA required
// 1 = "CONGRATS_PAGE_BLUE_BANK_STATEMENT_REQUIRED"
// 3 = "EXISTING_CUSTOMER_CONGRATS_PAGE_BLUE_BANK_STATEMENT_REQUIRED"
// 5 = "PARTNER_CONGRATS_PAGE_GOLD_BANK_STATEMENT_REQUIRED"
// 7 = "APPROVED_IN_PRINCIPAL_CONGRATS_PAGE_BLUE_BANK_STATEMENT_REQUIRED"

const CongratsResultTypeEnum = {
  NONE: -1,
  FRESH_CUSTOMER: 0,
  FRESH_CUSTOMER_BSA: 1,
  EXISTING_CUSTOMER: 2,
  EXISTING_CUSTOMER_BSA: 3,
  PARTNER: 4,
  PARTNER_BSA: 5,
  APPROVED_IN_PRINCIPAL: 6,
  APPROVED_IN_PRINCIPAL_BSA: 7,
};

export default CongratsResultTypeEnum;
