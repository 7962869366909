import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { StoreContext } from "../../store/context";
import correctIc from "../../assets/images/encore/icCaptureTutorial/correctIc.png";
import icBlur from "../../assets/images/encore/icCaptureTutorial/icBlur.png";
import icGlare from "../../assets/images/encore/icCaptureTutorial/icGlare.png";
import LanguageToggleButton from "../LanguageToggleButton";

import "./IcCaptureTutorial.scss";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IcCaptureTutorial = () => {
  const { t } = useTranslation();
  const carouselRef = useRef();
  const [current, setCurrent] = React.useState(0);
  const { state, actions } = useContext(StoreContext);
  const { icTutorialCapture, url } = state.generalStates;
  const {
    // closeIcCaptureTutorial,
    // modalDialogOpen,
    // setIcCameraActive,
    setGeneralData,
  } = actions.generalActions;
  const { resetIcImage } = actions.formActions;

  // function handleClick() {
  //   window.location.assign(url);
  // }

  function handleClose() {
    setGeneralData({ icTutorialCapture: false });
  }

  const items = [
    {
      title: "encore.tutorialModel.nric.step_1.label",
      subtitle: "encore.tutorialModel.nric.step_1.content",
      image: correctIc,
    },
    {
      title: "encore.tutorialModel.nric.step_2.label",
      subtitle: "encore.tutorialModel.nric.step_2.content",
      image: icGlare,
    },
    {
      title: "encore.tutorialModel.nric.step_3.label",
      subtitle: "encore.tutorialModel.nric.step_3.content",
      image: icBlur,
    },
  ];

  function getItem() {
    const layout = [];

    items.forEach((item) => {
      layout.push(
        <div key={item}>
          {/* <Typography variant="h5" gutterBottom className="title">
            {t(item.title)}
          </Typography> */}
          <Typography
            variant="subtitle1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(item.title),
            }}
          />
          <div className="logo-container-ic-tutorial">
            <CardMedia
              component="img"
              image={item.image}
              className="logo-ic-tutorial"
            />
          </div>
        </div>
      );
    });
    return layout;
  }

  function getCarousel() {
    return (
      <Carousel
        ref={carouselRef}
        // nextIcon={ArrowForwardIosIcon}
        // prevIcon={ArrowBackIosIcon}
        navButtonsAlwaysInvisible
        animation="fade"
        autoPlay={false}
      >
        {getItem()}
      </Carousel>
    );
  }

  return (
    <div>
      <Dialog
        open={icTutorialCapture}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        // onClose={handleClose}
        className="dialog-ic-tutorial"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="icCaptureTutorialModal">
          <div className="icCaptureTutorialModal__header">
            <div className="btn-close">
              <CloseIcon onClick={handleClose} />
            </div>
            <div>
              <LanguageToggleButton />
            </div>
          </div>
        </div>
        <div className="center-text icCaptureAlignmentTutorial">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2}>
              <ArrowBackIosIcon
                onClick={async () => {
                  await carouselRef.current.prev();
                  setCurrent(carouselRef.current.state.active);
                }}
                className={
                  current < 1 ? "arrowIconStyleHidden" : "arrowIconStyle"
                }
                fontSize="large"
              />
            </Grid>
            <Grid item xs={8}>
              {getCarousel()}
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIosIcon
                onClick={async () => {
                  await carouselRef.current.next();
                  setCurrent(carouselRef.current.state.active);
                }}
                className={
                  current === items.length - 1
                    ? "arrowIconStyleHidden"
                    : "arrowIconStyle"
                }
                fontSize="large"
              />
            </Grid>
          </Grid>
          <div className="button_holder">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="buttonStyle"
              onClick={async () => {
                if (current === items.length - 1) {
                  resetIcImage();
                  setGeneralData({ icCameraActive: false, icTutorialCapture: false });
                  handleClose();
                } else {
                  await carouselRef.current.next();
                  setCurrent(carouselRef.current.state.active);
                }
              }}
            >
              {current === items.length - 1
                ? t("encore.tutorialModel.button.gotIt")
                : t("encore.tutorialModel.button.label")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default IcCaptureTutorial;
