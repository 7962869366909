import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Template from "../../components/Template";
import { StoreContext } from "../../store/context";

import "./Contact.scss";

const {
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_SUPPORT_EMAIL,
} = process.env;

const Contact = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { state, actions } = useContext(StoreContext);
  const { productPhoneNumber, productEmail } = state.generalStates;
  const { setGeneralData } = actions.generalActions;

  const handleBack = () => {
    if (location.key) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    checkPhoneNumberAndEmailExistence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkPhoneNumberAndEmailExistence() {
    if (!productPhoneNumber) {
      setGeneralData({ productPhoneNumber: REACT_APP_SUPPORT_PHONE });
    }

    if (!productEmail) {
      setGeneralData({ productEmail: REACT_APP_SUPPORT_EMAIL });
    }
  }

  return (
    <Template>
      <div className="page__contact">
        <div className="title">{t("contacts.title")}</div>
        <div className="desc">{t("contacts.subTitle")}</div>
        <div className="contact-info">
          <PhoneIcon color="primary" />
          {" "}
          <span>{productPhoneNumber}</span>
        </div>
        <div className="contact-info">
          <EmailIcon color="primary" />
          {" "}
          <span>{productEmail}</span>
        </div>
        <div className="page__next__button">
          <Button className="button" color="secondary" variant="outlined" onClick={handleBack}>
            {location.key ? t("common.back") : t("common.returnHome")}
          </Button>
        </div>
      </div>
    </Template>
  );
};

export default Contact;
