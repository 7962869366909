import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/context";
// import { getTermsAndConditionsUrl } from "../../utils";

import "./Footer.scss";

const {
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_SUPPORT_EMAIL,
} = process.env;

const Footer = (props) => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { productPhoneNumber, productEmail } = state.generalStates;
  // const tncUrl = getTermsAndConditionsUrl(distributor?.id);
  // const isLanding = useLocation().pathname === "/";
  // const isContact = useLocation().pathname === "/contacts";
  // const { product } = state?.generalStates;
  const { setGeneralData } = actions.generalActions;

  useEffect(() => {
    // if (product.faqURL && product.termsURL){
    //   sessionStorage.setItem("faqURL", product.faqURL);
    //   sessionStorage.setItem("termsURL", product.termsURL);
    // }
    checkPhoneNumberAndEmailExistence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkPhoneNumberAndEmailExistence() {
    if (!productPhoneNumber) {
      setGeneralData({ productPhoneNumber: REACT_APP_SUPPORT_PHONE });
    }

    if (!productEmail) {
      setGeneralData({ productEmail: REACT_APP_SUPPORT_EMAIL });
    }
  }

  return (
    <>
      <div className="footer3">
        <div className="help-link">
          <Link to="/contacts">{t("footer.contacts")}</Link>
          {/* {!isLanding && (<a target="_blank" rel="noopener noreferrer" href={product.faqURL ? product.faqURL : sessionStorage.getItem("faqURL")}>{t("footer.faq")}</a>)}
          {!isLanding && (<a target="_blank" rel="noopener noreferrer" href={product.termsURL ? product.termsURL : sessionStorage.getItem("termsURL")}>{t("footer.terms")}</a>)} */}

          {/* <a target="_blank" rel="noopener noreferrer" href="https://aspirasi-assets.s3-ap-southeast-1.amazonaws.com/aspirasi-capital-lift/TOS_Capital_Standard.pdf">{t("footer.faq")}</a> */}
          {/* <a target="_blank" rel="noopener noreferrer" href="https://aspirasi-assets.s3-ap-southeast-1.amazonaws.com/aspirasi-capital-lift/TOS_Capital_Standard.pdf">{t("footer.terms")}</a> */}
          {/* <a target="_blank" rel="noopener noreferrer" href="https://aspirasi-assets.s3-ap-southeast-1.amazonaws.com/aspirasi-capital-lift/TOS_Capital_Standard.pdf">{t("footer.termOfService")}</a> */}
          <a target="_blank" rel="noopener noreferrer" href="https://aspirasi-assets.s3.ap-southeast-1.amazonaws.com/boost-credit/PRN_Boost_Credit.pdf">{t("footer.policy")}</a>
        </div>
        <div className="contact-info">
          <a href={`tel:${productPhoneNumber}`}>
            <PhoneIcon />
            {" "}
            <span>{productPhoneNumber}</span>
          </a>
          <a href={`mailto:${productEmail}`}>
            <EmailIcon />
            {" "}
            <span>{productEmail}</span>
          </a>
        </div>
      </div>
    </>
  );
};
export default Footer;
