import axios from "axios";

const {
  REACT_APP_LAZADA_API,
  REACT_APP_ASPIRASIUM_API,
} = process.env;

export default {
  funnelService: {
    get: (param) => getRequest(`${REACT_APP_LAZADA_API}${param}`),
    post: (param, payload) => postRequest(`${REACT_APP_LAZADA_API}${param}`, payload),
  },
  aspirasiumService: {
    get: (param) => getRequest(`${REACT_APP_ASPIRASIUM_API}${param}`),
    post: (param, payload) => postRequest(`${REACT_APP_ASPIRASIUM_API}${param}`, payload),
    put: (param, payload) => putRequest(`${REACT_APP_ASPIRASIUM_API}${param}`, payload),
  }

};

function getRequest(urlParam) {
  return new Promise((resolve, reject) => {
    return axios
      .get(urlParam)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

function postRequest(urlParam, payload, withHeader = true) {
  return new Promise((resolve, reject) => {
    return axios
      .post(urlParam, payload, withHeader ? {
        timeout: 60 * 2 * 1000,
        headers: {
          Authorization: sessionStorage.getItem("userToken"),
        },
      } : {})
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

function putRequest(urlParam, payload, withHeader = true) {
  return new Promise((resolve, reject) => {
    return axios
      .put(urlParam, payload, withHeader ? {
        timeout: 60 * 2 * 1000,
        headers: {
          Authorization: sessionStorage.getItem("userToken"),
        },
      } : {})
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
