import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import routes from "./router";
import { StoreContext } from "./store/context";
import ProtectedRoute from "./router/ProtectedRoute";
import WithTracker from "./components/WithTracker";
import { buildMultiLingualErrorMsg, generateRandomID, getLanguage, navigateToBoostCredit, navigateToHome } from "./utils";
import common_en from "./translations/en/common.json";
import jumioErrors_en from "./translations/en/jumioErrors.json";
import common_ms from "./translations/ms/common.json";
import jumioErrors_ms from "./translations/ms/jumioErrors.json";

import "./App.scss";

i18n
  .use(initReactI18next)
  .init({
    lng: getLanguage(),
    resources: {
      en: {
        translation: {
          ...common_en,
          ...jumioErrors_en
        }
      },
      ms: {
        translation: {
          ...common_ms,
          ...jumioErrors_ms
        }
      },
    },
    supportedLngs: ["en", "ms"],
    fallbackLng: "ms",
    nonExplicitSupportedLngs: true,
    cleanCode: true,
    interpolation: {
      escapeValue: false
    }
  });

const theme = createTheme({
  typography: {
    fontFamily: "'rawline', 'Open Sans', sans-serif",
  },
  palette: {
    primary: {
      light: "#f8a6b4", // $color-primary-light
      main: "#EA0029", // $color-primary
      dark: "#bd0a0a", // $color-primary-dark
      contrastText: "#ffffff", // $color-white
    },
    secondary: {
      light: "#8a8a8c", // $color-secondary-light
      main: "#58595b", // $color-secondary
      dark: "#231f20", // $color-secondary-dark
      contrastText: "#ffffff", // $color-white
    },
  },
});

const alertOptions = {
  offset: "25px",
  timeout: 3000,
  transition: transitions.SCALE,
};

function App() {
  const { state, actions } = useContext(StoreContext);

  const { trackingInitiated, isMaintenance } = state.generalStates;
  const { initiateTracking, setGeneralData, showErrorPage, validateProductShouldBeClosed, checkIfJourneyShouldBeShutdown, setGeneralDialogData } = actions.generalActions;
  const { checkServerAlive, setKycLoading } = actions.credentialActions;
  const { setFormData } = actions.formActions;

  const [route, setRoute] = useState([]);

  const { search } = window.location;
  const params = new URLSearchParams(search);

  useEffect(() => {
    initiateRendering();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateRendering = () => {
    // Check if Journey should Shut Down
    if (checkIfJourneyShouldBeShutdown()) {
      navigateToBoostCredit();
      return;
    }

    // Session Key handling (Resume journey)
    const sessionKey = params.get("sessionKey");
    if (sessionKey) {
      setGeneralData({ sessionKey });
    }

    // Token handling
    sessionStorage.removeItem("token");
    const token = params.get("token");
    if (token) {
      setGeneralData({ token });
      sessionStorage.setItem("token", token);
      if (process.env.REACT_APP_ENV === "development") {
        if (token == "6973f103-a3b1-11ea-a1ff-0694c746bcf4") {
          setGeneralData({ gaCategory: "Capital Plus Prejourney" });
        }
      } else if (["cae7736fd8ff2f1aa6a7b7a31bf7c32a", "b3c6453d-e54e-11ec-8897-0a5843143288"].includes(token)) {
        setGeneralData({ gaCategory: "Capital Plus Prejourney" });
      }
    } else {
      navigateToHome();
    }

    // Referral code
    sessionStorage.removeItem("referral");
    sessionStorage.removeItem("referralKey");
    const referralCode = params.get("referral"); // old uppercase 6 character
    const referralKey = params.get("referralKey"); // new random 10 characters
    if (referralCode && referralKey) {
      setFormData({ referralCode, referralKey });
      sessionStorage.setItem("referral", referralCode);
      sessionStorage.setItem("referralKey", referralKey);
    } else if (referralKey) {
      // referral must be provided
      setGeneralDialogData({
        show: true,
        dialogTitle: "common.sorry",
        dialogContent: "landing.invalid_url",
        showButton: false
      });
    }

    // UTM source
    const utmSource = params.get("utm_source");
    const utmMedium = params.get("utm_medium");
    if (utmSource && utmMedium) {
      setGeneralData({ utmSource, utmMedium });
    }

    // Enable merchant ID field
    if (params.get("mid") == "1") {
      setGeneralData({ enableMerchantIdField: true });
    }

    // Generate transaction ID
    const transId = generateRandomID();
    setGeneralData({ transId });

    // ReactPixel.trackSingle(REACT_APP_FB_PIXEL, "ViewContent", { content_name: "Welcome" });
    // ReactPixel.trackSingle("2494819120747706", "ViewContent", { content_name: "Welcome" });

    // const dev = params.get("dev");
    // if (process.env.REACT_APP_ENV === "development" && dev === "1") {
    //   props.dispatch({ type: SET_GENERAL_DATA, data: { dev: true } });
    // }

    // Check Server Alive API + Prerequisite API
    checkServerAlive(token, (productId) => {
      // Check if product is disabled
      if (validateProductShouldBeClosed(productId)) {
        return; // stop code because already redirected away
      }
      // Init Tracking/Analytics
      initiateTracking(productId);
      // After prerequisite API
      postPrerequisite();
    });
  };

  const handleInvalidUrl = () => {
    // Show error invalid link
    const msgForEndUser = buildMultiLingualErrorMsg("landing.invalid_url");
    showErrorPage(msgForEndUser);
  };

  const postPrerequisite = () => {
    const sessionKey = params.get("sessionKey");

    // DEV: skip to KYC
    // const testJumio = params.get("testJumio");
    // if (testJumio == "1" && sessionKey) {
    //   setKycBefore();
    //   return;
    // }

    // Handle redirect from Jumio, show loading page
    const fromJumio = params.get("fromJumio");
    if (fromJumio == "1") {
      setKycLoading();
      return;
    }

    // Handle redirect from LMS email, show OTP page
    const resumeJourney = params.get("resumeJourney");
    if (resumeJourney == "1") {
      if (sessionKey) {
        setGeneralData({ step: 2, isResumeJourney: true });
      } else {
        handleInvalidUrl();
      }
    }
  };

  useEffect(() => {
    if (trackingInitiated || isMaintenance) {
      setRoute(routes.map((routeItem, index) => (
        routeItem.isProtected
          ? (
            <ProtectedRoute
              path={routeItem.path}
              exact={routeItem.exact}
              component={WithTracker(routeItem.component)}
              key={index}
            />
          )
          : (
            <Route
              path={routeItem.path}
              exact={routeItem.exact}
              component={WithTracker(routeItem.component)}
              key={index}
            />
          )
      )));
    }
  }, [trackingInitiated, isMaintenance]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          {...alertOptions}
        >
          <Router basename={process.env.PUBLIC_URL || ""}>
            {/* {renderComponents} */}
            {/* {renderContent()} */}
            <Switch>{route}</Switch>
          </Router>
        </AlertProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
