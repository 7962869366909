import * as pages from "../pages";

const routes = [{
  path: "/",
  exact: true,
  component: pages.Encore,
},
// {
//     path: "/remix",
//     exact: true,
//     component: pages.Remix,
// },
{
  path: "/contacts",
  exact: true,
  component: pages.Contact,
},
{
  path: "*",
  component: pages.NotFound,
},
];

export default routes;
