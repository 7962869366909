import React from "react";
import { Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export default function CustomSelect(props) {
  return (
    <Select
      {...props}
      IconComponent={KeyboardArrowDownIcon}
    />
  );
}
