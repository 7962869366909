import i18n from "i18next";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import ReactPixel from "react-facebook-pixel";
import api from "../../utils/api";
import {
  getWebStatus,
  getLanguage,
  navigateToHome,
  removeLeadingCountryCode,
  getStandardizedErrorMsgs,
  buildMultiLingualErrorMsg,
  navigateToExternal,
  buildUrlQueryParam,
  getMwcUrl,
} from "../../utils";
import {
  RESET_CREDENTIAL_DATA,
  IS_LOADING,
  SET_GENERAL_DATA,
  NEXT_STEP,
  IS_ERROR,
  ERROR_MESSAGE,
  SET_FORM_DATA,
  IC_IMAGE_RETRIES,
  SELFIE_IMAGE_RETRIES,
  SET_GENERAL_DIALOG_DATA
} from "../types";
import KycStatusEnum, { isKycPotentialStuckIfProceedToResultPage } from "../../constants/KycStatusEnum";
import RamciStatusEnum from "../../constants/RamciStatusEnum";
import KycStepEnum from "../../constants/KycStepEnum";
import CongratsResultTypeEnum from "../../constants/CongratsResultTypeEnum";
import constant, { BSA_JOURNEY_STATUS_FORCED, TENANT_ID } from "../../utils/constants";

export const credentialActions = (props) => {
  const { gaCategory } = props.state.generalStates;

  const showErrorPage = (errorMessage = null) => {
    props.dispatch({ type: IS_LOADING, data: false });
    if (errorMessage) {
      props.dispatch({ type: ERROR_MESSAGE, data: errorMessage });
    }
    props.dispatch({ type: IS_ERROR });
  };

  const setGeneralDialogData = (data) => {
    props.dispatch({ type: SET_GENERAL_DIALOG_DATA, data });
  };

  const verifyPhoneNo = (params) => {
    return api
      .funnelService
      .get("/kyc/verifyPhoneNo"
        + `?phoneNo=60${params.cellPhone}`
        + `&tenantCode=${TENANT_ID}`);
  };

  const verifyEmail = (params) => {
    props.dispatch({ type: IS_LOADING, data: true });
    return api
      .funnelService
      .get("/kyc/verifyEmail"
        + `?email=${params.email}`
        + `&tenantCode=${TENANT_ID}`);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const checkingRamci = (getFbPixelId) => {
    props.dispatch({ type: SET_GENERAL_DATA, data: { step: 6 } });
    props.dispatch({ type: ERROR_MESSAGE, data: null });
    const payload = {
      sessionKey: props.state.generalStates.sessionKey
    };

    return api
      .funnelService
      .post("/kyc/checkingRamci", payload)
      .then((res) => {
        // DOCS - HTTP status map to CongratsResultTypeEnum
        // 200 - OK: 1/0 (or 3/2)     = new/existing user
        // 202 - ACCEPTED: 7/6        = approved in principal
        // 501 - NOT_IMPLEMENTED: 5/4 = partner
        // 303 - SEE_OTHER: 7/6       = approved in principal
        // 406 - NOT_ACCEPTABLE: Fail
        // 503 - SERVICE_UNAVAILABLE: Fail
        // other - Fail

        if (res.status === 200) {
          console.log("Status is success");
          ReactGA.event({
            category: gaCategory,
            action: "End - Credit Checking - Success",
            value: props.state.formStates.email
          });
          mixpanel.track(gaCategory, { "action": "End - Credit Checking - Success", "value": props.state.formStates.email });
          clevertap.event.push("act_cp_credit_check", {
            "Status": "Success",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });

          ReactPixel.trackSingle(getFbPixelId(), "Lead", { content_name: "Complete Ramci Checking" });
          ReactPixel.trackSingle("2494819120747706", "Lead", { content_name: "Complete Ramci Checking" });

          const reportResponse = {
            eligibilityAmount: res.data.amount,
            productDetail: {
              name: res.data.productName,
              featureUrl: res.data.featureUrl,
              featureText: res.data.featureText,
              mwcUrl: buildMwcUrl(res.data.landingURL, res.data.code),
              remixUrl: `${res.data.remixUrl}&language=${getLanguage()}`,
            },
            creditScoringDetail: {
              monthlyProfitRate: res.data.actualInterest,
              tenure: res.data.tenure
            },
            downSell: res.data.downsell
          };

          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              resultType: (props.state.generalStates.bankStatementRequired || res.data.amount == 0) ? CongratsResultTypeEnum.FRESH_CUSTOMER_BSA : CongratsResultTypeEnum.FRESH_CUSTOMER,
              reportResponse: reportResponse,
              reportLoadingDone: true
            }
          });
          return;
        }
        if (res.status === 202) {
          // show approved in pricipal
          console.log("Status is success");
          ReactGA.event({
            category: gaCategory,
            action: "End - Credit Checking - Success (No Limit)",
            value: props.state.formStates.email
          });
          mixpanel.track(gaCategory, { "action": "End - Credit Checking - Success (No Limit)", "value": props.state.formStates.email });
          clevertap.event.push("act_cp_credit_check", {
            "Status": "Success (No Limit)",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });

          ReactPixel.trackSingle(getFbPixelId(), "Lead", { content_name: "Complete Ramci Checking" });
          ReactPixel.trackSingle("2494819120747706", "Lead", { content_name: "Complete Ramci Checking" });

          const reportResponse = {
            eligibilityAmount: 0,
            productDetail: {
              name: "",
              featureUrl: "",
              featureText: {
                "ms": "",
                "en": ""
              },
              mwcUrl: buildMwcUrl(res.data.landingURL, res.data.code),
              remixUrl: `${res.data.remixUrl}&language=${getLanguage()}`,
            },
            creditScoringDetail: {
              monthlyProfitRate: 0.0,
              tenure: 0
            },
            downSell: false
          };

          props.dispatch({ type: SET_GENERAL_DATA, data: { previousStep: props.state.generalStates.step } });
          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              resultType: (props.state.generalStates.bankStatementRequired || res.data.amount == 0) ? CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA : CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL,
              reportResponse: reportResponse,
              reportLoadingDone: true,
              existingUser: true,
              step: 7
            }
          });
          return;
        }

        // For errors that are not 4xx and 5xx
        const { msgForAnalytics, msgForEndUser } = getStandardizedErrorMsgs(res.data);

        ReactGA.event({
          category: gaCategory,
          action: "Credit Checking - Fail"
        });
        mixpanel.track(gaCategory, { "action": "Credit Checking - Fail" });
        clevertap.event.push("act_cp_credit_check", {
          "Status": "Fail",
          "TypesOfRejection": msgForAnalytics,
          "ReferralCode": props.state.formStates.referralCode,
          "FullURL": window.location.href,
        });
        showErrorPage(msgForEndUser);
      })
      .catch((error) => {
        props.dispatch({ type: IS_LOADING, data: false });

        const { msgForAnalytics, msgForEndUser } = getStandardizedErrorMsgs(error.data);

        if (error.status === 501) {
          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              resultType: props.state.generalStates.bankStatementRequired ? CongratsResultTypeEnum.PARTNER_BSA : CongratsResultTypeEnum.PARTNER,
              reportLoadingDone: true
            }
          });
          ReactGA.event({
            category: gaCategory,
            action: "Checking - Success (Partners)"
          });
          mixpanel.track(gaCategory, { "action": "Checking - Success (Partners)" });
          clevertap.event.push("act_cp_credit_check", {
            "Status": "Success (Partners)",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });
        } else if (error.status === 303) {
          const reportResponse = {
            eligibilityAmount: 0,
            productDetail: {
              name: "",
              featureUrl: "",
              featureText: {
                "ms": "",
                "en": ""
              },
              mwcUrl: buildMwcUrl(error.data.landingURL, error.data.code),
              remixUrl: `${error.data.remixUrl}&language=${getLanguage()}`,
            },
            creditScoringDetail: {
              monthlyProfitRate: 0.0,
              tenure: 0
            },
            downSell: false
          };
          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              // May 15 2023: Changed from 1 to 7 after reading our codes. If 1 is the correct value for status 303 (limit 0), please change back to 1
              // resultType: props.state.generalStates.bankStatementRequired ? 1 : 6,
              resultType: props.state.generalStates.bankStatementRequired ? CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA : CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL,
              reportResponse: reportResponse,
              reportLoadingDone: true
            }
          });

          ReactGA.event({
            category: gaCategory,
            action: "Checking - Success (No Limit)"
          });
          mixpanel.track(gaCategory, { "action": "Checking - Success (No Limit)" });
          clevertap.event.push("act_cp_credit_check", {
            "Status": "Success (No Limit)",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });
        } else {
          ReactGA.event({
            category: gaCategory,
            action: "Credit Checking - Fail",
            label: msgForAnalytics
          });
          mixpanel.track(gaCategory, { "action": "Credit Checking - Fail", "label": msgForAnalytics });
          clevertap.event.push("act_cp_credit_check", {
            "Status": "Fail",
            "TypesOfRejection": msgForAnalytics,
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });
          showErrorPage(msgForEndUser);
        }
      });
  };

  const getPrerequisite = async (token = null, isMaintenance = false, callbackWithProductId, ...args) => {
    const urlQuery = `/kyc/prerequisite?token=${token || ""}`;
    return api
      .funnelService
      .get(urlQuery)
      .then(async (res) => {
        const response = await getWebStatus();
        // DEV
        // res.data.bankStatementRequired = true;
        // res.data.bsaRequirementStatus = BSA_JOURNEY_STATUS_FORCED;
        props.dispatch({
          type: SET_GENERAL_DATA,
          data: {
            webStatus: response,
            bankStatementRequired: res.data.bankStatementRequired || res.data.bsaRequirementStatus == BSA_JOURNEY_STATUS_FORCED,
            bankStatementCountInMonths: res.data.bankStatementCountInMonths,
            bsaRequirementStatus: res.data.bsaRequirementStatus,
            browserName: res.data.browser?.name,
            browserLogoUrl: res.data.browser?.logoUrl,
            productLogoUrl: res.data.product?.logoUrl,
            productLogoHeight: res.data.product?.logoSize?.desktop?.height,
            productMobileLogoHeight: res.data.product?.logoSize?.mobile?.height,
            productPhoneNumber: res.data.contact?.phoneNumber,
            productEmail: res.data.contact?.email,
          }
        });
        if (isMaintenance) {
          props.dispatch({ type: IS_LOADING, data: false });
          callbackWithProductId(res.data.product.productId, ...args);
          return;
        }

        props.dispatch({
          type: SET_GENERAL_DATA,
          data: {
            step: 0,
          }
        });
        callbackWithProductId(res.data.product.productId, ...args);
      })
      .catch(async (error) => {
        props.dispatch({ type: IS_LOADING, data: false });
        if (props.state.generalStates.isMaintenance) {
          props.dispatch({ type: SET_GENERAL_DATA, data: { maintenanceStep: 102 } });
        }
        const dialogTitle = "common.opps";
        const errMessage = "common.userInputError";
        // if (error.data?.error?.code === "PRE-0003") {
        //   dialogTitle = "common.opps";
        //   errMessage = "common.userInputError";
        // }
        setGeneralDialogData({
          show: true,
          dialogTitle,
          dialogContent: errMessage,
          callback: () => {
            navigateToHome();
          }
        });
      });
  };

  const initialKycStepStates = {
    step: 4,
    kycResult: null,
    kycPromptRetry: null,
    kycReasonCode: null,
    kycReasonDetail: null,
    kycStep: KycStepEnum.PRE_JUMIO
  };

  const setKycBefore = () => {
    props.dispatch({
      type: SET_GENERAL_DATA,
      data: {
        ...initialKycStepStates,
      }
    });
  };

  const retryKycBefore = () => {
    props.dispatch({
      type: SET_GENERAL_DATA,
      data: {
        kycStep: KycStepEnum.PRE_JUMIO
      }
    });
  };

  const setKycLoading = () => {
    props.dispatch({
      type: SET_GENERAL_DATA,
      data: {
        ...initialKycStepStates,
        kycStep: KycStepEnum.LOADING
      }
    });
  };

  const buildMwcUrl = (landingUrl = "", code = "") => {
    const { utmSource, utmMedium } = props.state.generalStates;
    const { referralCode } = props.state.formStates;

    return getMwcUrl(landingUrl, code, utmSource, utmMedium, referralCode);
  };

  return {
    buildMwcUrl,
    checkServerAlive: async (token, callbackWithProductId) => {
      props.dispatch({ type: IS_LOADING, data: true });
      return api
        .funnelService
        .get("/status/alive")
        .then(async (res) => {
          getPrerequisite(token, false, callbackWithProductId);
        })
        .catch((error) => {
          if (error?.data?.error?.code === "PRE-0004") {
            props.dispatch({ type: IS_LOADING, data: false });
            props.dispatch({ type: SET_GENERAL_DATA, data: { isMaintenance: true, maintenanceStep: 100 } });
            getPrerequisite(token, true, (productId) => props.dispatch({ type: SET_GENERAL_DATA, data: { productId } }));
          } else {
            props.dispatch({ type: IS_LOADING, data: false });
            props.dispatch({ type: SET_GENERAL_DATA, data: { isMaintenance: true, maintenanceStep: 103 } });
          }
        });
    },
    resetCredentialData: () => {
      props.dispatch({ type: RESET_CREDENTIAL_DATA });
    },
    checkCurseWord: async (text) => {
      props.dispatch({ type: IS_LOADING, data: true });
      return api
        .funnelService
        .get(`/kyc/checkCurseWord?text=${text}`)
        .then(async (res) => {
          return null;
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });
          if (error.status == 400) {
            return error.data.error;
          }
          const msgForEndUser = buildMultiLingualErrorMsg("common.userInputError");
          showErrorPage(msgForEndUser);
        });
    },
    verifyPhoneNoAndEmail: (params, sendOtp, submitUserInfo, getFbPixelId) => {
      props.dispatch({ type: IS_LOADING, data: true });
      props.dispatch({ type: ERROR_MESSAGE, data: null });
      return Promise.all([verifyPhoneNo(params), verifyEmail(params)])
        .then(([phoneVerification, emailVerification]) => {
          props.dispatch({ type: IS_LOADING, data: false });

          // Clevertap - Perform "onUserLogin()" before OTP requested
          clevertap.onUserLogin.push({
            "Site": {
              "Name": params.fullName,
              "Email": params.email,
              "Phone": `+60${params.cellPhone}`,
              "HowYouFindOutUs": params.questionnaires?.[0]?.answer || "",
            }
          });
          clevertap.event.push("act_cp_get_started", {
            "FullName": params.fullName,
            "Email": params.email,
            "Phone": `+60${params.cellPhone}`,
            "HowYouFindOutUs": params.questionnaires?.[0]?.answer || "",
            // "PrivacyNoticeAgreed": params.agreementCheck,
            "FullURL": window.location.href,
            "ProductID": props.state.generalStates?.productId,
            "ReferralCode": props.state.formStates.referralCode,
            "utmSource": props.state.generalStates?.utmSource,
            "utmMedium": props.state.generalStates?.utmMedium
          });
          ReactGA.event({
            category: gaCategory,
            action: "Get Started",
            label: "User Detail",
            value: params ? params.email : props.state.formStates.email
          });
          mixpanel.track(gaCategory, { "action": "Get Started" });
          ReactPixel.trackSingle(getFbPixelId(), "SubmitApplication", { content_name: "Submit Personal Details" });

          sendOtp(params, submitUserInfo, getFbPixelId);
          props.dispatch({ type: NEXT_STEP });
        }).catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });

          // if (error.data.applicationStatus >= 0 && error.data.applicationStatus < 3) {
          //   sendOtp(params, undefined, getFbPixelId);
          //   props.dispatch({ type: NEXT_STEP });
          // } else if (error.data.applicationStatus === 4) {
          //   props.dispatch({ type: IS_FINANCING_ON_THE_WAY });
          // } else {

          // KYC-0017, KYC-0020, KYC-0045, KYC-0046
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);

          // }
        });
    },
    submitUserInfo: (sessionKey, value) => {
      const payload = {
        sessionKey,
        fullName: value.fullName,
        emailAddress: value.email,
        questionnaires: value.questionnaires,
        phoneNumber: `60${value.cellPhone}`,
        tenantId: TENANT_ID,
        token: props.state.generalStates.token,
        referralCode: props.state.formStates.referralCode,
        referralKey: props.state.formStates.referralKey,
      };
      // props.dispatch({ type: IS_LOADING, data: true })
      props.dispatch({ type: ERROR_MESSAGE, data: null });
      return api
        .funnelService
        .post("/kyc/submitUserInfo", payload)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_FORM_DATA, data: { tenantInfo: res.data } });
        })
        .catch((error) => {
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    },
    submitBusinessDetail: (params, getFbPixelId) => {
      props.dispatch({ type: IS_LOADING, data: true });

      const payload = {
        transId: props.state.generalStates.transId,
        sessionKey: props.state.generalStates.sessionKey,
        identityCard: params.idNumber,
        companyName: params.businessName,
        companyRegistrationNo: params.businessRegistrationNumber,
        companyRegistrationType: params.companyRegistrationType,
        merchantId: params.merchantId,
        businessAddress: `${params.businessAddress1} ${params.businessAddress2} ${params.businessAddress3}`,
        businessCity: params.businessCity,
        businessPostCode: params.businessPostcode,
        businessState: params.businessState,
        businessPostOfficeId: params.businessPostOfficeId,
        businessCategory: props.state.formStates.businessCategory,
        businessSubCategory: props.state.formStates.subBusinessCategory,
        // bankId: params.bankId,
        // bankAccountNo: params.bankAccountNumber,
        productId: props.state.generalStates.productId,
      };
      return api
        .funnelService
        .post("/kyc/submitBusinessDetails", payload)
        .then((res) => {
          const trackingEvent = (isExistingApplicant = false, ramciStatus = "") => {
            if (isExistingApplicant) {
              // analytic event: refarming
              const eventName = `Refarming Existing User - Ramci status = ${ramciStatus}`;
              ReactGA.event({
                category: gaCategory,
                action: eventName,
              });
              mixpanel.track(gaCategory, { "action": eventName });
              clevertap.event.push("act_cp_refarming_existing_user", {
                "RamciStatus": ramciStatus,
                "RefarmingProductId": payload.productId,
                "ReferralCode": props.state.formStates.referralCode,
                "FullURL": window.location.href,
              });
            } else {
              // analytic event: submit business detail
              const eventName = "Submit Business Details - Success";
              ReactGA.event({
                category: gaCategory,
                action: eventName,
              });
              mixpanel.track(gaCategory, { "action": eventName });
              clevertap.event.push("act_cp_submit_business_detail", {
                "Status": "Success",
                "ReferralCode": props.state.formStates.referralCode,
                "FullURL": window.location.href
              });
            }
          };

          props.dispatch({ type: IS_LOADING, data: false });

          // Existing user
          if (res.data) {
            // for capX redirect
            if (res.data.capXUrl) {
              trackingEvent(true, "reuse existing ramci");
              navigateToExternal(res.data.capXUrl);
              return;
            }

            // CASE: Existing user resume journey (no RAMCI), restore sessionKey and show KYC loading page
            if (res.data.sessionKey) {
              trackingEvent(true, "no ramci");
              props.dispatch({
                type: SET_GENERAL_DATA,
                data: {
                  isRestoreSessionKey: true,
                  sessionKey: res.data.sessionKey,
                }
              });
              setKycLoading();
              return;
            }

            // CASE: Existing user with all same identifiers (RAMCI > 14 days), skip KYC, do RAMCI
            if (res.data?.merchantId && !res.data?.remixUrl) {
              trackingEvent(true, "more than 14 days");
              checkingRamci(getFbPixelId);
              return;
            }

            // If missing code, treat it as no amount
            const code = res.data?.code || "";
            const amount = !code ? 0 : (res.data?.amount || 0);

            // CASE: Existing user with all same identifiers (RAMCI < 14 days) BUT no amount, skip KYC, do RAMCI
            if (res.data?.merchantId && res.data?.remixUrl && amount <= 0) {
              trackingEvent(true, "within 14 days (no limit)");
              checkingRamci(getFbPixelId);
              return;
            }

            // CASE: Existing user with all same identifiers (RAMCI < 14 days), skip KYC, skip RAMCI
            if (res.data?.merchantId && res.data?.remixUrl && amount > 0) {
              trackingEvent(true, "within 14 days");

              const reportResponse = {
                eligibilityAmount: res.data.amount,
                productDetail: {
                  name: res.data.productName,
                  featureUrl: res.data.featureUrl,
                  featureText: res.data.featureText,
                  mwcUrl: buildMwcUrl(res.data.landingURL, res.data.code),
                  remixUrl: `${res.data.remixUrl}&language=${getLanguage()}`,
                  capXUrl: res.data.capXUrl,
                },
                creditScoringDetail: {
                  monthlyProfitRate: res.data.actualInterest,
                  tenure: res.data.tenure
                },
                downSell: res.data.downsell
              };

              props.dispatch({ type: SET_GENERAL_DATA, data: { previousStep: props.state.generalStates.step } });
              props.dispatch({
                type: SET_GENERAL_DATA,
                data: {
                  resultType: props.state.generalStates.bankStatementRequired ? CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA : CongratsResultTypeEnum.EXISTING_CUSTOMER,
                  reportResponse: reportResponse,
                  reportLoadingDone: true,
                  existingUser: true,
                  step: 7
                }
              });

              return;
            }

            // ** CODE SHOULD NOT REACH HERE **
            // As fallback, treating it as "New user"
            trackingEvent();
            props.dispatch({ type: NEXT_STEP });

            // Approved in Principle Page
            // if () {
            //   const reportResponse = {
            //     eligibilityAmount: 0,
            //     productDetail: {
            //       name: "",
            //       featureUrl: "",
            //       featureText: {
            //         "ms": "",
            //         "en": ""
            //       },
            //       mwcUrl: url,
            //       remixUrl: `${res.data.remixUrl}&language=${getLanguage()}`,
            //     },
            //     creditScoringDetail: {
            //       monthlyProfitRate: 0.0,
            //       tenure: 0
            //     },
            //     downSell: false
            //   };
            //   props.dispatch({ type: SET_GENERAL_DATA, data: { previousStep: props.state.generalStates.step } });
            //   props.dispatch({
            //     type: SET_GENERAL_DATA,
            //     data: {
            //       resultType: props.state.generalStates.bankStatementRequired ? CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL_BSA : CongratsResultTypeEnum.APPROVED_IN_PRINCIPAL,
            //       reportResponse: reportResponse,
            //       reportLoadingDone: true,
            //       existingUser: true,
            //       step: 7
            //     }
            //   });
            //   return;
            // }
          } else {
            // New user
            trackingEvent();
            props.dispatch({ type: NEXT_STEP });
          }
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });

          const { msgForAnalytics, msgForEndUser } = getStandardizedErrorMsgs(error.data);

          if (error.data?.error?.code === "KYC-0024") {
            console.log("Submit Business Details - Fail - Due to Existing Customer with Loan");
            ReactGA.event({
              category: gaCategory,
              action: "Submit Business Details - Fail - Due to Existing Customer with Loan",
              label: msgForAnalytics
            });
            mixpanel.track(gaCategory, { "action": "Submit Business Details - Fail - Due to Existing Customer with Loan", "label": msgForAnalytics });
            clevertap.event.push("act_cp_submit_business_detail", {
              "Status": "Fail",
              "TypesOfRejection": "Due to Existing Customer with Loan",
              "ReferralCode": props.state.formStates.referralCode,
              "FullURL": window.location.href,
            });
          } else if (error.data?.error?.code === "KYC-0025") {
            console.log("Submit Business Details - Fail - Due to High Risk job");
            ReactGA.event({
              category: gaCategory,
              action: "Submit Business Details - Fail - Due to High Risk job",
              label: msgForAnalytics
            });
            mixpanel.track(gaCategory, { "action": "Submit Business Details - Fail - Due to High Risk job", "label": msgForAnalytics });
            clevertap.event.push("act_cp_submit_business_detail", {
              "Status": "Fail",
              "TypesOfRejection": "Due to High Risk job",
              "ReferralCode": props.state.formStates.referralCode,
              "FullURL": window.location.href,
            });
          } else {
            console.log("Submit Business Details - Fail");
            ReactGA.event({
              category: gaCategory,
              action: "Submit Business Details - Fail",
              label: msgForAnalytics
            });
            mixpanel.track(gaCategory, { "action": "Submit Business Details - Fail", "label": msgForAnalytics });
            clevertap.event.push("act_cp_submit_business_detail", {
              "Status": "Fail",
              "TypesOfRejection": msgForAnalytics,
              "ReferralCode": props.state.formStates.referralCode,
              "FullURL": window.location.href,
            });
          }
          showErrorPage(msgForEndUser);
        });
    },
    handleUploadSelfie: async (getFbPixelId) => {
      if (!props.state.generalStates.selfieCameraCaptured) {
        ReactPixel.trackSingle(getFbPixelId(), "Subscribe", { content_name: "Selfie" });
        ReactGA.event({
          category: gaCategory,
          action: "Selfie"
        });
        mixpanel.track(gaCategory, { "action": "Selfie" });
        clevertap.event.push("act_cp_identity_check_selfie", {
          "ReferralCode": props.state.formStates.referralCode,
          "FullURL": window.location.href,
        });
      }
    },
    verifySelfieAndICImg: async (getFbPixelId) => {
      // props.dispatch({ type: SET_GENERAL_DATA, data: {
      //     step: 5,
      //     verifiedNricAndSelfieResult: true
      //     // sessionKey: "01597e41-7fd4-11ea-a1ff-0694c746bcf4"
      // }});
      // setTimeout(function(){
      //     checkingRamci(getFbPixelId);
      // }, 200);
      // // eslint-disable-next-line react-hooks/rules-of-hooks
      // const { t } = useTranslation();
      props.dispatch({ type: ERROR_MESSAGE, data: null });
      props.dispatch({ type: IS_LOADING, data: true });
      const formData = new FormData();
      formData.append("sessionKey", props.state.generalStates.sessionKey);
      formData.append("selfieImg", dataURLtoFile(props.state.formStates.selfieImage));
      formData.append("identityCard", dataURLtoFile(props.state.formStates.icImage));
      ReactPixel.trackSingle(getFbPixelId(), "Subscribe", { content_name: "Perform OCR" });
      return api
        .funnelService
        .post("/kyc/verifySelfieAndICImg", formData)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          console.log(res);
          if (res?.data?.faceMatchResult?.matched != null && res?.data?.textRecognitionResult?.matched != null) {
            if (!res.data.faceMatchResult.matched || !res.data.textRecognitionResult.matched) {
              console.log("it is not correct");
              ReactGA.event({
                category: gaCategory,
                action: "Identification Check - Fail"
              });
              mixpanel.track(gaCategory, { "action": "Identification Check - Fail" });
              props.dispatch({ type: SET_GENERAL_DATA, data: { icCameraActive: false, selfieCameraActive: false } });
              props.dispatch({ type: IC_IMAGE_RETRIES });
              props.dispatch({ type: SELFIE_IMAGE_RETRIES });

              // if (props.state.generalStates.icImageRetries === 2 && props.state.generalStates.selfieImageRetries === 2) {
              //     // props.dispatch({ type: SET_GENERAL_DATA, data: { step: 5, verifiedNricAndSelfieResult: false }});
              //     let line1 = i18n.t("encore.kyc.error.line_1");
              //     let line2 = i18n.t("encore.kyc.error.line_2");
              //     let line3 = i18n.t("encore.kyc.error.line_3");
              //     let errorMessageDiv = `${line1}<br>${line2}<br>${line3}`;
              //     showErrorPage(errorMessageDiv);
              //     return;
              // }

              let errMsg = "";
              if (!res.data.faceMatchResult.matched) {
                errMsg = res.data.faceMatchResult.message;
                props.dispatch({ type: ERROR_MESSAGE, data: errMsg });
              }
              if (!res.data.textRecognitionResult.matched) {
                errMsg = `${errMsg}\n${res.data.textRecognitionResult.message}`;
                props.dispatch({ type: ERROR_MESSAGE, data: errMsg });
              }
              clevertap.event.push("act_cp_identity_check", {
                "Status": "Fail",
                "TypesOfRejection": errMsg,
                "ReferralCode": props.state.formStates.referralCode,
                "FullURL": window.location.href,
              });
              props.dispatch({ type: SET_GENERAL_DATA, data: { icCameraCaptured: false, selfieCameraCaptured: false } });
              // props.dispatch({ type: ERROR_MESSAGE, data: !res.data.error.message });
              return;
            }
          }

          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              step: 5,
              verifiedNricAndSelfieResult: true
            }
          });

          ReactGA.event({
            category: gaCategory,
            action: "Identification Check - Success"
          });
          mixpanel.track(gaCategory, { "action": "Identification Check - Success" });
          clevertap.event.push("act_cp_identity_check", {
            "Status": "Success",
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });
          // checkingRamci(getFbPixelId);
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });

          const { msgForAnalytics, msgForEndUser } = getStandardizedErrorMsgs(error.data);

          // if (error.data?.error?.code === "KYC-0025" || error.data?.error?.code === "KYC-0026") {
          //   ReactGA.event({
          //     category: gaCategory,
          //     action: "Identification Check - Fail",
          //     label: msgForAnalytics
          //   });
          //   mixpanel.track(gaCategory, { "action": "Identification Check - Fail" });
          // } else {

          ReactGA.event({
            category: gaCategory,
            action: "Identification Check - Fail",
            label: msgForAnalytics
          });
          mixpanel.track(gaCategory, { "action": "Identification Check - Fail", "label": msgForAnalytics });
          clevertap.event.push("act_cp_identity_check", {
            "Status": "Fail",
            "TypesOfRejection": msgForAnalytics,
            "ReferralCode": props.state.formStates.referralCode,
            "FullURL": window.location.href,
          });
          showErrorPage(msgForEndUser);
        });
      // props.dispatch({ type: IS_LOADING, data: false });
      // props.dispatch({ type: SET_GENERAL_DATA, data: { step: 5, verifiedNricAndSelfieResult: true }});
      // ReactGA.event({
      //     category: gaCategory,
      //     action: 'Identification Check - Success'
      // });
      // mixpanel.track(gaCategory, { "action": "Identification Check - Success" });
      // // checkingRamci(getFbPixelId);
      // props.dispatch({ type: SET_GENERAL_DATA, data: {
      //     step: 5,
      //     verifiedNricAndSelfieResult: true,
      // }});
    },
    captureLocation: (params, storeLocation, getFbPixelId) => {
      if (props.state.generalStates.existingUser) {
        if (params) {
          storeLocation(params);
        }
        props.dispatch({ type: SET_GENERAL_DATA, data: { step: 7 } });
      } else {
        if (params) {
          storeLocation(params);
        }
        checkingRamci(getFbPixelId);
      }
    },
    sendRemixEmail: () => {
      props.dispatch({ type: IS_LOADING, data: true });
      const payload = {
        sessionKey: props.state.generalStates.sessionKey
        // sessionKey: "a50bcff4-816b-11ea-a1ff-0694c746bcf4"
      };
      return api
        .funnelService
        .post("/kyc/sendCongratsEmail", payload)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_GENERAL_DATA, data: { step: 10 } });
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_GENERAL_DATA, data: { step: -3 } });
        });
    },
    submitInterestRegistration: (params) => {
      props.dispatch({ type: IS_LOADING, data: true });

      const payload = {
        fullName: params.leadGenName,
        emailAddress: params.leadGenEmail,
        msisdn: `60${params.leadGenMsisdn}`,
        productId: props.state.generalStates.productId
      };

      return api
        .funnelService
        .post("/kyc/submitLeadGenInfo", payload)
        .then((res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_GENERAL_DATA, data: { maintenanceStep: 101 } });
        })
        .catch((error) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_GENERAL_DATA, data: { maintenanceStep: 102 } });
        });
    },
    setKycBefore,
    retryKycBefore,
    setKycLoading,
    registerJumioKyc: () => {
      props.dispatch({ type: IS_LOADING, data: true });
      const { sessionKey, token } = props.state.generalStates;
      const { referralCode, referralKey } = props.state.formStates;

      let comebackUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?token=${token}&sessionKey=${sessionKey}&fromJumio=1`;
      if (comebackUrl.indexOf("localhost") >= 0) {
        // DEV: localhost cannot pass jumio validation
        comebackUrl = `https://google.com/test?token=${token}&sessionKey=${sessionKey}&fromJumio=1`;
      } else if (comebackUrl.indexOf(":9191") >= 0) {
        // UAT: remove port number to pass jumio validation
        comebackUrl = comebackUrl.replace(":9191", "");
      }
      // Add referral code
      if (referralCode && referralKey) {
        comebackUrl = buildUrlQueryParam(comebackUrl, {
          referral: referralCode,
          referralKey: referralKey,
        });
      }

      const payload = {
        sessionKey: sessionKey,
        successUrl: comebackUrl,
        errorUrl: comebackUrl
      };

      return api
        .funnelService
        .post("/jumio/registerJumioKyc", payload)
        .then(async (res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({ type: SET_GENERAL_DATA, data: { kycJumioUrl: res.data.redirectUrl } });
        })
        .catch((error) => {
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    },
    getJumioKycStatus: () => {
      const path = `/jumio/getJumioKycStatus?sessionKey=${props.state.generalStates.sessionKey}`;
      return api
        .funnelService
        .get(path)
        .then(async (res) => {
          // if (skipCallback && res.data?.status == KycStatusEnum.INITIATED) {
          //   // FE manipulate to force error
          //   res.data.status = KycStatusEnum.FAILED;
          //   res.data.allowedToRetry = "NO";
          //   res.data.errorCode = "BCC004";
          //   res.data.reason = "EKYC_TIME_OUT";
          // }
          if (res.data?.status != KycStatusEnum.INITIATED) {
            props.dispatch({
              type: SET_GENERAL_DATA,
              data: {
                kycResult: res.data.status,
                kycPromptRetry: Boolean(res.data.allowedToRetry == "YES"),
                kycReasonCode: res.data.errorCode,
                kycReasonDetail: res.data.reason,
              }
            });
          } else {
            setKycLoading();
          }
        })
        .catch((error) => {
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    },
    getSessionInfo: (resumeJourneySendOtp) => {
      const path = `/jumio/getSessionInfo?sessionKey=${props.state.generalStates.sessionKey}`;
      return api
        .funnelService
        .get(path)
        .then(async (res) => {
          // Restore necessary info into states for resume journey
          const cellPhone = removeLeadingCountryCode(res.data.phoneNumber);
          props.dispatch({
            type: SET_FORM_DATA,
            data: {
              fullName: res.data.fullName,
              email: res.data.emailAddress,
              cellPhone,
              idNumber: res.data.identityCard,
              referralCode: res.data.referralCode,
            }
          });
          // Set referral into sessionStorage as if it's coming from URL param
          sessionStorage.setItem("referral", res.data.referralCode);

          if (cellPhone) {
            // Clevertap - Perform "onUserLogin()" before OTP requested
            clevertap.onUserLogin.push({
              "Site": {
                "Email": res.data.emailAddress,
                "Phone": `+60${cellPhone}`,
              }
            });

            if (resumeJourneySendOtp) {
              // For resume journey via resumeJourney=1 (email sent by Ops)
              clevertap.event.push("act_cp_resume_journey_after_manual_kyc", {
                "FullName": res.data.fullName,
                "Email": res.data.emailAddress,
                "Phone": `+60${cellPhone}`,
                "FullURL": window.location.href,
                "ProductID": props.state.generalStates?.productId,
                "ReferralCode": res.data.referralCode,
              });

              resumeJourneySendOtp({
                cellPhone
              });
            } else {
              // For refresh page at fromJumio=1 URL, if already done RAMCI, skip RAMCI
              if (res.data.ramciStatus == RamciStatusEnum.SUCCESSFUL) {
                const reportResponse = {
                  eligibilityAmount: res.data.amount,
                  productDetail: {
                    // name: res.data.productName,
                    // featureUrl: res.data.featureUrl,
                    // featureText: res.data.featureText,
                    mwcUrl: buildMwcUrl(res.data.landingUrl, res.data.code),
                    remixUrl: `${res.data.remixUrl}&language=${getLanguage()}`,
                  },
                  // creditScoringDetail: {
                  //   monthlyProfitRate: res.data.actualInterest,
                  //   tenure: res.data.tenure
                  // },
                  // downSell: res.data.downsell
                };

                props.dispatch({ type: SET_GENERAL_DATA, data: { previousStep: props.state.generalStates.step } });
                props.dispatch({
                  type: SET_GENERAL_DATA,
                  data: {
                    resultType: props.state.generalStates.bankStatementRequired ? CongratsResultTypeEnum.EXISTING_CUSTOMER_BSA : CongratsResultTypeEnum.EXISTING_CUSTOMER,
                    reportResponse: reportResponse,
                    reportLoadingDone: true,
                    existingUser: true,
                    step: 7
                  }
                });
                return;
              }

              // For resume journey via submitBusinessDetails API
              // Certain KYC result needs to be routed to pre-Jumio page instead of result page
              if (props.state.generalStates.isRestoreSessionKey) {
                const { kycStatus, allowedToRetry, errorCode } = res.data;
                if (isKycPotentialStuckIfProceedToResultPage(kycStatus, allowedToRetry, errorCode)) {
                  setKycBefore();
                }
              }
            }
          } else {
            showErrorPage();
          }
        })
        .catch((error) => {
          // Might get similar errors as verifyPhoneNo & verifyEmail APIs
          // KYC-0017, KYC-0020, KYC-0045, KYC-0046
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    },
    resolveFailedVerification: (values) => {
      console.log("resolveFailedVerification()", values);

      props.dispatch({ type: IS_LOADING, data: true });
      const { sessionKey } = props.state.generalStates;
      const { fullName, idNumber } = values;

      const payload = {
        sessionKey,
        fullName,
        identityCard: idNumber
      };

      return api
        .funnelService
        .post("/jumio/resolveFailedVerification", payload)
        .then(async (res) => {
          props.dispatch({ type: IS_LOADING, data: false });
          props.dispatch({
            type: SET_GENERAL_DATA,
            data: {
              kycStep: KycStepEnum.RESULT,
              kycResult: res.data.status,
              kycPromptRetry: Boolean(res.data.status == KycStatusEnum.PENDING_RETRY || res.data.allowedToRetry == "YES"),
              kycReasonCode: res.data.errorCode,
              kycReasonDetail: res.data.reason,
            }
          });
        })
        .catch((error) => {
          const { msgForEndUser } = getStandardizedErrorMsgs(error.data);
          showErrorPage(msgForEndUser);
        });
    }
  };
};
