import { generalActions } from "./generalActions";
import { credentialActions } from "./credentialActions";
import { formActions } from "./formActions";
import { otpActions } from "./otpActions";
import { verifyPageActions } from "./verifyPageActions";

export const useActions = (state, dispatch) => {
  return {
    generalActions: generalActions({ state, dispatch }),
    credentialActions: credentialActions({ state, dispatch }),
    formActions: formActions({ state, dispatch }),
    otpActions: otpActions({ state, dispatch }),
    verifyPageActions: verifyPageActions({ state, dispatch })
  };
};
