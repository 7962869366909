import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import { Field, Formik, Form } from "formik";
import * as yup from "yup";
// import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { FormControl, Input, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StoreContext } from "../../store/context";
import { getTermsAndConditionsUrl } from "../../utils";
import maintenance from "../../assets/images/leadGen/maintenance.svg";
// import alert from "../../assets/images/leadGen/alert.svg";
import checkedBox from "../../assets/images/encore/checkbox-active.svg";
import uncheckedBox from "../../assets/images/encore/checkbox-inactive.svg";
// import { TextFieldStyled } from "../ReusableComponent/ReusableStyles";

import "./LeadGen.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "30px",
    "&:first-child": {
      marginTop: "0px",
    }
  },
  stepLabel: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "bold !important",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconImg: {
    height: "100%",
    width: "100%",
  },
  imgPreview: {
    objectFit: "contain",
    width: "100%",
  },
}));

const LeadGen = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, actions } = useContext(StoreContext);
  const { submitInterestRegistration } = actions.credentialActions;
  const formikRef = useRef(null);
  const { distributor, sessionKey, productId } = state.generalStates;
  const tncUrl = getTermsAndConditionsUrl(distributor?.id);
  const { setFormData } = actions.formActions;
  const {
    leadGenName,
    leadGenEmail,
    leadGenMsisdn,
    leadGenAgreementCheck
  } = state.formStates;

  const emailRules = {
    required: "",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: ""
    }
  };

  const isExcludeZero = (val) => {
    if (val) {
      return !(val[0] === "0");
    }
  };

  const phoneNumberRules = {
    required: "",
    pattern: {
      value: /^[0-9]{9,11}$/,
      message: ""
    },
    validate: {
      excludeZero: isExcludeZero,
    }
  };

  const personalSchemaObject = {
    leadGenName: yup.string().required(t("encore.basicDetail.field.fullNameField.requiredError")).matches("^[a-zA-Z/.@\\- ]*$", t("encore.basicDetail.field.fullNameField.regexError")),
    leadGenEmail: yup.string().email(t("encore.basicDetail.field.emailField.regexError")).required(t("encore.basicDetail.field.emailField.requiredError")),
    leadGenMsisdn: yup
      .string()
      .matches(/^[0-9]{9,11}$/, t("encore.basicDetail.field.phoneNumberField.regexError"))
      .required(t("encore.basicDetail.field.phoneNumberField.requiredError"))
      .test("excludeZero", t("encore.basicDetail.field.phoneNumberField.excludeZeroError"), (value) => {
        if (value) {
          return !(value[0] === "0");
        }
      }),
    leadGenAgreementCheck: yup.bool().oneOf([true], t("encore.basicDetail.field.privateNoticeCheckField.checkRequired"))
  };

  const personalSchema = yup.object().shape(personalSchemaObject);

  return (
    <>
      <div className="leadgen maintenance">
        <Container>
          <Row>
            <Col style={{ textAlign: "left" }} md={10} lg={6}>
              <div className="leadGenTitle">
                {t("leadGen.register.title")}
              </div>
              <div className="desc">
                {t("leadGen.register.subTitle")}
              </div>
              <div className="formik-form">
                <Formik
                  innerRef={formikRef}
                  //   enableReinitialize
                  initialValues={{
                    leadGenName: `${leadGenName || ""}`,
                    leadGenEmail: `${leadGenEmail || ""}`,
                    leadGenMsisdn: `${leadGenMsisdn || ""}`,
                    leadGenAgreementCheck,
                  }}
                  validationSchema={personalSchema}
                  onSubmit={async (values) => {
                    const payload = {
                      leadGenName: values.leadGenName,
                      leadGenEmail: values.leadGenEmail,
                      leadGenMsisdn: values.leadGenMsisdn,
                      leadGenAgreementCheck: values.leadGenAgreementCheck,
                    };
                    console.log("values", values);
                    console.log("payload", payload);
                    setFormData(values);
                    submitInterestRegistration(payload);
                  }}
                >
                  {({ isSubmitting, values, handleChange }) => (
                    <Form>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <p className="field-label">{t("leadGen.register.nameTitle")}</p>
                        <Field name="leadGenName">
                          {({ field, form: { touched, errors } }) => (
                            <>
                              <Input
                                {...field}
                                id="leadGenName"
                                name="leadGenName"
                                className="input-field"
                                // type="text"
                                placeholder="Fill in your name"
                                fullWidth
                                // margin="normal"
                                onKeyUp={(event) => {
                                  setFormData({ leadGenName: event.target.value.trim() });
                                }}
                                // variant="outlined"
                                // className={touched[field.name] && errors[field.name] ? "input-field col-md-16 col-lg-12 error" : "input-field col-md-16 col-lg-12 primary"}
                                error={Boolean(touched[field.name] && errors[field.name])}
                              />
                              {touched[field.name] && errors[field.name] && (
                                <div className="error-text">
                                  {errors[field.name]}
                                </div>
                              )}
                            </>
                          )}
                        </Field>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <p className="field-label">{t("leadGen.register.emailTitle")}</p>
                        <Field name="leadGenEmail">
                          {({ field, form: { touched, errors } }) => (
                            <>
                              <Input
                                {...field}
                                id="leadGenEmail"
                                name="leadGenEmail"
                                className="input-field"
                                type="email"
                                placeholder="username@email.com"
                                fullWidth
                                // margin="normal"
                                onKeyUp={(event) => {
                                  setFormData({ leadGenEmail: event.target.value.trim() });
                                }}
                                // variant="outlined"
                                // className={touched[field.name] && errors[field.name] ? "input-field col-md-16 col-lg-12 error" : "input-field col-md-16 col-lg-12 primary"}
                                error={Boolean(touched[field.name] && errors[field.name])}
                              />
                              {touched[field.name] && errors[field.name] && (
                                <div className="error-text">
                                  {errors[field.name]}
                                </div>
                              )}
                            </>
                          )}
                        </Field>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <p className="field-label">{t("leadGen.register.phoneTitle")}</p>
                        <Field name="leadGenMsisdn">
                          {({ field, form: { touched, errors } }) => (
                            <>
                              <div className="form-flex">
                                {/* <div className="mobile-no-index">
                                            <span style={{ fontWeight: "bold" }}>+60</span>
                                        </div> */}
                                <div className="item">
                                  <span className="mobile-no-index">+60</span>
                                  <Input
                                    {...field}
                                    id="leadGenMsisdn"
                                    name="leadGenMsisdn"
                                    className="input-field"
                                    // type="text"
                                    placeholder="123456789"
                                    fullWidth
                                    // margin="normal"
                                    onKeyUp={(event) => {
                                      setFormData({ leadGenMsisdn: event.target.value.trim() });
                                    }}
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    // variant="outlined"
                                    // className={touched[field.name] && errors[field.name] ? "input-field col-md-12 col-lg-8 error" : "input-field col-md-12 col-lg-8 primary"}
                                    error={Boolean(touched[field.name] && errors[field.name])}
                                  />
                                </div>
                              </div>
                              {touched[field.name] && errors[field.name] && (
                                <div className="error-text">
                                  {errors[field.name]}
                                </div>
                              )}
                            </>
                          )}
                        </Field>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Field name="leadGenAgreementCheck">
                          {({ field, form: { touched, errors, setFieldValue } }) => (
                            <>
                              <div className="checkbox-container">
                                <FormControlLabel
                                  className={leadGenAgreementCheck ? "checkbox-checked" : "checkbox"}
                                  control={(
                                    <img
                                      className="agreement-checkbox"
                                      onClick={(e) => {
                                        setFieldValue("leadGenAgreementCheck", !leadGenAgreementCheck);
                                        setFormData({ leadGenAgreementCheck: !leadGenAgreementCheck });
                                        handleChange(e);
                                      }}
                                      alt="agreementWakalah"
                                      height="24"
                                      src={leadGenAgreementCheck ? checkedBox : uncheckedBox}
                                    />
                                  )
                                    // <Checkbox
                                    //     {...field}
                                    //     checked={leadGenAgreementCheck}
                                    //     name="leadGenAgreementCheck"
                                    //     style={{
                                    //         color: leadGenAgreementCheck ? "white" : "#124A67",
                                    //     }}
                                    //     onChange={(e) => {
                                    //         setFormData({ leadGenAgreementCheck: !leadGenAgreementCheck });
                                    //         handleChange(e);
                                    //     }}
                                    //     value={leadGenAgreementCheck}
                                    //   />
                                  }
                                  label={(
                                    <span className="checkbox-label">
                                      {t("leadGen.register.termsAndPrivacy")}
                                      {" "}
                                      {/* <a
                                                            className="link__primary__dark underline"
                                                            className={leadGenAgreementCheck ? "link__white underline" : "link__primary__dark underline"}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="tncUrl"
                                                        >
                                                            {t("footer.terms")}
                                                        </a>
                                                        {" and "} */}
                                      <a
                                        // className="link__primary__dark underline"
                                        // className={leadGenAgreementCheck ? "link__white underline" : "link__primary__dark underline"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://funnel-ada-matta.s3-ap-southeast-1.amazonaws.com/Funnel-ADA-Privacy-Notice.pdf"
                                      >
                                        {t("encore.basicDetail.field.privateNoticeCheckField.linkClickLabel")}
                                      </a>
                                      .
                                    </span>
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </Field>
                      </FormControl>
                      <div className="leadGen-button-container">
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="button"
                          type="submit"
                          disabled={isSubmitting || !leadGenAgreementCheck}
                        >
                          {t("landing.applyButton")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
            <Col md={10} lg={6} className="d-none d-lg-block">
              <div className="logo-container">
                <img src={maintenance} alt="Maintenance" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LeadGen;
