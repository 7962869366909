import React, { useContext, useRef, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FormControl, Input, makeStyles } from "@material-ui/core";
import { StoreContext } from "../../store/context";
import CustomSelect from "../CustomSelect";
import { getLanguage, loadCacheProgress, saveCacheProgress } from "../../utils";
import lock from "../../assets/images/encore/lock.svg";
// import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
import * as ubd from "../../utils/userBehaviouralData";

import "./BusinessDetail.scss";

const mouseSpeed = [];

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "30px",
    "&:first-child": {
      marginTop: "0px",
    },
    "&.consecutive": {
      marginTop: "10px",
    }
  },
  stepLabel: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "bold !important",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconImg: {
    height: "100%",
    width: "100%",
  },
  imgPreview: {
    objectFit: "contain",
    width: "100%",
  },
}));

const filter = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => option.postcode,
});

const BusinessDetail = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, actions } = useContext(StoreContext);
  const { getFbPixelId } = actions.generalActions;
  const { submitBusinessDetail } = actions.credentialActions;
  const { handleUserBehaviourData } = actions.otpActions;

  const validOldBRNRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/;
  const validNewBRNRegex = useMemo(() => {
    const yearNow = new Date().getFullYear();
    let reg = "";
    // new BRN format start from 2019
    for (let i = 2019; i <= yearNow; i++) {
      reg += i;
      reg += (i !== yearNow) ? "|" : "";
    }
    return new RegExp(`^(${reg})\\d{8}$`);
  }, []);

  const {
    parentCategory,
    childCategory,
    businessAddress1,
    businessAddress2,
    businessAddress3,
    businessCity,
    businessPostcode,
    businessState,
    stateList,
    postOfficeList,
    stateHashMap,
    postOfficeHashMap,
    idNumber,
    businessName,
    businessRegistrationNumber,
    companyRegistrationType,
    businessCategory,
    subBusinessCategory,
    merchantId, cellPhone, email } = state.formStates;
  const { setFormData, getBusinessCategories, getStateAndPostcode } = actions.formActions;
  const { gaCategory, enableMerchantIdField, token, overallStep, step, userBehaviourReference } = state.generalStates;
  const { mandatoryBRN } = state.formStates.tenantInfo;
  const [firstLoad, setFirstLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [enableSubCategories, setEnableSubCategories] = useState(false);
  const formikRef = useRef(null);

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [onTypingStart, setOnTypingStart] = useState(null);
  const [onTypingEnd, setOnTypingEnd] = useState(null);
  const [totalTypingDuration, setTotalTypingDuration] = useState(null);
  const [typingSpeed, setTypingSpeed] = useState([]);
  const [valueChange, setValueChange] = useState([]);
  const [selectedText, setSelectedText] = useState(null);
  const [copiedText, setCopiedText] = useState([]);
  const [cutText, setCutText] = useState([]);
  const [pastedText, setPastedText] = useState([]);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);
  // const [cachedForm, setCachedForm] = useState(null);

  const businessSchema = yup.object().shape({
    idNumber: yup
      .string()
      .matches(
        /^\d{6}-\d{2}-\d{4}$/,
        t("encore.businessDetail.field.icNumberField.regexError")
      )
      .test(
        "checkAge",
        t("encore.businessDetail.field.icNumberField.ageError"),
        (value) => {
          if (value) {
            if (value.length) {
              if (value.length > 5) {
                const nric = value;
                const dob = nric.split("-")[0].substring(0, 6);
                const momentDate = moment(dob, "YYMMDD");
                if (momentDate.isValid()) {
                  if (momentDate.year() > moment().year()) {
                    momentDate.subtract(100, "year");
                  }
                  const date_convert_age = moment().diff(momentDate, "years");
                  if (date_convert_age > 65 || date_convert_age < 18) {
                    return false;
                  }
                  return true;
                }
                return false;
              }
            }
          }
          return true;
        }
      )
      .required(t("encore.businessDetail.field.icNumberField.requiredError")),
    businessName: yup.string().required(t("encore.businessDetail.field.businessNameField.requiredError")),
    //
    businessRegistrationNumber: yup.string().when(["mandatoryBRN", "companyRegistrationType"], (mandatoryBRNVal, companyRegistrationTypeVal, schema) => {
      if (mandatoryBRNVal !== null && mandatoryBRNVal === 1) {
        if (companyRegistrationTypeVal == 1) {
          return schema
            .required(t("encore.businessDetail.field.businessRegistrationNumberField.requiredError"))
            .test("vlidateBRN", t("encore.businessDetail.field.businessRegistrationNumberField.invalidError"), (value) => {
              return validateBRN(value);
            });
        }
        return schema
          .required(t("encore.businessDetail.field.businessRegistrationNumberField.requiredError"));
      }

      if (companyRegistrationTypeVal == 1) {
        return schema
          .test("vlidateBRN", t("encore.businessDetail.field.businessRegistrationNumberField.invalidError"), (value) => {
            return validateBRN(value);
          });
      }

      return schema;
    }),
    businessCategory: yup.string().required(t("encore.businessDetail.field.businessCategoryField.requiredError")),
    subBusinessCategory: enableSubCategories
      ? yup
        .string()
        .required(
          t("encore.businessDetail.field.businessSubCategoryField.requiredError")
        )
      : "",
    merchantId: enableMerchantIdField
      ? yup
        .string()
        .required(t("encore.businessDetail.field.merchantIdField.requiredError"))
      : "",
    businessAddress1: yup.string().required(t("encore.businessDetail.field.businessAddressField.requiredError")),
    businessCity: yup.string().required(t("encore.businessDetail.field.cityField.requiredError")),
    businessPostcode: yup.string().required(t("encore.businessDetail.field.postCodeField.requiredError")),
    businessState: yup.string().required(t("encore.businessDetail.field.stateField.requiredError"))
  });

  function validateBRN(value) {
    const reg = /^\d+$/;
    if (reg.test(value)) {
      // validate new BRN format
      if (validNewBRNRegex.test(value)) {
        return true;
      }
      return false;
    }
    // validate old BRN format
    if (validOldBRNRegex.test(value)) {
      return true;
    }
    return false;
  }

  function TooltipRender(props) {
    const { toolTipLabel } = props;
    return (
      <Tooltip
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        title={(
          <h6 style={{ marginBottom: "0rem" }}>
            {toolTipLabel}
          </h6>
        )}
        arrow
        placement="top"
      >
        <IconButton aria-label="delete" style={{ padding: "unset" }} onClick={() => { setOpen(true); }}>
          <ErrorOutlineIcon style={{ color: "#E1519C" }} />
        </IconButton>
      </Tooltip>
    );
  }

  const getTooltip = () => {
    if (token) {
      if (token === "e6f23cf1-1108-11eb-a321-0a5843143288") {
        return <TooltipRender toolTipLabel={`${t("encore.businessDetail.field.merchantIdField.tooltip")}nn21`} />;
      } if (token === "177b604a-1218-11eb-a321-0a5843143288") {
        return <TooltipRender toolTipLabel={`${t("encore.businessDetail.field.merchantIdField.tooltip")}300-D000222`} />;
      } if (token === "c27919eb-1c0e-11ec-ae4a-0a5843143288") {
        return <TooltipRender toolTipLabel={`${t("encore.businessDetail.field.merchantIdField.tooltipAlt1")}NTH60469`} />;
      }
    }
    return [];
  };

  function getParentCategory() {
    const layout = [];
    if (!parentCategory) return layout;
    parentCategory.map((item, key) => layout.push(
      <MenuItem key={key} value={item.id} data-risk={item.risk}>
        {item.name[getLanguage()]}
      </MenuItem>
    ));
    return layout;
  }

  function getCompanyRegistrationType() {
    const layout = [];
    layout.push(<MenuItem key="SSM" value={1}>{t("encore.businessDetail.field.SSM.label")}</MenuItem>);
    layout.push(<MenuItem key="Local Council Business Registration" value={2}>{t("encore.businessDetail.field.localCouncilBusinessRegistration.label")}</MenuItem>);
    return layout;
  }

  function getSubCategory() {
    const layout = [];
    if (!childCategory[businessCategory]) return layout;
    childCategory[businessCategory].map((item, key) => layout.push(
      <MenuItem key={key} value={item.id} data-risk={item.risk}>
        {item.name[getLanguage()]}
      </MenuItem>
    ));
    return layout;
  }

  const handleAutoPopulate = (newValue, values) => {
    setFormData({
      businessCity: newValue,
      businessState: newValue.stateId,
      businessPostcode: newValue,
      businessAddress1: values.businessAddress1,
      businessAddress2: values.businessAddress2,
      businessAddress3: values.businessAddress3
    });
  };

  const initializeBusinessCity = (_businessCity) => {
    if (_businessCity) {
      if (typeof _businessCity === "object" && _businessCity.constructor === Object) {
        setFormData({
          businessCity: _businessCity,
          businessState: _businessCity.stateId,
          businessPostcode: _businessCity
        });
      } else {
        setFormData({
          businessCity: postOfficeHashMap[_businessCity],
          businessState: postOfficeHashMap[_businessCity].stateId,
          businessPostcode: postOfficeHashMap[_businessCity]
        });
      }
    }
  };

  const initFormData = (cachedFormData) => {
    setFormData({
      ...cachedFormData
    });
  };

  useEffect(() => {
    getBusinessCategories();
    getStateAndPostcode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessCity) {
      initializeBusinessCity(businessCity);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCity]);

  useEffect(() => {
    if (parentCategory && childCategory && stateHashMap && postOfficeHashMap) {
      if (email && cellPhone) {
        // Restore FormData to LS
        const cachedPayload = loadCacheProgress(email, `+60${cellPhone}`, "formStates");
        if (cachedPayload) {
          const cachedFormData = cachedPayload.rawFormData;
          const bizPostcodeObj = cachedPayload.businessPostcode;
          delete cachedPayload.rawFormData;
          // setCachedForm({
          //   ...cachedPayload,
          //   businessCity: `${bizPostcodeObj ? typeof bizPostcodeObj === "object" && bizPostcodeObj.constructor === Object ? bizPostcodeObj.postOfficeLocation : postOfficeHashMap[bizPostcodeObj].postOfficeLocation : ""}`,
          //   businessPostcode: `${bizPostcodeObj || ""}`,
          //   businessState: `${bizPostcodeObj ? stateHashMap[bizPostcodeObj.stateId].stateName : ""}`
          // });
          initializeBusinessCity(bizPostcodeObj);
          initFormData({
            ...cachedFormData,
            businessCategory: cachedPayload.businessCategory,
            subBusinessCategory: cachedPayload.subBusinessCategory,
          });
          setEnableSubCategories((childCategory[cachedPayload.businessCategory]
              && childCategory[cachedPayload.businessCategory].length > 0));
        }
      }
    }
  }, [email, cellPhone, parentCategory, childCategory, stateHashMap, postOfficeHashMap]);

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const duration = ubd.calculateDuration(onTypingEnd, onTypingStart);
    setTotalTypingDuration(duration._milliseconds + (totalTypingDuration != null ? totalTypingDuration : 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypingEnd]);

  useEffect(() => {
    const fieldNames = ["idNumber", "businessName", "businessRegistrationNumber", "merchantId", "businessAddress1", "businessAddress2", "businessAddress3"];
    const typingSpeedArray = [];
    const valueChangeArray = [];
    fieldNames.forEach((name) => {
      if (typingSpeed[name] != null) {
        typingSpeedArray.push(typingSpeed[name]);
      }
      if (valueChange[name] != null) {
        valueChangeArray.push(valueChange[name]);
      }
    });
    const params = {
      step: ubd.STEP_BUSINESS_DETAIL,
      startDate: journeyStart,
      endDate: singleStepEnd,
      typingDuration: totalTypingDuration,
      typingSpeed: typingSpeedArray,
      typingSpeedKey: typingSpeed,
      valueChange: valueChangeArray,
      valueChangeKey: valueChange,
      totalMouseSpeed,
      clipboardCopy: copiedText,
      clipboardCut: cutText,
      clipboardPaste: pastedText
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  const checkBehaviourExist = () => {
    if (userBehaviourReference != null) {
      for (let i = 0; i < userBehaviourReference.length; i++) {
        if (userBehaviourReference[i].stepId == ubd.STEP_BUSINESS_DETAIL) {
          if (userBehaviourReference[i].typingSpeedKey != null) {
            setTypingSpeed(userBehaviourReference[i].typingSpeedKey);
          }
          if (userBehaviourReference[i].clipboardCopy != null) {
            setCopiedText(userBehaviourReference[i].clipboardCopy);
          }
          if (userBehaviourReference[i].clipboardCut != null) {
            setCutText(userBehaviourReference[i].clipboardCut);
          }
          if (userBehaviourReference[i].clipboardPaste != null) {
            setPastedText(userBehaviourReference[i].clipboardPaste);
          }
          if (userBehaviourReference[i].valueChangeKey != null) {
            setValueChange(userBehaviourReference[i].valueChangeKey);
          }
        }
      }
    }
  };

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    checkBehaviourExist();
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="encore__business_detail__guide">
      {/* <IconButton
        color="primary"
        onClick={() => {
          setGeneralData({ step: 2 });
        }}
        className="encore__page__back_holder"
      >
        <img src={circleLeftArrow} alt="Back" />
      </IconButton> */}
      <div className="encore__page__upper">
        <div className="encore__identifier">
          <div>
            {`${t("common.step_plural")} ${step} ${t("common.of")} ${overallStep}` }
          </div>
          <div>
            <img src={lock} alt="Secure" />
          </div>
        </div>
        <div className="encore__title">{t("encore.businessDetail.title")}</div>
        <div className="encore__desc">{t("encore.businessDetail.description")}</div>
      </div>
      <div className="formik-form">
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            mandatoryBRN,
            idNumber: `${idNumber || ""}`,
            businessName: `${businessName || ""}`,
            businessRegistrationNumber: `${
              businessRegistrationNumber || ""
            }`,
            companyRegistrationType: `${companyRegistrationType || ""}`,
            businessCategory: `${businessCategory || ""}`,
            subBusinessCategory: `${
              subBusinessCategory || ""
            }`,
            merchantId: `${merchantId || ""}`,
            businessAddress1: `${businessAddress1 || ""}`,
            businessAddress2: `${businessAddress2 || ""}`,
            businessAddress3: `${businessAddress3 || ""}`,
            businessCity: `${businessCity ? typeof businessCity === "object" && businessCity.constructor === Object ? businessCity.postOfficeLocation : postOfficeHashMap[businessCity].postOfficeLocation : ""}`,
            businessPostcode: `${businessPostcode || ""}`,
            businessState: `${businessState ? stateHashMap[businessState].stateName : ""}`
          }}
          validationSchema={businessSchema}
          onSubmit={(values) => {
            // console.log("onSubmit values", values);
            const payload = {
              idNumber: values.idNumber,
              businessName: values.businessName,
              businessRegistrationNumber: values.businessRegistrationNumber,
              companyRegistrationType: values.companyRegistrationType,
              merchantId: values.merchantId,
              businessAddress1: values.businessAddress1,
              businessAddress2: values.businessAddress2,
              businessAddress3: values.businessAddress3,
              businessPostcode: businessPostcode.postOfficeId,
              businessState: stateHashMap[businessState].stateId,
              businessCity: typeof businessCity === "object" && businessCity.constructor === Object ? businessCity.postOfficeId : postOfficeHashMap[businessCity].postOfficeId,
              businessPostOfficeId: typeof businessCity === "object" && businessCity.constructor === Object ? businessCity.postOfficeId : postOfficeHashMap[businessCity].postOfficeId
            };
            ReactGA.event({
              category: gaCategory,
              action: "Business Detail - Continue",
            });
            mixpanel.track(gaCategory, { action: "Business Detail - Continue" });
            ReactPixel.trackSingle(getFbPixelId(), "Purchase", {
              content_name: "Submit Business Details",
            });
            handleEndJourney();
            // Backup FormData to LS
            saveCacheProgress(email, `+60${cellPhone}`, "formStates", {
              idNumber: payload.idNumber,
              businessName: payload.businessName,
              businessRegistrationNumber: payload.businessRegistrationNumber,
              companyRegistrationType: payload.companyRegistrationType,
              businessCategory,
              subBusinessCategory,
              businessPostcode,
              merchantId: payload.merchantId,
              businessAddress1: payload.businessAddress1,
              businessAddress2: payload.businessAddress2,
              businessAddress3: payload.businessAddress3,
              rawFormData: payload
            });
            setFormData(payload);
            submitBusinessDetail(payload, getFbPixelId);
          }}
        >
          {({ isSubmitting, values, handleChange, handleBlur }) => (
            <Form autoComplete="off">

              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.icNumberField.label")}</p>
                  <Field name="idNumber">
                    {({ field, form: { touched, errors } }) => (
                      <>
                        <Input
                          {...field}
                          id="idNumber"
                          name="idNumber"
                          className="input-field"
                          placeholder={t("encore.businessDetail.field.icNumberField.placeholder")}
                          onKeyUp={(event) => {
                            setFormData({ idNumber: event.target.value.trim() });
                          }}
                          error={Boolean(touched[field.name] && errors[field.name])}
                          onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                          onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                          onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                          onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                          onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                          onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                          onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.businessNameField.label")}</p>
                  <div className="input-label-notes">
                    <p>
                      {t(
                        "encore.businessDetail.field.businessNameField.note"
                      )}
                    </p>
                  </div>
                  <Field name="businessName">
                    {({ field, form: { touched, errors } }) => (
                      <>
                        <Input
                          {...field}
                          id="businessName"
                          name="businessName"
                          className="input-field"
                          placeholder={t("encore.businessDetail.field.businessNameField.placeholder")}
                          onKeyUp={(event) => {
                            setFormData({ businessName: event.target.value.trim() });
                          }}
                          error={Boolean(touched[field.name] && errors[field.name])}
                          onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                          onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                          onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                          onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                          onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                          onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                          onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.companyRegistrationTypeField.label")}</p>
                  <Field name="companyRegistrationType">
                    {({ field, form: { touched, errors, setFieldValue } }) => (
                      <>
                        <CustomSelect
                          {...field}
                          labelId="companyRegistrationTypeLabel"
                          id="companyRegistrationType"
                          name="companyRegistrationType"
                          className={`input-field select ${companyRegistrationType ? "selected" : ""}`}
                          value={companyRegistrationType || ""}
                          displayEmpty
                          onChange={async (e) => {
                            handleChange(e);
                            // e.currentTarget.attributes["data-risk"].value
                            await setFormData({
                              companyRegistrationType: e.target.value,
                            });
                            setFieldValue("companyRegistrationType", e.target.value);
                            setFirstLoad(false);
                          }}
                          variant="outlined"
                        >
                          {getCompanyRegistrationType()}
                        </CustomSelect>
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.businessRegistrationNumberField.label")}</p>
                  <div className="input-label-notes">
                    <p>
                      {t(
                        "encore.businessDetail.field.businessRegistrationNumberField.note"
                      )}
                    </p>
                  </div>
                  <Field name="businessRegistrationNumber">
                    {({ field, form: { touched, errors } }) => (
                      <>
                        <Input
                          {...field}
                          id="businessRegistrationNumber"
                          name="businessRegistrationNumber"
                          className="input-field"
                          placeholder={values.companyRegistrationType == 1 ? t("encore.businessDetail.field.businessRegistrationNumberField.placeholder") : ""}
                          onKeyUp={(event) => {
                            setFormData({ businessRegistrationNumber: event.target.value.trim() });
                          }}
                          error={Boolean(touched[field.name] && errors[field.name])}
                          onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                          onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                          onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                          onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                          onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                          onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                          onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}

                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.businessCategoryField.label")}</p>
                  <Field name="businessCategory">
                    {({ field, form: { touched, errors, setFieldValue } }) => (
                      <>
                        <CustomSelect
                          {...field}
                          labelId="businessCategoryLabel"
                          id="businessCategory"
                          name="businessCategory"
                          className={`input-field select ${businessCategory ? "selected" : ""}`}
                          value={businessCategory || ""}
                          displayEmpty
                          onChange={async (e) => {
                            handleChange(e);
                            // e.currentTarget.attributes["data-risk"].value
                            setFormData({
                              businessCategory: e.target.value,
                              subBusinessCategory: "",
                            });
                            setFieldValue("subBusinessCategory", "");
                            setFieldValue("businessCategory", e.target.value);
                            (childCategory[e.target.value]
                          && childCategory[e.target.value].length > 0)
                              ? setEnableSubCategories(true)
                              : setEnableSubCategories(false);
                            setFirstLoad(false);
                          }}
                          variant="outlined"
                        >
                          {getParentCategory()}
                        </CustomSelect>
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
                {enableSubCategories ? (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <p className="input-label">{t("encore.businessDetail.field.businessSubCategoryField.label")}</p>
                    <Field name="subBusinessCategory">
                      {({ field, form: { touched, errors, setFieldValue } }) => (
                        <>
                          <CustomSelect
                            {...field}
                            labelId="subBusinessCategoryLabel"
                            id="subBusinessCategory"
                            name="subBusinessCategory"
                            className={`input-field select ${subBusinessCategory ? "selected" : ""}`}
                            value={subBusinessCategory || ""}
                            displayEmpty
                            onChange={(e) => {
                              handleChange(e);
                              setFormData({ subBusinessCategory: e.target.value });
                            }}
                            variant="outlined"
                          >
                            {getSubCategory()}
                          </CustomSelect>
                          {touched[field.name] && errors[field.name] && (
                            <div className="error-text">
                              {errors[field.name]}
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </FormControl>
                ) : firstLoad ? (
                  <></>
                ) : (
                  <></>
                )}
                {enableMerchantIdField ? (
                  <>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <p className="input-label">
                        {t("encore.businessDetail.field.merchantIdField.label")}
                        {getTooltip()}
                      </p>
                      <div>
                        <p>
                          {t("encore.businessDetail.field.merchantIdField.note")}
                        </p>
                      </div>
                      <Field name="merchantId">
                        {({ field, form: { touched, errors } }) => (
                          <>
                            <Input
                              {...field}
                              id="merchantId"
                              name="merchantId"
                              className="input-field"
                              placeholder=""
                              error={Boolean(
                                touched[field.name] && errors[field.name]
                              )}
                              onKeyUp={(event) => {
                                setFormData({ merchantId: event.target.value.trim() });
                              }}
                              onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                              onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                              onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                              onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                              onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                              onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                              onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}

                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error-text">
                                {errors[field.name]}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="encore__section__title">{t("encore.businessDetail.businessLocation.description")}</div>
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.businessAddressField.label")}</p>
                  <div>
                    <Field name="businessAddress1">
                      {({ field, form: { touched, errors } }) => (
                        <>
                          <Input
                            {...field}
                            id="businessAddress1"
                            name="businessAddress1"
                            className="input-field"
                            placeholder={t("encore.businessDetail.field.businessAddressField.placeholder1")}
                            onKeyUp={(event) => {
                              setFormData({ businessAddress1: event.target.value.trim() });
                            }}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                            onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                            onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                            onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                            onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                            onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                            onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}

                          />
                          {touched[field.name] && errors[field.name] && (
                            <div className="error-text">
                              {errors[field.name]}
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </FormControl>
                <FormControl variant="outlined" className={`${classes.formControl} consecutive`}>
                  <div>
                    <Field name="businessAddress2">
                      {({ field, form: { touched, errors } }) => (
                        <>
                          <Input
                            {...field}
                            id="businessAddress2"
                            name="businessAddress2"
                            className="input-field"
                            placeholder={t("encore.businessDetail.field.businessAddressField.placeholder2")}
                            onKeyUp={(event) => {
                              setFormData({ businessAddress2: event.target.value.trim() });
                            }}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                            onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                            onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                            onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                            onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                            onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                            onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </FormControl>
                <FormControl variant="outlined" className={`${classes.formControl} consecutive`}>
                  <div>
                    <Field name="businessAddress3">
                      {({ field, form: { touched, errors } }) => (
                        <>
                          <Input
                            {...field}
                            id="businessAddress3"
                            name="businessAddress3"
                            className="input-field"
                            placeholder={t("encore.businessDetail.field.businessAddressField.placeholder3")}
                            onKeyUp={(event) => {
                              setFormData({ businessAddress3: event.target.value.trim() });
                            }}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            onFocus={() => { ubd.focusTextField(setOnTypingStart); }}
                            onBlur={(e) => { handleBlur(e); ubd.blurTextField(e, onTypingStart, setOnTypingEnd, setTypingSpeed); }}
                            onCopy={(e) => { ubd.trackCopyText(e, setCopiedText, selectedText); }}
                            onCut={(e) => { ubd.trackCutText(e, setCutText, selectedText); }}
                            onPaste={(e) => { ubd.trackPastedText(e, setPastedText); }}
                            onSelect={(e) => { ubd.getSelectedText(e, selectedText, setSelectedText); }}
                            onInput={(e) => { ubd.handleOnKeyInput(e, valueChange, setValueChange); }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <div className="form-flex">
                    <div className="item">
                      <p className="input-label">{t("encore.businessDetail.field.postCodeField.label")}</p>
                      <Field name="businessPostcode">
                        {({ field, form: { touched, errors } }) => (
                          <>
                            <Autocomplete
                              {...field}
                              id="combo-box-demo"
                              value={businessCity}
                              open={open}
                              noOptionsText=""
                              filterOptions={(options, stateRef) => {
                                if (stateRef.inputValue.length) {
                                  if (stateRef.inputValue.length > 2) {
                                    const filtered = filter(options, stateRef);
                                    const result = options.find((obj) => {
                                      return obj.postcode === stateRef.inputValue;
                                    });
                                    if (result) {
                                      handleAutoPopulate(result, values);
                                    }
                                    return filtered;
                                  }
                                }
                                return [];
                              }}
                              options={postOfficeList}
                              getOptionLabel={(option) => {
                                if (option) {
                                  if (option.postcode) {
                                    return option.postcode;
                                  }
                                  return postOfficeHashMap[option].postcode;
                                }
                                return "";
                              }}
                              onChange={(event, newValue) => {
                                setOpen(false);
                                if (newValue) {
                                  handleAutoPopulate(newValue, values);
                                }
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              disableClearable
                              closeIcon=""
                              renderInput={(params) => (
                                <Input
                                  {...params}
                                  id="postcode"
                                  name="postcode"
                                  className="input-field"
                                  fullWidth={false}
                                  error={Boolean(
                                    touched[field.name] && errors[field.name]
                                  )}
                                  onChange={(e) => {
                                    setOpen(false);
                                    if (e.target.value.toString().length) {
                                      if (e.target.value.toString().length > 2) {
                                        setOpen(true);
                                      }
                                    }
                                  }}
                                  placeholder={t("encore.businessDetail.field.postCodeField.placeholder")}
                                />
                              )}
                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error-text">
                                {errors[field.name]}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <div className="form-flex">
                    <div className="item">
                      <p className="input-label">{t("encore.businessDetail.field.cityField.label")}</p>
                      <Field name="businessCity">
                        {({ field, form: { touched, errors } }) => (
                          <>
                            <Input
                              {...field}
                              id="businessCity"
                              name="businessCity"
                              className="input-field"
                              placeholder={t("encore.businessDetail.field.cityField.placeholder")}
                              disabled
                              error={Boolean(touched[field.name] && errors[field.name])}
                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error-text">
                                {errors[field.name]}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p className="input-label">{t("encore.businessDetail.field.stateField.label")}</p>
                  <Field name="businessState">
                    {({ field, form: { touched, errors } }) => (
                      <>
                        <Input
                          {...field}
                          id="businessState"
                          name="businessState"
                          className="input-field"
                          placeholder={t("encore.businessDetail.field.stateField.placeholder")}
                          disabled
                          error={Boolean(touched[field.name] && errors[field.name])}
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error-text">
                            {errors[field.name]}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </FormControl>
              </div>

              <div className="encore__next__button">
                <Button
                  id="btn_submit_biz_details"
                  variant="contained"
                  color="primary"
                  className="button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("encore.businessDetail.button")}
                </Button>
              </div>

            </Form>
          )}
        </Formik>
      </div>

    </div>
  );
};

export default BusinessDetail;
