import React, { useContext } from "react";
import Header from "../Header";
import Footer from "../Footer";
import PageLoader from "../PageLoader";
import { StoreContext } from "../../store/context";

const Template = (props) => {
  const { children, step } = props;
  const { state } = useContext(StoreContext);
  const { isLoading } = state.generalStates;

  return (
    <div className="page">
      {isLoading && <PageLoader />}
      <Header />
      <div className="page-container">
        {children}
      </div>
      <Footer steps={step} />
    </div>
  );
};

export default Template;
