import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { navigateToBoostCredit } from "../../utils";
import maintenance from "../../assets/images/encore/maintenance.svg";

import "./Maintenance.scss";

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <div className="maintenance">
      <div className="logo-container">
        <img src={maintenance} alt="Maintenance" />
      </div>
      <div className="title">
        {t("maintenancePage.header")}
      </div>
      <div className="desc">
        {t("maintenancePage.subHeader")}
      </div>
      <div className="encore__next__button">
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={() => {
            navigateToBoostCredit();
          }}
        >
          {t("maintenancePage.buttonText")}
        </Button>
      </div>
    </div>
  );
};

export default Maintenance;
