import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@material-ui/core";
import clevertap from "clevertap-web-sdk";
import { StoreContext } from "../../store/context";
import KycStepEnum from "../../constants/KycStepEnum";
import wifi from "../../assets/images/encore/guide/Wifi.svg";
import web from "../../assets/images/encore/guide/Internet.svg";
import selfie from "../../assets/images/encore/guide/Camera.svg";
import ID from "../../assets/images/encore/guide/ID.svg";
import circleLeftArrow from "../../assets/images/encore/circle-left-arrow.svg";
import { navigateToExternal } from "../../utils";
import * as ubd from "../../utils/userBehaviouralData";

import "./KYCBefore.scss";

const mouseSpeed = [];

const KYCBefore = () => {
  const { t } = useTranslation();
  const { state, actions } = useContext(StoreContext);
  const { handleUserBehaviourData } = actions.otpActions;
  const { setGeneralData } = actions.generalActions;
  const { registerJumioKyc } = actions.credentialActions;
  const { kycJumioUrl, step, kycStep, kycPromptRetry, isResumeJourney, isRestoreSessionKey } = state.generalStates;
  const { referralCode } = state.formStates;

  const [journeyStart, setJourneyStart] = useState(null);
  const [singleStepEnd, setSingleStepEnd] = useState(null);
  const [totalMouseSpeed, setTotalMouseSpeed] = useState(0);

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (step == 4 && kycStep == KycStepEnum.PRE_JUMIO) {
      registerJumioKyc();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEndJourney = () => {
    ubd.calculateMouseMovementTotalSpeed(mouseSpeed, setTotalMouseSpeed);
    setSingleStepEnd(ubd.formatCurrentDateTime());
  };

  useEffect(() => {
    const params = {
      step: ubd.STEP_JUMIO_KYC_BEFORE_REDIRECT,
      startDate: journeyStart,
      endDate: singleStepEnd,
      totalMouseSpeed,
    };
    if (singleStepEnd != null) {
      handleUserBehaviourData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStepEnd]);

  useEffect(() => {
    ubd.getCurrentDateTime(setJourneyStart);
    let prevEvent; let
      currentEvent;
    document.documentElement.onmousemove = function (e) {
      currentEvent = e;
    };
    let prevSpeed = 0;
    const interval = setInterval(() => {
      if (prevEvent && currentEvent) {
        const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
        const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
        const movement = Math.sqrt(movementX * movementX + movementY * movementY);
        var speed = 10 * movement;
        if (speed > 0) {
          mouseSpeed.push(Math.round(speed, 2));
        }
      }
      prevEvent = currentEvent;
      prevSpeed = speed;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleProceedButton = () => {
    handleEndJourney();

    // Send Clevertap event
    clevertap.event.push("act_cp_identity_check_redirect_jumio", {
      "ReferralCode": referralCode,
      "FullURL": window.location.href,
    });

    setRedirecting(true);
    setTimeout(() => {
      navigateToExternal(kycJumioUrl);
    }, 3000); // wait 3 seconds for some analytics tracking to finish
  };

  return (
    <div className="encore__kycBefore">
      { !kycPromptRetry && !isResumeJourney && !isRestoreSessionKey && (
        <IconButton
          color="primary"
          onClick={() => {
            // Hide Back button if is reattempt or is resume journey
            setGeneralData({ step: 3 });
          }}
          className="encore__page__back_holder"
        >
          <img src={circleLeftArrow} alt="Back" />
        </IconButton>
      )}
      <div className="encore__page__upper">
        <div className="encore__title">{t("encore.kycBefore.title")}</div>
        {/* <div className="encore__desc">{checkBankStatement()}</div> */}
        <div className="encore__desc">{t("encore.kycBefore.description")}</div>
        <div className="encore__reminder__title" />
      </div>
      <div className="encore__reminder__container">
        <div className="encore__title">
          {t("encore.kycBefore.preparation.title")}
        </div>
        <div className="encore__reminder">
          <div className="encore__container">
            <div className="encore__icon">
              <img src={ID} alt="ID Icon" />
            </div>
            <div
              className="encore__desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.kycBefore.preparation.preparation1"),
              }}
              style={{ marginBottom: "15px" }}
            />
            <div
              className="encore__desc"
              id="smallDesc"
              dangerouslySetInnerHTML={{
                __html: t("encore.kycBefore.preparation.preparation5"),
              }}
            />
          </div>
          <div className="encore__container">
            <div className="encore__icon">
              <img src={selfie} alt="Selfie Icon" />
            </div>
            <div
              className="encore__desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.kycBefore.preparation.preparation2"),
              }}
            />
          </div>
          <div className="encore__container">
            <div className="encore__icon">
              <img src={web} alt="Web Icon" />
            </div>
            <div
              className="encore__desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.kycBefore.preparation.preparation3"),
              }}
            />
          </div>
          <div className="encore__container">
            <div className="encore__icon">
              <img src={wifi} alt="WIFI Icon" />
            </div>
            <div
              className="encore__desc"
              dangerouslySetInnerHTML={{
                __html: t("encore.kycBefore.preparation.preparation4"),
              }}
            />
          </div>
        </div>
      </div>
      <div className="encore__next__button">
        <Button
          id="btn_kyc_redirect_to_jumio"
          variant="contained"
          color="primary"
          className="button"
          disabled={!kycJumioUrl || redirecting}
          onClick={handleProceedButton}
        >
          {!redirecting ? t("encore.kycBefore.button") : t("encore.kycBefore.buttonRedirecting")}
        </Button>
      </div>
    </div>
  );
};

export default KYCBefore;
