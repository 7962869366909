export const OTP_TIMER = 30;
export const TENANT_ID = 13;

// Prerequisite API BSA Requirement Status
export const BSA_JOURNEY_STATUS_MANDATORY = "MANDATORY";
export const BSA_JOURNEY_STATUS_OPTIONAL = "OPTIONAL";
export const BSA_JOURNEY_STATUS_NOT_REQUIRED = "NOT_REQUIRED";
export const BSA_JOURNEY_STATUS_FORCED = "FORCED";

// getSessionInfo API ramciStatus
export const DOT_RAMCI_STATUS_PROCESSING = 1;
export const DOT_RAMCI_STATUS_REJECTED = 2;
export const DOT_RAMCI_STATUS_SUCCESSFUL = 3;

export default {
  isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }
};
