const KycStatusEnum = {
  INITIATED: "INITIATED",
  FAILED: "FAILED",
  WARNING: "WARNING",
  PASSED: "PASSED",
  REJECTED: "REJECTED",
  NOT_EXECUTED: "NOT_EXECUTED",
  MANUALLY_APPROVED: "MANUALLY_APPROVED",
  MANUALLY_REJECTED: "MANUALLY_REJECTED",
  PENDING_RETRY: "PENDING_RETRY",
};

/**
 * Returns true if the KYC is waiting to be retried
 *    (manually requested for re-attempt by the Ops team)
 */
export const isKycPendingRetry = (result) => {
  return Boolean(result == KycStatusEnum.PENDING_RETRY);
};

/**
 * Returns true if the KYC result is not-retryable
 *    (either approved or pending approval by Ops team)
 */
export const isKycNotRetryable = (result) => {
  return isKycApproved(result) || isKycWaitingOpsReview(result);
};

/**
 * Returns true if the KYC result is approved
 */
export const isKycApproved = (result) => {
  return Boolean(result == KycStatusEnum.PASSED || result == KycStatusEnum.MANUALLY_APPROVED);
};

/**
 * Returns true if the KYC result is rejected
 */
export const isKycRejected = (result) => {
  return Boolean(result == KycStatusEnum.REJECTED || result == KycStatusEnum.MANUALLY_REJECTED);
};

/**
 * Returns true if the KYC is routed to Ops team
 */
export const isKycWaitingOpsReview = (result) => {
  return Boolean(result == KycStatusEnum.WARNING);
};

/**
 * Returns true if the KYC is pending review name/nric
 */
export const isKycWaitingUserReviewNameNric = (result, errorCode) => {
  // error codes that need to route user to name/nric assessment
  return (result == KycStatusEnum.FAILED) && (["BCC001", "BCC002", "BCC003"].includes(errorCode));
};

/**
 * Returns true if the KYC should be navigated to pre-Jumio page for retry, upon restore saved session key. Due to possible user forever stuck if we show their previous KYC result.
 */
export const isKycPotentialStuckIfProceedToResultPage = (result, allowedToRetry, errorCode) => {
  if (result == KycStatusEnum.INITIATED || result == KycStatusEnum.NOT_EXECUTED) {
    // User dropoff at pre-Jumio page without submit selfie
    // Although possible allowedToRetry=YES, we still skip the loading page because its not nice for user to see.
    return true;
  }

  if (isKycRejected(result) && allowedToRetry != "YES") {
    // User previously rejected and blocked, allow user to go to pre-Jumio page and let Backend decide whether to remain block or unblock
    return true;
  }

  if (result == KycStatusEnum.FAILED && !isKycWaitingUserReviewNameNric(result, errorCode) && allowedToRetry != "YES") {
    // User previously failed due to unknown issues
    return true;
  }

  return false;
};

export default KycStatusEnum;
