import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../store/context";
import boostCreditLogoRed from "../../assets/images/boost-credit.svg";
import LanguageToggleButton from "../LanguageToggleButton";
import { getBoostCreditUrl } from "../../utils";

import "./Header.scss";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Header = () => {
  const [logoHeight, setLogoHeight] = useState();
  const { height, width } = useWindowDimensions();
  const { state, actions } = useContext(StoreContext);
  const { browserName, browserLogoUrl, productLogoUrl, productLogoHeight, productMobileLogoHeight } = state.generalStates;
  const { setGeneralData } = actions.generalActions;
  const { referralCode, referralKey } = state.formStates;

  const [isDisableRedirect, setIsDisableRedirect] = useState(false);
  const [logoRedirectUrl, setLogoRedirectUrl] = useState();

  useEffect(() => {
    setLogoRedirectUrl(getBoostCreditUrl(referralCode, referralKey));
  }, [referralCode, referralKey]);

  useEffect(() => {
    if (browserName) {
      document.title = browserName;
      document.querySelector("link[rel*='icon'").href = browserLogoUrl;
    }

    if (!productLogoUrl) {
      setGeneralData({ productLogoUrl: boostCreditLogoRed, productLogoHeight: 22 });
    } else {
      setIsDisableRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserName, productLogoUrl, browserLogoUrl]);

  function checkLogoHeight() {
    if (!productLogoHeight) {
      setGeneralData({ productLogoHeight: 22 });
    } else if (width <= 768) {
      return productMobileLogoHeight || productLogoHeight;
    }
    return productLogoHeight;
  }

  useEffect(() => {
    setLogoHeight(checkLogoHeight());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, productLogoHeight, productMobileLogoHeight]);

  return (
    <header className="header-parent">
      <div className="header3">
        <div className="question-holder" />
        <div className="logo-holder">
          <a href={isDisableRedirect ? null : logoRedirectUrl}>
            <img className="float-left" alt="logo" height={logoHeight} src={productLogoUrl} />
          </a>
        </div>
        <div className="language-holder">
          <div className="language">
            <LanguageToggleButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
